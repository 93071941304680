<template>
  <VPopover placement="left" :trigger="hit.case_hit.comments.length === 0 && !editable ? 'disabled' : 'click'" @click.stop @contextmenu.stop>
    <template #reference>
      <VButton :disabled="commentDisabled.disabled" :help="commentDisabled.help" hover-type="secondary" rounded size="small" style="flex-shrink: 0" type="link">
        <VIcon :class="editable ? 'editable' : 'not-editable'" :icon="hit.case_hit.comments.length === 0 ? 'CommentEmpty' : 'Comment'" :size="15" type="primary" />
      </VButton>
      <VBadge :hidden="hit.case_hit.comments.length === 0" type="primary" :value="hit.case_hit.comments.length" />
    </template>
    <div style="max-width: 700px; min-width: 400px">
      <FieldComments v-if="'case_hit' in hit" :hit="hit" style="margin-bottom: 20px" />

      <MentionableComment v-if="editable" v-model="comment" :placeholder="$t('your-comment')" :suggestions="suggestions" />

      <template v-if="editable">
        <VButton :disabled="comment === '[]'" :label="$t('submit')" style="margin-top: 10px; float: right" @click="resolve()" />
      </template>
    </div>
  </VPopover>
</template>

<script setup lang="ts">
import type { CaseHitTransformed } from '~/types.ts'

const $resolve = inject('$resolve') as (args: any) => Promise<void>

const props = defineProps<{
  hit: CaseHitTransformed['data']
  editable: boolean
}>()
const commentDisabled = computed(() => {
  return new DisabledHelp($t('there-are-no-comments-on-this-hit-and-you-can-not-create-one'), props.hit.case_hit.comments.length === 0 && !props.editable)
})

const comment = ref('[]')
const loading = ref(false)

async function resolve() {
  if (loading.value) return

  loading.value = true
  await nextFrame()

  await $resolve({
    ids: [props.hit.case_hit.id],
    resolution: props.hit.case_hit.resolution,
    comment: comment.value,
  })

  comment.value = '[]'
  loading.value = false
}
const suggestions = computed((): string[] => [$t('true-positive'), $t('false-positive'), $t('relevant'), $t('not-relevant'), $t('review-needed')])
</script>

<style lang="scss" scoped>
.v-badge {
  position: absolute;
  top: -4px;
  left: 22px;
}

.v-icon-commentempty.not-editable :deep() path {
  stroke: var(--color-grey-40);
}
</style>
