import { acceptHMRUpdate, defineStore } from 'pinia'

export const ACTIVE_DISCOUNT = 0 as number
export const NEXT_MONTH_DISCOUNT = 0 as number

export const useDiscounts = defineStore('discounts', {
  state: () => ({
    ACTIVE_DISCOUNT,
    NEXT_MONTH_DISCOUNT,
  }),

  getters: {},

  actions: {
    async fetch(organizationId: number) {
      const responseNow = await api.post<Record<string, any>[]>('discounts/searches', { organization_id: [organizationId], active_on_date: dayjs() })
      this.ACTIVE_DISCOUNT = responseNow.data[0]?.discount_percentage ?? 0
      const responseNext = await api.post<Record<string, any>[]>('discounts/searches', { organization_id: [organizationId], active_on_date: dayjs().add(1, 'month') })
      this.NEXT_MONTH_DISCOUNT = responseNext.data[0]?.discount_percentage ?? 0
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDiscounts, import.meta.hot))
}
