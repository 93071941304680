<template>
  <span v-if="position" v-text="position" />
  <FieldEmpty v-else />
</template>

<script lang="ts">
export default defineComponent({
  props: {
    position: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null || prop === undefined,
    },
  },
})
</script>
