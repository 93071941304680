import { acceptHMRUpdate, defineStore } from 'pinia'

type Scripts = Record<string, string>

interface Translation {
  detectedLanguage: string
  language: string
  translation: string
}

export interface Language {
  name: string
  dir: string
  nativeName: string
}

type Languages = Record<string, Language>

export const useTranslation = defineStore('translation', {
  state: () => ({
    translations: {} as Record<string, Translation>,
    languages: {} as Languages,
    scripts: {} as Scripts,
    languageDetections: {} as Record<string, string>,
    scriptDetections: {} as Record<string, string>,
    loadingLanguages: false,
    loadingScripts: false,
  }),

  actions: {
    async loadLanguages() {
      if (this.loadingLanguages || Object.keys(this.languages).length > 0) return
      this.loadingLanguages = true

      const { data } = await api.get<Languages>('languages')
      this.languages = data
      this.loadingLanguages = false
    },

    async loadScripts() {
      if (this.loadingScripts || Object.keys(this.scripts).length > 0) return
      this.loadingScripts = true

      const { data } = await api.get<Scripts>('scripts')
      this.scripts = data
      this.loadingScripts = false
    },

    async detectLanguage({ text }: { text: string }) {
      const key = JSON.stringify(text.toLowerCase())
      if (key in this.languageDetections) {
        return this.languageDetections[key]
      }

      const { data } = await api.post<Record<string, number>>('detect-language', { text })
      const language = Object.keys(data)[0]
      this.languageDetections[key] = language

      return language
    },

    async detectScript({ text }: { text: string }) {
      const key = JSON.stringify(text.toLowerCase())
      if (key in this.scriptDetections) {
        return this.scriptDetections[key]
      }

      const { data } = await api.post<Record<string, number>>('detect-script', { text })
      const script = Object.keys(data)[0]

      this.scriptDetections[key] = script

      return script
    },

    async translate({ text }: { text: string }) {
      const language = stores.settings.settings.translation_language
      if (!language) return {}

      const key = `${language}.${JSON.stringify(text.toLowerCase())}`
      if (key in this.translations) {
        return { cached: true, translation: this.translations[key] }
      }

      const { data } = await api.post<Translation>('translate', { text, language })

      this.translations[key] = data

      return { cached: false, translation: data }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTranslation, import.meta.hot))
}
