<template>
  <div>
    <CountryFlagIcon v-if="jurisdiction" type="jurisdiction" :value="jurisdiction" />
    <SearchLink :form="{ jurisdiction }" :highlight-target="highlightTarget" :text="name" :title="$t('perform-a-business-check')" type="Business" />
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    name: {
      required: true,
      type: String,
    },

    jurisdiction: {
      type: String,
      default: '',
    },

    highlightTarget: {
      type: [String, Array],
      default: '',
    },
  },

  methods: { capitalize },
})
</script>
