<template>
  <div>
    <div v-for="(item, index) in value" :key="index" v-text="`${item.type}: ${item.value}`" />
  </div>
</template>

<script lang="ts">
import { type PropType } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<{ value: string; type: string }[]>,
      required: true,
    },
  },
})
</script>
