<template>
  <VPopover :placement="simplified ? 'right' : 'top'" tag="span" trigger="hover">
    <ChartRiskClassification v-if="simplified" :risk="risk" />
    <ChartRiskMatrix v-else :risk="risk" />

    <template #reference>
      <slot />
    </template>
  </VPopover>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    risk: {
      type: Object,
      required: true,
    },

    simplified: Boolean,
  },
})
</script>
