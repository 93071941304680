<template>
  <div>
    <VRow v-for="rowIndex in 5" :key="rowIndex" class="skeleton-row" :gutter="20">
      <VCol :span="4">
        <div class="skeleton-box" style="width: 100%" />
      </VCol>

      <VCol :span="8">
        <div class="skeleton-box" style="width: 100%" />
      </VCol>

      <VCol :span="2">
        <div class="skeleton-box" style="width: 100%" />
      </VCol>

      <VCol :span="2">
        <div class="skeleton-box" style="width: 100%" />
      </VCol>

      <VCol :span="6">
        <div class="skeleton-box" style="width: 100%" />
      </VCol>

      <VCol :span="2">
        <div class="skeleton-box" style="width: 100%" />
      </VCol>
    </VRow>
  </div>
</template>

<script lang="ts">
export default defineComponent({})
</script>

<style lang="scss" scoped>
.skeleton-row {
  width: 100%;
  padding: 10px 0;
  margin: 10px 0 !important;
  border-bottom: var(--border);
}
</style>
