<template>
  <div class="notice">
    <span style="flex-grow: 1; font-weight: 500">{{ $t('updated-item') }}</span>
    <VButton :label="showChanges ? $t('hide-changes') : $t('show-changes')" style="float: right" type="link" @click="hideChanges" />
    <VButton style="float: right" type="link" @click="acceptUpdate">{{ $t('accept-changes') }}</VButton>
  </div>
</template>

<script lang="ts">
import { $caseStoreKey } from '~/injections.ts'

export default defineComponent({
  props: {
    row: {
      type: Object,
      required: true,
    },

    showChanges: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['toggleChanges'],

  setup() {
    const { sourceStores } = injectRequired($caseStoreKey)

    return {
      sourceStores,
    }
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    hideChanges() {
      this.$emit('toggleChanges')
      stores.apmEvents.track({ event: 'hit-update-hidden', category: 'resolving' })
    },

    async acceptUpdate() {
      this.loading = true
      const { data } = await api.post('case-hits/accept-update', { case_id: this.row.case_hit.case_id, ids: [this.row.case_hit.id], source: this.row.case_hit.source })

      this.sourceStores[this.row.case_hit.source].updateHits(data.hits)
      stores.cases.opened[data.id].risk = data.risk
      stores.cases.opened[data.id].unresolved_risk = data.unresolved_risk
      stores.apmEvents.track({ event: 'hit-update-accepted', category: 'resolving' })
    },
  },
})
</script>

<style lang="scss" scoped>
.notice {
  display: flex;
  align-items: center;
  padding: 3px 12px;
  height: 40px;
  background-color: var(--color-blue-1);
  border-radius: 5px;
  margin-bottom: 16px;
}
</style>
