<template>
  <VBadge :hidden="!unresolvedRisk" :size="14" :style="fullWidth ? 'width:100%' : ''" :type="(unresolvedRisk ?? 0) > 0.12 ? 'red' : 'primary'">
    <div :class="classes" v-text="score ? `${score} = ${$t(kebab(risk ?? 'No risk'))}` : $t(kebab(risk ?? 'No risk'))" />
  </VBadge>
</template>

<script lang="ts">
import { type PropType } from 'vue'

export default defineComponent({
  props: {
    risk: {
      type: String as PropType<string | null>,
      default: '',
    },

    unresolvedRisk: {
      type: Number as PropType<number | null>,
      default: null,
    },

    score: {
      type: Number,
      default: null,
    },

    type: {
      type: String,
      default: 'tag',
    },

    deleted: {
      type: Boolean,
      default: false,
    },

    rounded: Boolean,
    dimmed: Boolean,
    autoWidth: Boolean,
    fullWidth: Boolean,
    inactive: Boolean,
  },

  data: () => ({
    typeMap: {
      'very high': 'red',
      high: 'red',
      moderate: 'orange',
      low: 'green',
      'very low': 'green',
      'no matches': 'info',
    },
  }),

  computed: {
    classes: (vm) => [
      'risk-bar',
      {
        [`risk-bar--${kebab(vm.risk ?? '')}`]: vm.risk,
        'risk-bar--inactive': vm.inactive,
        'risk-bar--rounded': vm.rounded,
        'risk-bar--full-width': vm.fullWidth,
        'risk-bar--auto-width': vm.autoWidth,
        'risk-bar--dimmed': vm.dimmed,
      },
    ],
  },

  methods: { kebab },
})
</script>

<style lang="scss" scoped>
$color-map: (
  'very-high': var(--color-risk-very-high),
  'high': var(--color-risk-high),
  'moderate': var(--color-risk-moderate),
  'low': var(--color-risk-low),
  'very-low': var(--color-risk-very-low),
  'no-matches': var(--color-risk-no-risk),
);

.risk-bar {
  text-align: center;
  color: #fff;
  padding: 3px 6px;
  min-width: 110px;
  background-color: var(--color-risk-no-risk);

  @each $risk, $color in $color-map {
    &--#{$risk} {
      background-color: $color;
    }
  }
  &--dimmed {
    opacity: 0.5;
  }

  &--full-width {
    width: 100%;
  }

  &--auto-width {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  &--rounded {
    border-radius: 15px;
  }

  &--inactive {
    opacity: 0.4;
  }

  + .risk-bar {
    margin-left: 4px;
  }
}

:deep() .v-badge__content {
  border: 2px solid white;
  margin-top: 5px;
}
</style>
