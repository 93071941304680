<template>
  <span v-if="value.length > 0" class="link" @click="dialogVisible = true">
    {{ $t('a-total-of-count-duplicates-have-been-identified', value.length) }}
    <DialogDuplicates v-model:visible="dialogVisible" :value="value" />
  </span>
  <FieldEmpty v-else />
</template>

<script lang="ts">
export default defineComponent({
  props: {
    value: {
      required: true,
      type: Array,
    },
  },

  data: () => ({
    dialogVisible: false,
  }),
})
</script>
