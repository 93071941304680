<template>
  <VRow :gutter="10" style="margin-right: 0; width: auto">
    <VCol :md="18">
      <div
        v-if="row.checked_at && $dayjs(row.checked_at) < $dayjs().subtract(6, 'month') && row.case_hit?.resolution === 'unresolved' && row.case_hit?.source !== 'news'"
        class="archived-notice"
      >
        <span style="flex-grow: 1; font-weight: 500" v-text="$t('note-the-record-was-last-updated-more-than-6-months-ago')" />
      </div>
      <TranslationNotice>
        <PartialSupportNotice v-if="row.case_hit?.source === 'news' && row.language && !$stores.initialState.fullySupportedLanguages.includes(row.language)" />

        <slot name="expand" :row="row">
          <NoticeHitUpdate v-if="row.case_hit?.update_changes" :row="row" :show-changes="showChanges" @toggle-changes="showChanges = !showChanges" />
          <slot name="expanded-row-start" :row="row" />

          <HitField v-for="field in keyFields" :key="field" ref="keyFields" :field="field" :field-size="5" :form="form" :row="row" :show-changes="showChanges" :value-size="19" />
          <VButton v-show="!expanded" :label="$t('show-more')" style="margin-top: 15px" type="link" @click="showMore" />

          <VTransitionCollapse lazy style="margin-top: -1px" :visible="expanded">
            <HitField v-for="field in nonKeyFields" :key="field" :field="field" :field-size="5" :form="form" :row="row" :show-changes="showChanges" :value-size="19" />
            <HitField field="case_hit.created_at" :field-size="5" :form="form" :row="row" :value-size="19" />
            <HitField field="vendor" :field-size="5" :form="form" :row="row" :show-changes="showChanges" :value-size="19" />
            <HitField field="explanation" :field-size="5" :form="form" :row="row" :show-changes="showChanges" :value-size="19" />
            <HitField
              v-if="row.case_hit && !row.case_hit?.removed_from_source"
              field="risk_explanation"
              :field-size="5"
              :form="form"
              :row="row"
              :show-changes="showChanges"
              :value-size="19"
            />

            <VButton :label="$t('show-less')" style="margin-top: 15px" type="link" @click="expanded = false" />

            <slot name="expanded-row-end" :row="row" />
          </VTransitionCollapse>
        </slot>
      </TranslationNotice>
    </VCol>

    <VCol :md="6">
      <HitField v-if="row.score" field="score" :form="form" :row="row" :show-changes="showChanges" vertical />
      <HitField v-if="row.risk && !row.case_hit?.removed_from_source" field="risk" :form="form" :row="row" :show-changes="showChanges" vertical />

      <HitField v-for="field in rightFields" :key="field" :field="field" :form="form" :row="row" :show-changes="showChanges" vertical>
        <template v-if="field === 'adverse'" #empty>
          <VTag>{{ $t('not-supported') }}</VTag>
        </template>
      </HitField>

      <slot name="expanded-row-right" :row="row" />
    </VCol>

    <VCol :id="`row-comment-${row.case_hit?.id}`">
      <HitResolve v-if="resolve" extended :rows="[row]" style="margin: 20px 0" />
      <FieldComments v-if="'case_hit' in row" :hit="row" />
    </VCol>
  </VRow>
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type CaseHitTransformed, type CaseSourceName, type SearchHit } from '~/types.ts'
import type HitField from './HitField.vue'

const rightFields = ['score', 'risk', 'sanction_type', 'source', 'adverse']
const hiddenFieldsPerSource = {
  news: ['title', 'summary'],
}

export default defineComponent({
  props: {
    row: {
      type: Object as PropType<SearchHit['data'] | CaseHitTransformed['data']>,
      required: true,
    },

    form: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    source: {
      type: String as PropType<CaseSourceName>,
      required: true,
    },

    resolve: Boolean,
  },

  data: () => ({
    expanded: false,
    showChanges: true,
    visibleFields: [] as string[],
  }),

  computed: {
    fields() {
      return stores.source.fields[this.source]
    },

    hiddenFields() {
      if (this.showChanges && this.row.case_hit?.update_changes?.updated?.name) {
        return []
      }

      return ['name']
    },

    filteredFields() {
      return this.fields.filter((field) => !this.hiddenFields.includes(field) && !(hiddenFieldsPerSource[this.source] ?? []).includes(field))
    },

    rightFields() {
      return this.filteredFields.filter((field) => rightFields.includes(field))
    },

    leftFields() {
      return this.filteredFields.filter((field) => !this.rightFields.includes(field))
    },

    keyFields() {
      return this.leftFields.filter((field) => {
        if (this.visibleFields.includes(field)) {
          return true
        }

        if ((field === 'organizations' && this.type === 'Person') || (field === 'persons' && this.type === 'Business')) {
          return false
        }

        return stores.source.keyFields[this.source].includes(field)
      })
    },

    nonKeyFields() {
      return this.leftFields.filter((field) => {
        return !this.keyFields.includes(field)
      })
    },
  },

  async mounted() {
    await this.$nextTick()

    const keyFields = this.$refs.keyFields as InstanceType<typeof HitField>[]
    let visibleKeyFields = keyFields.filter((field) => field.showRow)
    let attempts = 0

    while (visibleKeyFields.length < 5 && this.nonKeyFields.length > 0 && attempts < 100) {
      this.visibleFields.push(this.nonKeyFields[0])
      await this.$nextTick()

      const keyFields = this.$refs.keyFields as InstanceType<typeof HitField>[]
      visibleKeyFields = keyFields.filter((field) => field.showRow)
      attempts++
    }
  },

  methods: {
    showMore() {
      this.expanded = true
      stores.apmEvents.track({ event: 'hit-show-more', category: 'resolving' })
    },
  },
})
</script>

<style lang="scss" scoped>
.archived-notice {
  display: flex;
  align-items: center;
  padding: 3px 12px;
  height: 40px;
  background-color: var(--color-blue-1);
  border-radius: 5px;
  margin-bottom: 16px;
}
</style>
