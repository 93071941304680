import { type InjectionKey } from 'vue'

import { type createCaseStore } from '~/stores/case.ts'
import { type createHitsStore } from '~/stores/hits.ts'
import { type createPlanManagementStore } from '~/stores/planManagement.ts'
import { type CaseSourceName } from './types.ts'

export type Translations = Record<number, { from: string; to: string }>
export const $translationNoticeKey = Symbol('TranslationNotice') as InjectionKey<{
  showTranslation: boolean
  translating: Set<number>
  translations: Translations
}>

export const $caseStoreKey = Symbol('CaseStore') as InjectionKey<{
  caseStore: ReturnType<ReturnType<typeof createCaseStore>>
  sourceStores: Record<CaseSourceName, ReturnType<ReturnType<typeof createHitsStore>>>
  isPreviewDialog?: boolean
}>

export const $planManagementStoreKey = Symbol('PlanManagementStore') as InjectionKey<ReturnType<ReturnType<typeof createPlanManagementStore>>>
