<template>
  <VTable
    clickable
    :data="tableData"
    :pagination="{ pageSize: 5 }"
    row-key="uid"
    :style="{ 'margin-bottom': tableData.length < 6 ? '-16px' : '16px' }"
    @row-click="openDialog($event.row)"
  >
    <VTableColumn v-slot="{ row }: { row: TableRow }" cell-class="flex-center" :label="$t('name')" max-width="280" prop="name" show-overflow-tooltip>
      <VTooltip v-if="row.matched" :content="`${$t('matched-on')}: ${[...row.matched].join(' & ')}`">
        <MatchTag class="match-tag" :value="row.name">{{ $t('match') }}</MatchTag>
      </VTooltip>

      <CaseIcon v-if="row.caseType" style="margin-right: 5px" :type="row.caseType" />
      <FieldNationality v-if="row.jurisdiction_code" field="jurisdiction_code" flag-only style="display: inline-block" :value="row.jurisdiction_code" />
      <FieldNationality v-else-if="row.nationalities" flag-only style="display: inline-block" :value="row.nationalities[0].country" />
      <template v-if="row.type === 'location' || (typeof row.type === 'object' && row.type?.value === 'location')">
        <CountryLink v-if="$stores.countries.countryName(row.name)" :highlight-target="highlightTarget('name')" :text="row.name" />
        <VTextHighlight v-else :queries="highlightTarget('name')" :text="row.name" />
      </template>
      <SearchLink v-else :highlight-target="highlightTarget('name')" style="margin-left: 5px" :text="row.name" :type="row.caseType" />
    </VTableColumn>

    <VTableColumn v-slot="{ row }" width="66">
      <FieldCheckedName :type="row.caseType" :value="row.name" />
    </VTableColumn>

    <VTableColumn v-if="columns.has('position')" v-slot="{ row }" :label="$t('position')" prop="position">
      <div v-if="row.position" v-text="row.position" />
      <FieldEmpty v-else />
    </VTableColumn>

    <VTableColumn v-if="columns.has('connection_type')" v-slot="{ row }" :label="$t('relation')" prop="connection_type">
      <div v-if="row.connection_type" v-text="row.connection_type" />
      <FieldEmpty v-else />
    </VTableColumn>

    <VTableColumn v-if="columns.has('listed_at') || columns.has('listed_until')" v-slot="{ row }" :label="$t('date')" prop="listed_at">
      <div v-if="row.listed_at || row.listed_until">{{ formatDate(row.listed_at, $t('unknown')) }} - {{ formatDate(row.listed_until, $t('unknown')) }}</div>
      <FieldEmpty v-else />
    </VTableColumn>

    <VTableColumn v-slot="{ row }" width="40">
      <VButton icon="ChevronRight" rounded size="small" type="icon" @click="openDialog(row)" />
    </VTableColumn>

    <DialogConnectionInformation v-if="dialogVisible" v-model:visible="dialogVisible" :form="form" :value="selectedRow" />
  </VTable>
</template>

<script setup lang="ts">
import get from 'lodash-es/get.js'

import { createHighlightQueries } from '~/helpers/index.ts'
import { formatDate } from '~/helpers/textFormatting'
import type { CaseType } from '~/types'

interface Connection {
  name: string
  type:
    | string
    | {
        predicted: boolean
        value: string
      }
  count: number
  connection_type: string
  [key: string]: any
}

interface TableRow extends Connection {
  matched: Set<string>
  matchedCount: number
  caseType: CaseType | ''
}

interface Props {
  connections: Connection[]
  form: Record<string, unknown>
  elasticHighlights: {
    connections?: Record<string, string[]>
  }
}

const props = defineProps<Props>()

const typeMap: Record<string, CaseType> = {
  business: 'Business',
  entity: 'Business',
  individual: 'Person',
  person: 'Person',
}

const dialogVisible = ref(false)
const selectedRow = ref({})

const tableData = computed((): TableRow[] => {
  const connections: Partial<TableRow>[] = props.connections.map((connection) => ({ ...connection, caseType: caseType(connection.type) }))

  if (props.elasticHighlights.connections) {
    for (const [key, values] of Object.entries(props.elasticHighlights.connections)) {
      const matchedFields = values.map((highlight) => removeTokens(highlight.toLowerCase()))
      for (const connection of Object.values(connections)) {
        const path = key.includes('.') ? key.substring(0, key.indexOf('.')) : key
        let pathItems = get(connection, path)
        if (!pathItems) {
          continue
        }

        if (typeof pathItems === 'string') {
          pathItems = [pathItems]
        }
        for (let pathItem of pathItems) {
          if (typeof pathItem !== 'string') {
            if ('text' in pathItem) {
              pathItem = pathItem.text
            } else if ('name' in pathItem) {
              pathItem = pathItem.name
            }
          }
          if (matchedFields.includes(pathItem.toLowerCase())) {
            connection.matched ??= new Set()
            connection.matchedCount ??= 1
            connection.matched.add(mapField(path))
            connection.matchedCount += 1
            break
          }
        }
      }
    }
  }

  return sortArrayByObjectKey({ array: sortArrayByObjectKey({ array: connections as TableRow[], key: 'count', order: 'desc' }), key: 'matchedCount', order: 'desc' })
})

const columns = computed(() => {
  const columns = new Set()
  for (let item of tableData.value) {
    for (const [key, value] of Object.entries(item)) {
      if (value) {
        columns.add(key)
      }
    }
  }

  return columns
})

function openDialog(row: TableRow) {
  selectedRow.value = row
  dialogVisible.value = true
}

function caseType(value: null | undefined | string | { value: string }): CaseType | '' {
  let string = typeof value === 'object' ? value?.value : value

  if (!string) {
    return ''
  }

  string = string.toLowerCase()

  if (string in typeMap) {
    return typeMap[string]
  }

  return ''
}

function removeTokens(string: string) {
  return string.replaceAll('<em>', '').replaceAll('</em>', '').replaceAll('.', '').replaceAll(',', '').replaceAll('-', '')
}

function highlightTarget(field: string) {
  return createHighlightQueries(props.form, field)
}
</script>

<style lang="scss" scoped>
div > .match-tag {
  margin-right: 8px;
  color: var(--color-text-primary);
  border-color: var(--color-text-primary);
}

@media (min-width: $lg) {
  .v-dialog-wrapper :deep() .v-dialog {
    min-width: 1080px;
  }
}
</style>
