import Axios, { type AxiosError } from 'axios'

const csrfToken = (document.getElementsByName('csrf-token')[0] as HTMLMetaElement).content as string
const apiTokenElement = document.head.querySelector<HTMLMetaElement>('meta[name="api-token"]')
if (apiTokenElement) {
  document.head.removeChild(apiTokenElement)
}

let apiToken = apiTokenElement?.content ?? null
if (apiToken) {
  sessionStorage.setItem('api-token', apiToken)
} else {
  apiToken = sessionStorage.getItem('api-token')
}

export const axios = Axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': csrfToken,
    Authorization: apiToken ? `Bearer ${apiToken}` : undefined,
  },
  withCredentials: !apiToken,
})

export const http = Axios.create()

export const api = Axios.create({
  baseURL: '/api',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': csrfToken,
    Authorization: apiToken ? `Bearer ${apiToken}` : undefined,
  },
  withCredentials: !apiToken,
})

let interceptor = -1

async function interceptExpiredSessions(error: AxiosError) {
  if (interceptor > -1 && error.response?.status === 419) {
    api.interceptors.response.eject(interceptor)
    interceptor = -1

    window.location.reload()
    // Wait a bit so no error is shown while reloading
    await delay(3000)
  }

  return Promise.reject(error)
}
interceptor = api.interceptors.response.use(undefined, interceptExpiredSessions)

let currentRequestsCount = 0
api.interceptors.request.use((config) => {
  currentRequestsCount++
  window.idle = false

  return config
})
api.interceptors.response.use(
  (response) => {
    currentRequestsCount--
    if (currentRequestsCount === 0) window.idle = true

    return response
  },
  (error) => {
    currentRequestsCount--
    if (currentRequestsCount === 0) window.idle = true

    return Promise.reject(error)
  },
)
