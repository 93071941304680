<template>
  <span v-if="editable" class="hit-resolve" :class="{ 'hit-resolve--extended': extended }" @click.stop>
    <!-- In expanded hit -->
    <template v-if="extended">
      <MentionableComment ref="mentionableComment" v-model="comment" :placeholder="$t('your-comment')" :suggestions="suggestions" />

      <VSelect v-if="isRiskVisible" v-model="risk" clearable :placeholder="$t('modify-risk')" style="max-width: 200px; width: 100%">
        <VOption v-for="option in riskOptions" :key="option" :label="$t(kebab(option))" :value="option" />
      </VSelect>

      <ButtonResolve :disabled="disabled.disabled" :help="disabled.help" type="comment" @click="resolve(activeResolution)" />
      <ButtonResolve v-if="activeResolution !== 'positive'" :disabled="disabled.disabled" :help="disabled.help" type="include" @click="resolve('positive')" />
      <ButtonResolve v-if="activeResolution !== 'negative'" :disabled="disabled.disabled" :help="disabled.help" type="exclude" @click="resolve('negative')" />
    </template>

    <!-- In table cell-->
    <template v-else>
      <VButton v-if="activeResolution !== 'positive'" :disabled="loading" icon="Check" rounded size="small" type="secondary" @click="resolve('positive')" />
      <VButton v-if="activeResolution !== 'negative'" :disabled="loading" icon="Close" rounded size="small" type="secondary" @click="resolve('negative')" />
    </template>
  </span>
</template>

<script setup lang="ts">
import { kebab } from '~/helpers/textFormatting.ts'
import { $caseStoreKey } from '~/injections.ts'
import { canEditCase } from '~/stores/user.ts'
import { type CaseHitTransformed } from '~/types.ts'
import MentionableComment from './MentionableComment.vue'

interface Props {
  extended?: boolean
  rows: CaseHitTransformed['data'][]
}

const props = defineProps<Props>()

const _resolve = inject('$resolve') as (args: any) => Promise<void>

const { caseStore, sourceStores } = injectRequired($caseStoreKey)

const riskOptions = ['Very high', 'High', 'Moderate', 'Low', 'Very low']
const mentionableComment = ref<InstanceType<typeof MentionableComment>>()
const comment = ref('[]')
const risk = ref<string | null>(null)
const loading = ref(false)

const activeResolution = computed(() => caseStore.activeResolution)
const editable = computed(() => (caseStore.case ? canEditCase(caseStore.case) : false))

const isRiskVisible = computed(
  () => caseStore.case && caseStore.case.services.includes('riskClassification') && props.rows.every((row) => row.risk && typeof row.risk === 'object'),
)

const ids = computed(() => {
  const ids: number[] = props.rows.map((row) => row.case_hit.id)

  for (const row of props.rows) {
    for (const id of row.similar ?? []) {
      const hit = sourceStores.news.hits.find((hit) => hit.data.id === id)

      if (hit && !ids.includes(hit.id)) {
        ids.push(hit.id)
      }
    }
  }

  return ids
})

const disabled = computed(() => new DisabledHelp($t('you-are-required-to-add-a-comment'), comment.value === '[]' && stores.policies.policies.require_hit_comments))

const suggestions = computed(() => [$t('true-positive'), $t('false-positive'), $t('relevant'), $t('not-relevant'), $t('review-needed')])

onMounted(() => {
  if (props.extended) {
    mentionableComment.value?.vmentionable?.vtextarea?.textarea?.focus({ preventScroll: true })
  }
})

async function resolve(resolution: string) {
  if (loading.value) return

  loading.value = true
  await nextFrame()

  await _resolve({
    ids: ids.value,
    resolution,
    comment: comment.value === '[]' ? null : comment.value,
    risk: risk.value?.toLowerCase(),
  })

  comment.value = '[]'
  loading.value = false
}
</script>

<style lang="scss" scoped>
.hit-resolve .v-btn:not(:last-of-type) {
  margin-right: 5px;
}

.hit-resolve--extended {
  display: flex;
  align-items: stretch;

  .v-btn + .v-btn {
    margin-left: 0px;
  }

  > *:not(:first-child) {
    margin-left: 15px;
  }
}

.input--comment :deep() .v-input__suggestions {
  display: flex;
}

.v-mentionable :deep() {
  width: 100%;

  .v-input__prefix {
    align-items: normal;
    top: 5px;
  }

  .v-input__suggestions {
    text-align: left;
  }
}
</style>
