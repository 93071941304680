<template>
  <VTag v-if="count > 1" size="mini" type="primary">{{ count }}</VTag>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    row: {
      type: Object,
      required: true,
    },
  },

  computed: {
    count() {
      if (this.row.case_hit.source === 'companyOfficers') {
        return this.row.positions?.length ?? 0
      }

      if (this.row.case_hit.source === 'businessRegisters') {
        return this.row.connections?.length ?? 0
      }

      return 0
    },
  },
})
</script>
