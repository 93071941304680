<template>
  <VTextHighlight v-if="searchedName?.toLowerCase() === text.toLowerCase() || !$user.id || isOneTimeCase() || $inSafeGhostMode" :queries="highlightTarget" :text="text" />

  <a v-else>
    <VTextHighlight :queries="highlightTarget" :text="text" @click.stop="debounceClick" />
    <ContextMenu v-if="renderTypeMenu" ref="typeMenu">
      <ul>
        <li @click="searchPerson()">{{ $t('perform-a-person-check') }}</li>
        <li @click="searchBusiness()">{{ $t('perform-a-business-check') }}</li>
      </ul>
    </ContextMenu>
  </a>
</template>

<script setup lang="ts">
import debounce from 'lodash-es/debounce.js'

import { isOneTimeCase } from '~/helpers/index.ts'
import { $caseStoreKey } from '~/injections.ts'
import { type CaseOrigin } from '~/stores/case.ts'
import { type CaseForm, type CaseType, type Confidence, type HighlightTarget } from '~/types.ts'
import ContextMenu from './ContextMenu.vue'

export interface SearchData {
  case_id: number | null
  type: CaseType
  form: {
    name: string
    additional_terms: string[]
    confidence: Confidence
    clients: number[]
  }
  reset: boolean
  origin: CaseOrigin
  originCaseId?: number | null
}

interface Props {
  text: string
  type?: CaseType | ''
  highlightTarget?: HighlightTarget
  form?: Partial<CaseForm>
  searchedName?: string
}

const props = withDefaults(defineProps<Props>(), {
  type: undefined,
  highlightTarget: undefined,
  form: () => ({}),
  searchedName: undefined,
})

const s = inject($caseStoreKey, undefined)

const caseStore = s?.caseStore

const selectedType = ref<CaseType>()
const renderTypeMenu = ref(false)
const typeMenu = ref<typeof ContextMenu>()

const debounceClick = debounce(
  function (event: MouseEvent) {
    if (!caseType.value) {
      openTypeMenu(event)

      return
    }

    search()
  },
  300,
  { leading: true, trailing: false },
)

const caseType = computed<CaseType | undefined>(() => {
  if (props.type) {
    return props.type
  }

  if (selectedType.value) {
    return selectedType.value
  }

  if ('gender' in props.form) {
    return 'Person'
  }

  return undefined
})

async function openTypeMenu(event: MouseEvent) {
  renderTypeMenu.value = true
  await nextTick()
  typeMenu.value?.open(event, event)
}

function searchPerson() {
  selectedType.value = 'Person'
  search()
}

function searchBusiness() {
  selectedType.value = 'Business'
  search()
}

function search({ withPreviousName = true } = {}) {
  const data = createSearchData({ withPreviousName })
  $bus.emit('dialog.show', 'search')
  $bus.emit('search', data)
}

function createSearchData({ withPreviousName = false } = {}): SearchData {
  if (!caseType.value) {
    throw new Error('Cannot create search data without type')
  }

  const data: SearchData = {
    type: caseType.value,
    case_id: null,
    form: {
      ...props.form,
      name: props.text,
      additional_terms: [] as string[],
      confidence: stores.settings.settings.default_confidence,
      clients: [],
      sources: stores.policies.policies.preview_default_case_sources,
      status: 'Preview',
    },
    reset: true,
    origin: 'hyperlink',
  }

  if (!caseStore) {
    return data
  }

  if (withPreviousName) {
    data.case_id = caseStore.caseId

    if (caseStore.case) {
      data.form.clients = caseStore.case.clients.map((client) => client.id)
      data.form.additional_terms.push(caseStore.case.name)
      data.originCaseId = caseStore ? caseStore.caseId : null
    }
  }

  return data
}
</script>
