<template>
  <VChart autoresize :init-options="{ devicePixelRatio }" :option="option" @click="click" />
</template>

<script setup lang="ts">
import type { EChartsOption } from 'echarts'

import { options } from '~/services/chart/index.ts'

interface ChartMouseEvent {
  data: { id: string; name: string; value: number }
}
interface Props {
  chartData: { series: { data: { id: string; name: string; value: number }[]; type: 'pie'; label: { show: boolean }; radius: string[] }[]; categories: []; nonIdentified: number }
  color?: string
  chartOptions?: EChartsOption
}

const props = withDefaults(defineProps<Props>(), {
  color: '#330066',
  chartOptions: () => ({}),
})

const emit = defineEmits<{
  dataClick: [{ data: { id: string } }]
}>()

const devicePixelRatio = computed(() => (window.devicePixelRatio < 2 ? window.devicePixelRatio * 2 : window.devicePixelRatio))
const option = computed(() => {
  return options(
    {
      series: props.chartData.series,
      xAxis: {},
      yAxis: { data: props.chartData.categories },
      grid: {},
      ...props.chartOptions,
    },
    'bar',
    1,
    props.color,
  )
})

function click(event: ChartMouseEvent) {
  if ('data' in event) {
    emit('dataClick', event)
  }
}
</script>
