<template>
  <div>
    <div class="link" @click="dialogVisible = true">
      {{ $t('there-are-count-similar-articles', similarIds.length) }}
    </div>

    <VDialog v-model:visible="dialogVisible" placement="fullscreen" :title="$t('similar-articles')">
      <SourceTable v-bind="{ ...sourceTableProps, tableEvents: {} }" :data="filteredHits" :expand-colspan-before="1" source="news" @contextmenu.stop.prevent @expand-open="loadNer">
        <template #expanded-row-start="{ row }">
          <NewsExpand :form="form" :value="row" />
        </template>
      </SourceTable>
    </VDialog>
  </div>
</template>

<script setup lang="ts">
import type ContextMenu from '~/components/ContextMenu.vue'
import SourceTable from '~/components/SourceTable.vue'
import { useSource } from '~/composables/source.ts'
import { type CaseForm } from '~/types.ts'

interface Props {
  form: CaseForm
  similarIds: string[]
}

const props = defineProps<Props>()

const contextMenu = ref<InstanceType<typeof ContextMenu>>()
const sourceTable = ref<InstanceType<typeof SourceTable>>()

const { hits, loadNer, sourceTableProps } = useSource({ ...props, tableSize: 20 }, contextMenu, sourceTable, 'news', ['expand', 'publish_date', 'title', 'risk', 'confidence'])

const dialogVisible = ref(false)
const filteredHits = computed(() => hits.value.map((hit) => hit.data).filter((hitData) => props.similarIds.includes(hitData.id)))
</script>
