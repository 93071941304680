<template>
  <VTooltip placement="top" style="margin-right: 10px" tag="span" wrapper-tag="span">
    <template #content>
      {{ $t('the-vendor-has-removed-the-individual-hit-or-source-from-their-dataset') }}
    </template>

    <VTag :plain="false" size="mini" type="red">
      {{ $t('removed') }}
      <VIcon icon="InfoCircle" :size="11" style="margin-left: 5px" type="white" />
    </VTag>
  </VTooltip>
</template>
