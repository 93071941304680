<template>
  <VChart ref="chart" autoresize :option="option" @click="click" @legendselectchanged="legendSelectChanged($event)" />
</template>

<script setup lang="ts">
import type { PieSeriesOption } from 'echarts'

import { options } from '~/services/chart/index.ts'

interface ChartMouseEvent {
  data: { id: string; name: string; value: number }
}
interface ChartLegendSelectChangedEvent {
  type: 'legendselectchanged'
  name: string
  selected: Record<string, boolean>
}

interface Props {
  chartData: { series: (PieSeriesOption & { data: { name: string; id: string } })[]; categories: []; nonIdentified: number }
  color?: string
  otherColor?: string
  hideLegend?: boolean
  disableLegendClick?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  color: '#330066',
  otherColor: '',
})

const emit = defineEmits<{
  dataClick: [{ data: { id: string } }]
}>()

const chart = ref()

const option = computed(() => {
  return options(
    {
      series: props.chartData.series,
      legend: {
        orient: 'vertical',
        left: 10,
        data: props.chartData.categories,
        type: 'scroll',
        show: !props.hideLegend,
        selectedMode: !props.disableLegendClick,
      },
      grid: {},
    },
    'donut',
    props.chartData.categories.length,
    props.color,
    props.otherColor,
  )
})

function legendSelectChanged(event: ChartLegendSelectChangedEvent) {
  if (props.disableLegendClick) {
    return
  }
  const id = props.chartData.series[0]?.data.find((datum) => datum.name === event.name)?.id
  if (!id) return
  emit('dataClick', { data: { id } })
  for (const key in event.selected) {
    event.selected[key] = true
  }
  chart.value?.setOption({ legend: { selected: event.selected } })
}

function click(event: ChartMouseEvent) {
  if ('data' in event) {
    emit('dataClick', event)
  }
}
</script>
