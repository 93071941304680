<template>
  <div class="field-max-height" style="margin-left: -5px">
    <VTag v-for="keyword in keywords" :key="keyword" style="margin: 5px" type="info">{{ keyword }}</VTag>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    keywords: {
      required: true,
      type: Array,
    },
  },
})
</script>
