<template>
  <div v-if="value">
    <CountryFlagIcon :type="type" :value="value" />
    <template v-if="!flagOnly">
      <CountryLink v-if="$user.id && countryLinkName && !isOneTimeCase()" :highlight-target="highlightTarget" :search-value="countryLinkName" :text="name" />
      <template v-else>{{ name }}</template>
    </template>
  </div>

  <FieldEmpty v-else />
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { isOneTimeCase } from '~/helpers/index.ts'
import { type HighlightTarget } from '~/types.ts'

const typeMap = {
  nationality: 'country',
  nationalities: 'country',
  jurisdiction: 'jurisdiction',
  jurisdiction_code: 'jurisdiction',
}

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },

    field: {
      type: String as PropType<keyof typeof typeMap>,
      default: 'nationality',
    },

    highlightTarget: {
      type: [Array, String] as PropType<HighlightTarget>,
      default: '',
    },

    flagOnly: Boolean,
  },

  computed: {
    type() {
      return typeMap[this.field]
    },

    name() {
      if (this.type === 'jurisdiction') {
        const jurisdiction = stores.countries.jurisdiction(this.value)

        return jurisdiction?.name ?? this.value
      } else if (this.type === 'country') {
        const countryName = stores.countries.countryName(this.value)

        return countryName ?? this.value
      }

      return this.value
    },

    countryLinkName() {
      if (this.type === 'jurisdiction') {
        const jurisdiction = stores.countries.jurisdiction(this.value)

        if (jurisdiction) {
          return jurisdiction.country.name
        }
      }

      return stores.countries.country(this.value)?.name
    },
  },

  methods: {
    isOneTimeCase,
  },
})
</script>
