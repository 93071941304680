<template>
  <VTable :data="tableData" style="margin-bottom: -16px">
    <VTableColumn :label="$t('field')" prop="field" />
    <VTableColumn v-slot="{ row }" :label="$t('input')" prop="input" wrap-text>
      <FieldDate v-if="row.fieldKey === 'date_of_birth'" :value="row.input" />
      <Country v-else-if="['nationalities', 'country_of_birth', 'country_of_residence', 'jurisdictionCountry'].includes(row.fieldKey)" :value="row.input" />
      <Jurisdiction v-else-if="['jurisdiction'].includes(row.fieldKey)" :value="row.input" />
      <template v-else>{{ row.input }}</template>
    </VTableColumn>
    <VTableColumn v-slot="{ row }" :label="$t('matches')" min-width="130" prop="matches" wrap-text>
      <VTag v-if="row.matches.length === 0" type="red">{{ $t('no-match') }}</VTag>
      <span v-for="match in row.matches" v-else :key="match.field">
        <PopoverMatchDetails :details="match.match">
          <VTag :type="match.type">
            {{ match.field }}
            <VIcon icon="QuestionCircle" :size="11" style="margin-left: 5px" :type="match.type" />
          </VTag>
        </PopoverMatchDetails>
      </span>
    </VTableColumn>
  </VTable>
</template>

<script setup lang="ts">
import { type HitData } from '~/types.ts'

interface Props {
  explanation: HitData['explanation']
}

const props = defineProps<Props>()

const matchTypes: Record<string, string> = {
  'Exact match': 'green',
  'Partial match': 'orange',
  'No match': 'red',
  'Not available': 'info',
}

const tableData = computed(() => {
  const data = []

  for (const field in props.explanation) {
    for (const [input, matches] of Object.entries(props.explanation[field])) {
      const item = {
        field: mapField(field),
        fieldKey: field,
        input,
        matches: [] as {
          field: string
          type: string
          match: string
        }[],
      }
      let isAvailable = false
      for (const [field, match] of Object.entries(matches)) {
        if (['Exact match', 'Partial match'].includes(match.match)) {
          item.matches.push({
            field: mapField(field.split('.')[0]),
            type: matchTypes[match.match],
            match: hoverText(match),
          })
          isAvailable = true
        } else if (match.match === 'No match') {
          isAvailable = true
        }
      }
      if (!isAvailable) {
        item.matches.push({
          field: 'Not available',
          type: 'info',
          match: `${$t('not-available')}\n${$t('the-source-did-not-provide-this-information-to-match-on')}`,
        })
      }

      data.push(item)
    }
  }

  return data
})

function hoverText(match: HitData['explanation'][string][string][string]) {
  if (!match.matchDetails) {
    return $t(kebab(match.match))
  }

  let hoverString = $t(kebab(match.match))
  for (const detail of match.matchDetails) {
    hoverString = `${hoverString}\n${$t(kebab(detail))}`
  }

  return hoverString
}
</script>

<style lang="scss" scoped>
.v-tag {
  margin: 5px;
}
</style>
