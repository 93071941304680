<template>
  <div v-if="comments.length > 0">
    <div v-for="comment in paginatedComments" :key="comment.id" style="margin-bottom: 20px">
      <div class="field-max-height flex-center">
        <MentionableComment :model-value="comment.comment" readonly style="margin-right: 5px" />
        <VButton v-if="editable && editable(comment)" icon="Close" rounded size="small" type="icon" @click="emit('remove', comment)" />
      </div>
      <div class="flex-center color-grey-40" style="font-size: 14px; margin-top: 3px">
        <UserAvatar style="margin-right: 7px" :user-id="comment.user_id" />{{ comment.user_name }}, <FieldDate relative style="margin-left: 5px" :value="comment.created_at" />
      </div>
    </div>

    <VPagination v-model="page" :page-size="pageSize" :total="comments.length" />
  </div>
</template>

<script setup lang="ts">
interface Comment {
  id: number
  comment: string
  user_id: number
  user_name: string
  created_at: string
}

interface Props {
  comments: Comment[]
  editable?: (comment: Comment) => boolean
}

const props = defineProps<Props>()

const emit = defineEmits<{
  remove: [comment: Comment]
}>()

const pageSize = 5
const page = ref(1)

const sortedComments = computed(() => sortArrayByObjectKey({ array: props.comments, key: 'created_at' }))
const paginatedComments = computed(() => sortedComments.value.slice(page.value * pageSize - pageSize, page.value * pageSize))
</script>
