<template>
  <VDialog :title="$t('duplicates')" :visible="visible" @update:visible="$emit('update:visible', false)">
    <VTable :data="tableData" :default-sort="{ prop: 'source', order: 'desc' }" :pagination="{}">
      <VTableColumn :label="$t('source')" prop="source" sortable />

      <VTableColumn v-slot="{ row }" :label="$t('url')" prop="url" sortable>
        <a :href="row.url" rel="noreferrer" target="_blank">{{ row.url }}</a>
      </VTableColumn>
    </VTable>
  </VDialog>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    value: {
      required: true,
      type: Array,
    },

    visible: Boolean,
  },

  emits: ['update:visible'],

  computed: {
    tableData: (vm) => vm.value.map((url) => ({ url, source: getDomain(url) })),
  },
})
</script>
