const cache: Record<string, string> = {}
import { dayjs } from '@vartion/ui'

export type CacheRecord = string | number | boolean | unknown[] | object | null

const instances: Record<string, InMemoryCache> = {}
// Create a global of all instances for easy access.
// Useful for debugging and flushing during tests.
window.cacheInstances = instances

export class InMemoryCache {
  tags: string[] = []
  prefix = 'cache:'

  constructor({ tags = [] }: { tags?: string[] } = {}) {
    this.tags = tags
    this.prefix = `cache:${this.tags.join(':')}${tags.length === 0 ? ':' : ''}` // Append an extra : when there are no tags to prevent overlap with non-tagged and tagged cached when checking for prefixes.

    setInterval(this.flushExpired.bind(this), 60000)
  }

  static tags(tags: string | string[]) {
    if (Array.isArray(tags)) {
      tags = tags.join(':')
    }

    const instanceName = `${this.name}:${tags}`
    if (tags in instances) {
      return instances[instanceName]
    }

    const instance = new this({ tags: tags.split(':') })
    instances[instanceName] = instance

    return instance
  }

  flush() {
    for (const key in cache) {
      if (key.startsWith(this.prefix)) {
        delete cache[key]
      }
    }
  }

  flushExpired() {
    for (const key in cache) {
      const item = cache[key]
      if (!item) continue

      const meta = item.split(':')

      if (this.isExpired(meta)) {
        delete cache[key]
      }
    }
  }

  list() {
    const list: Record<string, string> = {}

    for (const key in cache) {
      if (key.startsWith(this.prefix)) {
        list[key] = cache[key]
      }
    }

    return new Promise<Record<string, string>>((resolve) => resolve(list))
  }

  createKey(key: CacheRecord) {
    return `${this.prefix}:${JSON.stringify(key)}`
  }

  createValue(value: CacheRecord, date: dayjs.Dayjs) {
    return `${date.unix()}:${JSON.stringify(value)}`
  }

  async has(key: CacheRecord) {
    return (await this.get(key)) !== null
  }

  async put<T extends CacheRecord>(key: CacheRecord, value: T, date = dayjs().add(1, 'year')) {
    await this.store(this.createKey(key), this.createValue(value, date))
  }

  // eslint-disable-next-line require-await
  protected async store(key: string, value: string) {
    cache[key] = value
  }

  isExpired(meta: string[]) {
    const timestamp = meta[0]

    return dayjs().isAfter(dayjs(Number(timestamp) * 1000))
  }

  getValue(meta: string[]) {
    return meta.slice(1).join(':')
  }

  // eslint-disable-next-line require-await
  async get<T = any>(key: CacheRecord, defaultValue: any = null): Promise<T> {
    key = this.createKey(key)

    const item = cache[key]
    if (!item) return defaultValue

    const meta = item.split(':')

    if (this.isExpired(meta)) {
      delete cache[key]

      return defaultValue
    }

    return JSON.parse(this.getValue(meta))
  }

  async remember<T = any>(key: CacheRecord, duration: dayjs.Dayjs, callback: () => Promise<T>): Promise<T> {
    let value = await this.get(key)

    if (value !== null) {
      return value
    }

    value = await callback()

    await this.put(key, value, duration)

    return value
  }
}
