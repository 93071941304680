<template>
  <span class="link-clipboard" :title="$t('copy-to-clipboard')" @click="onClick">
    <slot />
  </span>
</template>

<script lang="ts">
export default defineComponent({
  methods: {
    async onClick() {
      const text = this.$slots.default()[0].children

      await navigator.clipboard.writeText(text)
      this.$message.success(this.$t('text-was-successfully-copied-to-your-clipboard'))
    },
  },
})
</script>

<style lang="scss" scoped>
.link-clipboard:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
