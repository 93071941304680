<template>
  <VButton :disabled="disabled.disabled" :help="disabled.help" :label="$t('go-to-next-case')" :loading="loading" size="small" type="link" @click="onClick" />
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type CaseFilters } from '~/composables/useCaseFilters.ts'
import { type CaseModel } from '~/types.ts'

export default defineComponent({
  props: {
    caseModel: {
      type: Object as PropType<CaseModel>,
      required: true,
    },
  },

  data: () => ({
    nextCaseId: '',
    loading: false,
  }),

  computed: {
    disabled() {
      return new DisabledHelp(this.$t('all-your-cases-have-been-resolved'), this.nextCaseId === '' && !this.loading)
    },
  },

  async mounted() {
    this.loading = true
    await this.load()
    this.loading = false
  },

  methods: {
    async load() {
      const filters: Partial<CaseFilters> = {
        organization_id: [stores.organization.id],
        client_id: this.caseModel.clients.map((client) => client.id),
        status: ['Monitored', 'In review'],
        custom_filters: ['assigned', 'editable', 'unresolved cases', 'partially resolved cases'],
        sort_by: 'searched_at',
        per_page: 1,
      }

      const { data } = await api.post('v1/cases/searches', filters)
      if (data.data.length) {
        this.nextCaseId = data.data[0].uuid

        return
      }

      // Try again without client filter
      delete filters.client_id
      const { data: data2 } = await api.post('v1/cases/searches', filters)
      if (data2.data.length) {
        this.nextCaseId = data2.data[0].uuid
      }
    },

    onClick() {
      this.$router.push(`/cases/${this.nextCaseId}`)
    },
  },
})
</script>
