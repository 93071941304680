<template>
  <div v-if="validImages.length > 0">
    <VCol v-for="(image, index) in validImages" :key="index" :lg="6" :md="5" :sm="8" :xl="6">
      <img :src="image.url" @error="imageError(image.url)" />
    </VCol>
  </div>
  <FieldEmpty v-else />
</template>

<script lang="ts">
export default defineComponent({
  props: {
    value: {
      required: true,
      type: Array,
    },
  },

  data: () => ({
    imageErrors: [],
  }),

  computed: {
    validImages: (vm) => vm.value.filter((image) => !vm.imageErrors.includes(image.url) && image.url.startsWith('https://')),
  },

  methods: {
    imageError(index) {
      this.imageErrors.push(index)
    },
  },
})
</script>
