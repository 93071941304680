<template>
  <div v-if="tags.length > 0">
    <VTag v-for="tag in tags" :key="tag" type="orange">{{ $t(tag) }}</VTag>
  </div>
  <VTag v-else type="green">{{ $t('none-identified') }}</VTag>
</template>

<script setup lang="ts">
interface Props {
  value: Record<string, number>
}
const props = defineProps<Props>()

const tags = computed(() => adverseTags(props.value))
</script>
