<template>
  <div v-if="value.name">
    {{ $t('branch-of') }}
    <CountryFlagIcon v-if="value.jurisdiction_code" type="jurisdiction" :value="value.jurisdiction_code" />
    <SearchLink :form="form" :text="value.name" :title="$t('perform-a-business-check')" type="Business" />
    <div v-if="value.company_number">{{ $t('company-number-companynumber', form) }}</div>
  </div>
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type HitData } from '~/types.ts'

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<Exclude<HitData['home_company'], undefined>>,
      required: true,
    },
  },

  computed: {
    form() {
      return this.value.company_number ? { company_number: this.value.company_number } : {}
    },
  },
})
</script>
