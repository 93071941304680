<template>
  <VCard v-if="empty" :title="title">
    <SourceEmpty />
  </VCard>

  <div v-if="!empty">
    <div style="text-align: right">
      <FilterButton v-model="caseStore.showFilters" :active="nFiltersActive > 0" />
    </div>

    <SourceFilters
      v-if="!empty"
      v-show="caseStore.showFilters"
      :filtered-hits="filteredHits"
      :filtered-names="filteredNames"
      :hits="hits"
      source="peps"
      @update:filtered-names="filteredNames = $event"
    />
  </div>

  <VCard v-if="!empty" :title="title">
    <template #header-left>
      <div v-if="nFiltersActive" style="margin-top: 4px">
        {{ nFiltersActive }} {{ $t('filters-active', nFiltersActive) }}
        <VButton icon="Close" plain size="small" style="margin-left: 5px" type="primary" @click="clearAllFilters">{{ $t('clear-all') }}</VButton>
      </div>
    </template>

    <SourceTable source="peps" v-bind="sourceTableProps" />

    <ContextMenu ref="contextMenu" v-slot="{ data }">
      <ul>
        <li v-for="item in contextMenuItems" :key="item" @click="contextMenuClick(item, data)">{{ item }}</li>
      </ul>
    </ContextMenu>

    <ActionBarResolve v-model:selection="selection" />
  </VCard>
</template>

<script setup lang="ts">
import ContextMenu from '~/components/ContextMenu.vue'
import SourceTable from '~/components/SourceTable.vue'
import { type SourceProps, useSource } from '~/composables/source.ts'

const props = withDefaults(defineProps<SourceProps>(), {
  form: () => ({}),
  tableSize: 20,
})

const contextMenu = ref<InstanceType<typeof ContextMenu>>()
const sourceTable = ref<InstanceType<typeof SourceTable>>()

const { caseStore, clearAllFilters, contextMenuClick, contextMenuItems, empty, filteredHits, filteredNames, hits, nFiltersActive, selection, sourceTableProps, title } = useSource(
  props,
  contextMenu,
  sourceTable,
  'peps',
  ['selection', 'expand', 'name', 'nationalities', 'source', 'risk', 'confidence', 'resolve', 'comments'],
)
</script>
