<template>
  <Transition mode="out-in" :name="showTransition ? 'v-fade-fast' : ''">
    <div v-if="translation && (!notice || notice?.showTranslation)" key="translation" class="translation__text">
      <slot :is-translated="true" :translation="translation" />
    </div>

    <div v-else key="original" class="translation__text-original">
      <slot :is-translated="false" :translation="text" />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { $translationNoticeKey } from '~/injections.ts'

interface Props {
  text: string
  transliterate?: boolean
  fromLanguage?: null | string
}

const props = withDefaults(defineProps<Props>(), {
  transliterate: false,
  fromLanguage: undefined,
})

const notice = inject($translationNoticeKey, undefined)

const id = counter++

const translation = ref('')
const detected = ref('')
const showTransition = ref(true)

const knownLanguages = computed(() => stores.settings.settings.known_languages ?? [])
const knownScripts = computed(() => knownLanguages.value.map((code) => stores.translation.scripts[code]))

watch(
  () => stores.settings.settings.translation_language,
  () => {
    translation.value = ''
    delete notice?.translations[id]
  },
)

async function translate() {
  if (translation.value) return

  if (!(await shouldTranslate())) {
    translation.value = ''
    delete notice?.translations[id]

    return
  }

  notice?.translating.add(id)

  try {
    const { cached, translation: response } = await stores.translation.translate({ text: props.text })

    showTransition.value = !cached

    if (response && response.detectedLanguage !== response.language) {
      translation.value = response.translation
      if (notice) {
        notice.translations[id] = {
          from: stores.translation.languages[response.detectedLanguage]?.name ?? detected.value,
          to: stores.translation.languages[response.language]?.name ?? stores.settings.settings.translation_language,
        }
      }
    } else {
      translation.value = ''
      delete notice?.translations[id]
    }
  } finally {
    notice?.translating.delete(id)
  }
}

async function shouldTranslate() {
  if (props.fromLanguage) {
    if (knownLanguages.value.includes(props.fromLanguage)) {
      return false
    }

    if (props.fromLanguage in stores.translation.languages) {
      return true
    }
  }

  if (props.transliterate) {
    await stores.translation.loadScripts()
    detected.value = await stores.translation.detectScript({ text: props.text })

    return !knownScripts.value.includes(detected.value) && detected.value !== stores.settings.settings.translation_language
  }

  await stores.translation.loadLanguages()
  detected.value = await stores.translation.detectLanguage({ text: props.text })

  return !knownLanguages.value.includes(detected.value) && detected.value !== stores.settings.settings.translation_language
}

translate()
</script>

<script lang="ts">
let counter = 0
</script>
