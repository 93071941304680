<script lang="ts">
const getCountry = (value: string, type: string) => {
  try {
    if (type === 'country') {
      return stores.countries.country(value)
    }

    if (type === 'jurisdiction') {
      return stores.countries.jurisdiction(value)?.country
    }
  } catch (error) {
    return null
  }
}

export default defineComponent({
  functional: true,

  props: {
    value: {
      required: true,
      type: String,
    },

    type: {
      type: String,
      default: 'country',
    },
  },

  render() {
    const country = getCountry(this.value, this.type)

    return h('span', {
      class: ['flag-icon', `flag-icon-${country ? country['alpha-2'].toLowerCase() : 'none'}`],
      attrs: { title: country?.name ?? '' },
      style: { marginRight: '5px' },
    })
  },
})
</script>
