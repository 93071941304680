<template>
  <div>
    <VTable
      ref="table"
      v-bind="tableProps"
      check-on-checkbox-cell-click
      check-on-dragover
      :checkable="columns.includes('selection')"
      :checked-rows="checkedRows"
      class="source-table"
      :data="tableData"
      :default-sort="defaultSort"
      :expand-colspan-before="expandColspanBefore"
      :expandable="columns.includes('expand')"
      remote-sort
      :row-key="rowKey"
      v-on="tableEvents"
      @expand-open="$emit('expandOpen', $event)"
      @sort="sort"
      @update:checked-rows="$emit('update:checkedRows', $event)"
    >
      <template #empty>
        <slot name="empty">
          <FieldEmpty />
        </slot>
      </template>

      <template v-if="columns.includes('expand')" #expand="{ row }">
        <HitExpandedRow :form="form" :resolve="columns.includes('resolve')" :row="row" :source="source" :type="nameType">
          <template #expand>
            <slot name="expand" :row="row" />
          </template>

          <template #expanded-row-start>
            <slot name="expanded-row-start" :row="row" />
          </template>

          <template #expanded-row-end>
            <slot name="expanded-row-end" :row="row" />
          </template>

          <template #expanded-row-right>
            <slot name="expanded-row-right" :row="row" />
          </template>
        </HitExpandedRow>
      </template>

      <template v-for="column in columns" :key="column">
        <VTableColumn v-if="column === 'similar'" v-slot="{ row }" prop="similar" width="55">
          <VTag v-if="row.similar" size="mini" type="primary">{{ row.similar.length }}</VTag>
        </VTableColumn>

        <VTableColumn v-else-if="column === 'entry_count'" v-slot="{ row }" prop="entry_count" width="55">
          <FieldEntryCount :row="row" />
        </VTableColumn>

        <VTableColumn v-else-if="column === 'type'" v-slot="{ row }" :label="$t('type')" prop="type" :sortable="sortable(column)" width="80">
          <CaseIcon v-if="row.type && row.type.value" style="margin-right: 10px" :type="row.type.value" />
        </VTableColumn>

        <VTableColumn v-else-if="column === 'name'" v-slot="{ row, expanded }" :label="$t('name')" max-width="200" prop="name" show-overflow-tooltip :sortable="sortable(column)">
          <RemovedTag v-if="row.case_hit?.removed_from_source" />
          <ArchivedTag v-if="isArchived(row)" />
          <VTag v-if="row.case_hit && row.case_hit.hasChanges" class="updated-tag" :plain="false" size="mini" type="primary">{{ $t('updated') }}</VTag>
          <FieldName
            v-if="row.name"
            :elastic-highlights="expanded ? row?.highlight ?? {} : {}"
            :form="createHitForm(row)"
            :highlight-target="createHighlightQueries(form, 'name')"
            :searched-name="searchedName"
            :type="hitType(row)"
            :value="row.name"
          />
          <FieldEmpty v-else />
        </VTableColumn>

        <VTableColumn
          v-else-if="column === 'title'"
          v-slot="{ row }"
          hide-on-expand
          :label="$t('title')"
          max-width="250"
          prop="title"
          show-overflow-tooltip
          :sortable="sortable(column)"
        >
          <Translation v-if="row.title" v-slot="{ translation, isTranslated }" :from-language="row.language" :text="row.title">
            <RemovedTag v-if="row.case_hit?.removed_from_source" />
            <ArchivedTag v-if="isArchived(row)" />
            <VTag v-if="row.case_hit && row.case_hit.hasChanges" :plain="false" size="mini" type="primary">{{ $t('updated') }}</VTag>
            <VTag v-if="isTranslated" :plain="false" size="mini" type="blue-3">{{ $t('translated') }}</VTag>
            <VTag v-if="!$stores.initialState.fullySupportedLanguages.includes(row.language)" :plain="false" size="mini" type="blue-3">{{ $t('partial-support') }}</VTag>
            <VTextHighlight :queries="form.name ? createHighlightQueries(form, 'name') : []" :text="translation" />
          </Translation>
          <FieldEmpty v-else />
        </VTableColumn>

        <VTableColumn
          v-else-if="column === 'nationalities'"
          v-slot="{ row }"
          hide-on-expand
          :label="$t('nationality')"
          max-width="200"
          prop="nationalities"
          show-overflow-tooltip
          :sortable="sortable(column)"
        >
          <FieldNationality v-if="row.nationalities && row.nationalities.length > 0" :value="row.nationalities[0].country_code || row.nationalities[0].country" />
          <FieldEmpty v-else />
        </VTableColumn>

        <VTableColumn
          v-else-if="column === 'status'"
          v-slot="{ row }"
          hide-on-expand
          :label="$t('status')"
          max-width="150"
          prop="status"
          show-overflow-tooltip
          :sortable="sortable(column)"
        >
          <span v-if="row.status" v-text="row.status" />
          <FieldEmpty v-else />
        </VTableColumn>

        <template v-else-if="column === 'connections'">
          <VTableColumn v-slot="{ row }" hide-on-expand :label="$t('connections')" max-width="200" prop="connections" show-overflow-tooltip :sortable="sortable(column)">
            <FieldConnectionsName v-if="row.connections" :connections="row.connections" />
            <FieldEmpty v-else />
          </VTableColumn>

          <VTableColumn v-slot="{ row }" hide-on-expand width="66">
            <FieldCheckedName
              v-if="row.connections && row.connections[0] && row.connections[0].name"
              :type="connectionType(row.connections[0].type)"
              :value="row.connections[0].name"
            />
          </VTableColumn>

          <VTableColumn v-slot="{ row }" hide-on-expand width="65">
            <PopoverMultiColumn v-if="row.connections && row.connections.length > 1" :items="row.connections.slice(1)">
              <VTag>+ {{ row.connections.length - 1 }}</VTag>
            </PopoverMultiColumn>
          </VTableColumn>
        </template>

        <VTableColumn
          v-else-if="column === 'jurisdiction_code'"
          v-slot="{ row }"
          hide-on-expand
          :label="$t('jurisdiction')"
          max-width="200"
          prop="jurisdiction_code"
          show-overflow-tooltip
          :sortable="sortable(column)"
        >
          <FieldNationality field="jurisdiction_code" :value="row.jurisdiction_code" />
        </VTableColumn>

        <VTableColumn
          v-else-if="column === 'source'"
          v-slot="{ row }"
          cell-class="source-column"
          hide-on-expand
          :label="$t('source')"
          max-width="150"
          prop="source"
          show-overflow-tooltip
          :sortable="sortable(column)"
        >
          <VPopover
            v-if="row.source && (!Array.isArray(row.source) || row.source.length > 0)"
            placement="right"
            reference-class="flex-center"
            :trigger="Array.isArray(row.source) && row.source.length > 1 ? 'hover' : 'disabled'"
          >
            <template #reference>
              <FieldSource style="display: inline-block; margin-right: 7px" :url="row.url" :value="firstSource(row.source)" />
              <VTag v-if="Array.isArray(row.source) && row.source.length > 1" size="small">{{ `+ ${Object.keys(row.source).length - 1}` }}</VTag>
            </template>

            <template v-if="Array.isArray(row.source)">
              <FieldSource v-for="rowSource in row.source" :key="rowSource" :url="row.url" :value="rowSource" />
            </template>
          </VPopover>
          <FieldEmpty v-else />
        </VTableColumn>

        <VTableColumn v-else-if="column === 'source_url'" v-slot="{ row }" hide-on-expand :label="$t('source')" prop="source_url" :sortable="sortable(column)">
          <a v-if="row.url" :href="row.url" target="_blank" @click="$stores.apmEvents.track({ event: 'open-source', category: 'resolving' })" v-text="getDomain(row.url)" />
          <FieldEmpty v-else />
        </VTableColumn>

        <VTableColumn v-else-if="column === 'adverse'" hide-on-expand :label="$t('adverse-events')" prop="adverse" :sortable="sortable(column)">
          <template #default="{ row }">
            <FieldAdverseEvents v-if="row.adverse" :value="row.adverse" />
            <FieldEmpty v-else />
          </template>
          <template #header>
            {{ $t('adverse-events') }}
            <IconHelp :text="adverseHelp" />
          </template>
        </VTableColumn>

        <VTableColumn v-else-if="column === 'publish_date'" v-slot="{ row }" :label="$t('publish-date')" prop="publish_date" :sortable="sortable(column)" :width="120">
          <FieldDate v-if="row.publish_date" class="color-grey-40" :value="row.publish_date" />
        </VTableColumn>

        <template v-else-if="column === 'company.name'">
          <VTableColumn v-slot="{ row }" hide-on-expand :label="$t('company')" max-width="160" prop="company.name" show-overflow-tooltip :sortable="sortable(column)">
            <FieldCompanyName
              v-if="row.company && row.company.name"
              :jurisdiction="row.company.jurisdiction_code || row.company.jurisdiction"
              :name="row.company.name"
              :searched-name="searchedName"
            />
            <FieldEmpty v-else />
          </VTableColumn>

          <VTableColumn v-slot="{ row }" hide-on-expand width="100">
            <FieldCheckedName v-if="row.company && row.company.name" type="Business" :value="row.company.name" />
          </VTableColumn>
        </template>

        <VTableColumn v-else-if="column === 'position'" v-slot="{ row }" hide-on-expand :label="$t('position')" prop="position" :sortable="sortable(column)">
          <FieldPosition :position="row.position" :type="row.status" />
          <VTag v-if="row.position && row.status" size="small" style="margin-left: 5px" type="orange">{{ row.status }}</VTag>
        </VTableColumn>

        <VTableColumn v-else-if="column === 'positions'" v-slot="{ row }" hide-on-expand :label="$t('positions')" prop="positions" :sortable="sortable(column)">
          <template v-if="row.positions?.length > 0">
            <FieldPosition :position="row.positions[0].position" />
            <VTag v-if="row.positions.length > 1" size="small" style="margin-left: 5px">+ {{ row.positions.length - 1 }}</VTag>
            <VTag v-if="row.positions[0].status" size="small" style="margin-left: 5px" type="orange">{{ row.positions[0].status }}</VTag>
          </template>
          <FieldEmpty v-else />
        </VTableColumn>

        <VTableColumn v-else-if="column === 'updated_at'" v-slot="{ row }" hide-on-expand :label="$t('updated-at')" prop="updated_at" :sortable="sortable(column)">
          <FieldDate v-if="row.updated_at" :value="row.updated_at" />
          <FieldEmpty v-else />
        </VTableColumn>

        <VTableColumn v-else-if="column === 'confidence'" v-slot="{ row }" hide-on-expand :label="$t('confidence')" max-width="80" prop="score" :sortable="sortable(column)">
          <FieldConfidence v-if="typeof row.score === 'number'" :implicit-feedback="row.case_hit.implicit_feedback" :value="row.score" />
          <FieldEmpty v-else />
        </VTableColumn>

        <VTableColumn v-else-if="column === 'risk'" v-slot="{ row }" hide-on-expand :label="$t('risk')" prop="risk.score" :sortable="sortable(column)" width="100">
          <FieldRisk v-if="row.risk" :deleted="row.case_hit?.removed_from_source" :risk="getHighestRisk(row.case_hit?.user_risk_score) ?? row.risk.classification" rounded />
        </VTableColumn>

        <VTableColumn v-else-if="column === 'resolve'" v-slot="{ row }" :label="$t('resolve')" width="85">
          <HitResolve v-if="!requiresComments" :rows="[row]" />
          <VButton v-else icon="CommentEmpty" rounded size="small" type="secondary" @click.stop="checkRow(row)" />
        </VTableColumn>

        <VTableColumn v-else-if="column === 'comments'" v-slot="{ row }" :label="$t('comments')" prop="case_hit.comments" sortable width="85">
          <HitCommentButton :editable="columns.includes('resolve')" :hit="row" />
        </VTableColumn>
      </template>

      <slot name="custom-columns" />
    </VTable>

    <slot v-if="pagination" name="pagination">
      <VPagination v-model="currentPage" :page-size="pageSize" :total="data.length" @update:model-value="pageChange" />
    </slot>
  </div>
</template>

<script lang="ts">
import { type VTable } from '@vartion/ui'
import { type PropType } from 'vue'

import { getHighestRisk } from '~/helpers/risk.ts'
import { route } from '~/router.ts'
import {
  type BusinessForm,
  type CaseForm,
  type CaseHitTransformed,
  type CaseSourceName,
  type CaseType,
  type PersonForm,
  type SearchHitTransformed,
  type SortOrder,
} from '~/types.ts'

const typeMap: Record<string, CaseType> = {
  business: 'Business',
  entity: 'Business',
  individual: 'Person',
  person: 'Person',
}

type Hit = SearchHitTransformed['data'] | CaseHitTransformed['data']

export default defineComponent({
  props: {
    source: {
      type: String as PropType<CaseSourceName>,
      required: true,
    },

    data: {
      type: Array as PropType<Hit[]>,
      required: true,
    },

    columns: {
      type: Array as PropType<readonly string[]>,
      required: true,
    },

    expandColspanBefore: {
      type: Number,
      default: 1,
    },

    sortableColumns: {
      type: [Array, Boolean],
      default: true,
    },

    nameType: {
      type: String as PropType<CaseType>,
      default: '',
    },

    tableProps: {
      type: Object,
      default: () => ({}),
    },

    tableEvents: {
      type: Object,
      default: () => ({}),
    },

    form: {
      type: Object as PropType<Partial<CaseForm>>,
      default: () => ({}),
    },

    pagination: {
      type: Boolean,
      default: false,
    },

    defaultSort: {
      type: Object as PropType<{ prop: string; order: SortOrder }>,

      default: () => ({
        prop: '',
        order: 'desc',
      }),
    },

    beforeSort: {
      type: Function,
      default: null,
    },

    rowKey: {
      type: String,
      default: 'id',
    },

    checkedRows: {
      type: Array as PropType<SearchHitTransformed['data'][]>,
      default: () => [],
    },

    pageSize: {
      type: Number,
      default: 20,
    },
  },

  emits: ['expandOpen', 'update:checkedRows', 'paginated'],

  setup() {
    stores.archivedSources.fetch()

    return {
      table: ref<InstanceType<typeof VTable>>(),
    }
  },

  data: (vm) => ({
    sortBy: vm.defaultSort.prop as string | null,
    sortOrder: vm.defaultSort.order ?? 'desc',
    sortTransforms: {
      nationalities: vm.transformNationalities,
      jurisdiction_code: vm.transformJurisdictionCode,
      adverse: vm.firstAdverseTag,
      source_url: getDomain,
      similar: (item?: string[]) => (item === undefined ? [] : item),
    },

    sortCustomTransforms: {
      'risk.score': (row: CaseHitTransformed['data'] | SearchHitTransformed['data']) => row.case_hit?.user_risk_score ?? row.risk?.score,
    },

    sortKeys: {
      source_url: 'source',
      type: 'type.value',
    },

    adverseHelp: [
      vm.$t('media-articles-are-analysed-for-22-adverse-events-20-of-which-correspond-to-the-fatf-designated-categories-of-offences'),
      vm.$t('the-full-list-of-adverse-events-can-be-found-in-the-product-documentation'),
      vm.$t('pascal-decides-which-adverse-event-if-any-is-most-prominent-in-an-article-and-this-adverse-event-label-will-be-shown'),
      vm.$t('currently-adverse-media-classification-is-only-supported-for-dutch-and-english-media-articles'),
    ],

    currentPage: 1,
  }),

  computed: {
    searchedName() {
      return this.form.name ?? ''
    },

    countriesByAlpha2Code() {
      return stores.countries.countriesByAlpha2Code
    },

    jurisdictionsByCode() {
      return stores.countries.jurisdictionsByCode
    },

    sortKey() {
      return this.sortKeys[this.sortBy] ?? this.sortBy
    },

    requiresComments() {
      return stores.policies.policies.require_hit_comments
    },

    presorted() {
      // Stabilize sort
      let hits = sortArrayByObjectKey({ array: this.data, key: 'id', order: 'desc' })

      const presort: { key: string; order: 'asc' | 'desc' }[] = [
        { key: 'company.name', order: 'asc' },
        { key: 'adverse', order: 'asc' },
        { key: 'publish_date', order: 'desc' },
        { key: 'risk.score', order: 'desc' },
      ]

      for (const { key, order } of presort) {
        hits = sortArrayByObjectKey({
          array: hits,
          key,
          order,
          transform: this.sortTransforms[key],
          customTransform: this.sortCustomTransforms[this.sortBy],
        })
      }

      return hits
    },

    sorted() {
      if (this.sortBy === null) return this.presorted

      return sortArrayByObjectKey({
        array: this.presorted,
        key: this.sortKey,
        order: this.sortOrder,
        transform: this.sortTransforms[this.sortBy],
        customTransform: this.sortCustomTransforms[this.sortBy],
      })
    },

    tableData() {
      if (!this.pagination) return this.sorted

      const end = this.currentPage * this.pageSize
      const start = end - this.pageSize

      return this.sorted.slice(start, end)
    },

    commentHitId() {
      return Number(route.value.query.hit_id)
    },
  },

  watch: {
    commentHitId: {
      immediate: true,

      async handler(commentHitId) {
        if (!commentHitId) {
          return
        }

        const index = this.sorted.findIndex((hit) => 'id' in hit.case_hit && hit.case_hit.id === commentHitId)
        if (index < 0) {
          return
        }

        // Wait for the table to be rendered
        await nextTick()

        const tableHit = this.sorted[index]
        if (this.pagination) {
          this.currentPage = Math.floor(index / this.pageSize) + 1
          this.paginate()
        }
        const pageIndex = this.tableData.indexOf(tableHit)
        const table = this.table

        if (table && pageIndex > -1) {
          table.expandedRows = []
          table.toggleExpand(this.tableData[pageIndex])
          for (let i = 0; i < 30; i++) {
            const element = document.getElementById(`row-comment-${commentHitId}`)
            if (element) {
              element.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' })
              break
            } else {
              await delay(100)
            }
          }
        } else {
          $message.error($t('api-error'))
        }
      },
    },
  },

  methods: {
    getDomain,
    createHighlightQueries,
    getHighestRisk,

    async sort({ prop, order }: { prop: string; order: SortOrder }) {
      if (order === '' || prop === '') {
        prop = this.defaultSort.prop
        order = this.defaultSort.order
      }

      if (typeof this.beforeSort === 'function') {
        await this.beforeSort({ prop, order })
      }

      this.sortBy = prop
      this.sortOrder = order
    },

    transformNationalities(nationalities?: { country_code: string; country: string }[]) {
      if (nationalities && nationalities.length > 0) {
        if (nationalities[0].country_code) {
          return this.countriesByAlpha2Code[nationalities[0]?.country_code.toLowerCase()]?.name
        }

        return nationalities[0].country
      }

      return null
    },

    firstSource(sources: string | string[]) {
      if (Array.isArray(sources)) {
        return sources[0]
      }

      return sources
    },

    transformJurisdictionCode(code: string | null) {
      if (!code) return null

      return this.jurisdictionsByCode[code.toLowerCase()]?.name
    },

    pageChange(page: number) {
      this.paginate({ page })
    },

    paginate({ page }: { page?: number } = {}) {
      if (page) {
        this.currentPage = page
      }

      // When changing pageSize and on page > 1, check if there is still data in the table
      if (this.tableData.length === 0) {
        this.currentPage = 1
      }

      this.$emit('paginated')
    },

    firstAdverseTag(adverse: object | null) {
      if (!adverse) return null

      const tags = adverseTags(adverse)

      if (tags.length > 0) {
        return `.${tags[0]}`
      }

      return 'Nonadverse'
    },

    sortable(column: string) {
      if (this.sortableColumns === true) return true

      if (Array.isArray(this.sortableColumns)) {
        return this.sortableColumns.some((sortableColumn) => sortableColumn === column) ? true : false
      }

      return false
    },

    connectionType(value: string | null | { value: string | null }) {
      if (!value) {
        return ''
      }

      if (typeof value === 'object' && 'value' in value) {
        value = value.value
      }

      if (!value) {
        return ''
      }

      value = value.toLowerCase()

      if (value in typeMap) {
        return typeMap[value]
      }

      return ''
    },

    createHitForm(hit: SearchHitTransformed['data']) {
      const gender = hit.gender

      const form: Partial<PersonForm & BusinessForm> = {}

      if ('gender' in hit) {
        form.gender = typeof gender === 'string' ? gender : gender?.value ?? ''
      }

      if ('nationalities' in hit && hit.nationalities) {
        form.nationalities = hit.nationalities.map((nationality) => country(nationality.country_code ?? nationality.country ?? '')?.['alpha-3']).filter((code) => code) as string[]
      }

      if (hit.jurisdiction_code) {
        form.jurisdiction = hit.jurisdiction_code
      }

      return form
    },

    hitType(hit: SearchHitTransformed['data']): undefined | CaseType {
      if (this.nameType) {
        return this.nameType
      }

      if (hit.type && hit.type.value) {
        return typeMap[hit.type.value]
      }
    },

    checkRow(row: SearchHitTransformed['data']) {
      const table = this.table
      if (!table) {
        return
      }

      if (this.checkedRows.length === 1 && this.checkedRows.map((value: SearchHitTransformed['data']) => value.id).includes(row.id)) {
        table.$emit('update:checkedRows', [])

        return
      }
      table.$emit('update:checkedRows', [row])
    },

    isArchived(hit: SearchHitTransformed['data']) {
      if (!hit.source) {
        return false
      }

      let type = this.source as string
      if (type === 'companyOfficers') {
        type = 'officers'
      }
      if (type === 'businessRegisters') {
        type = 'companies'
      }
      if (type === 'enforcements') {
        type = 'sanctions'
      }

      const sources = stores.archivedSources.perType[type] ?? []
      if (Array.isArray(hit.source)) {
        return hit.source.some((source) => sources.includes(source.toLowerCase()))
      }

      return sources.includes(hit.source.toLowerCase())
    },
  },
})
</script>

<style lang="scss" scoped>
:deep() .source-column li {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
