<template>
  <div v-if="message" class="translation-notice">
    <span style="flex-grow: 1; font-weight: 500" v-text="message" />

    <span v-if="Object.keys(translations).length > 0" style="float: right">
      <VButton :label="showTranslation ? $t('show-original') : $t('show-translation')" type="link" @click="showTranslation = !showTranslation" />
    </span>
  </div>

  <slot />
</template>

<script setup lang="ts">
import { $translationNoticeKey, type Translations } from '~/injections.ts'

const message = computed(() => {
  if (translating.value.size > 0) {
    return $t('text-is-being-translated-please-wait-a-moment')
  } else if (Object.keys(translations).length > 0) {
    return $t('automatically-translated-from-from-to-to', {
      from: Array.from(uniqueTranslationLanguages.value.from).join(', '),
      to: Array.from(uniqueTranslationLanguages.value.to).join(', '),
    })
  }

  return ''
})

const translations = reactive<Translations>({})
const translating = ref(new Set<number>())
const showTranslation = ref(true)

const uniqueTranslationLanguages = computed(() => {
  const from = new Set<string>()
  const to = new Set<string>()

  for (const key in translations) {
    from.add(translations[key].from)
    to.add(translations[key].to)
  }

  return { from, to }
})

provide(
  $translationNoticeKey,
  reactive({
    showTranslation,
    translating,
    translations,
  }),
)
</script>

<style lang="scss" scoped>
.translation-notice {
  display: flex;
  align-items: center;
  padding: 3px 12px;
  height: 40px;
  background-color: var(--color-blue-1);
  border-radius: 5px;
  margin-bottom: 16px;
}
</style>
