<template>
  <div class="field-max-height">
    <div v-for="(officer, index) in formatted" :key="index" class="officer">
      <div v-if="officer.type || officer.name">
        <CaseIcon v-if="officer.type" style="margin-right: 7px" :type="officer.type" />

        <template v-if="officer.name">
          <SearchLink :text="officer.name" :type="officer.type" />
          <FieldCheckedName :type="officer.type" :value="officer.name" />
        </template>
      </div>

      <div v-if="officer.position">{{ $t('position') }}: {{ officer.position }}</div>
      <div v-if="officer.listed_at">{{ $t('date') }}: {{ formatDate(officer.listed_at, $t('unknown')) }} - {{ formatDate(officer.listed_until, $t('unknown')) }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type CaseType, type Officer } from '~/types.ts'

const typeMap: Record<string, CaseType> = {
  business: 'Business',
  entity: 'Business',
  individual: 'Person',
  person: 'Person',
}

export default defineComponent({
  props: {
    officers: {
      required: true,
      type: Array as PropType<Officer[]>,
    },
  },

  computed: {
    formatted() {
      return this.officers.map((officer) => ({ ...officer, type: this.type(officer.type) }))
    },
  },

  methods: {
    formatDate,

    type(value: string | { value: string }) {
      if (!value) {
        return ''
      }

      if (typeof value === 'object' && 'value' in (value ?? {})) {
        value = value.value
      }

      if (typeof value === 'string') {
        value = value.toLowerCase()

        if (value in typeMap) {
          return typeMap[value]
        }
      }

      return ''
    },
  },
})
</script>

<style lang="scss" scoped>
.officer:not(:last-child) {
  margin-bottom: 2em;
}
</style>
