<template>
  <div style="position: relative">
    <Translation v-slot="{ translation }" :from-language="value.language" :text="value.title">
      <div class="title-20" style="margin-bottom: 15px; white-space: initial" v-text="translation" />
    </Translation>

    <Translation v-slot="{ translation }" :from-language="value.language" :text="value.summary">
      <FieldSummary :highlight-target="highlightTarget('summary')" max-height="none" :searched-name="form.name" style="margin-bottom: 20px" :value="translation" />
    </Translation>

    <HitField class="border-bottom" field="similar" :field-size="5" :form="form" :row="value" :value-size="19" />
  </div>
</template>

<script setup lang="ts">
import { type CaseForm } from '~/types.ts'

interface Props {
  value: Record<string, any>
  form: CaseForm
}

const props = defineProps<Props>()

function highlightTarget(field: string) {
  return createHighlightQueries(props.form, field)
}
</script>
