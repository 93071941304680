<template>
  <div class="partial-support-notice">
    {{
      $t(
        hasTranslations
          ? 'the-original-language-of-this-article-is-not-fully-supported-adverse-events-and-industries-are-disabled-and-entities-found-can-be-of-lesser-quality'
          : 'the-language-of-this-article-is-not-fully-supported-adverse-events-and-industries-are-disabled-and-entities-found-can-be-of-lesser-quality',
      )
    }}
  </div>
</template>

<script setup lang="ts">
import { $translationNoticeKey } from '~/injections.ts'

const translationNotice = inject($translationNoticeKey, undefined)

const hasTranslations = computed(() => Object.keys(translationNotice?.translations ?? {}).length > 0)
</script>

<style lang="scss" scoped>
.partial-support-notice {
  display: flex;
  align-items: center;
  padding: 3px 12px;
  height: 40px;
  background-color: var(--color-blue-1);
  border-radius: 5px;
  margin-bottom: 16px;
  font-weight: 500;
}
</style>
