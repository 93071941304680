<template>
  <div style="width: 320px; height: 310px; overflow: hidden">
    <h1 class="matrix-title">{{ $t('risk-classification') }}</h1>

    <VRow :gutter="10" style="height: 260px; padding: 25px 25px 25px 25px">
      <VTableKeyValue>
        <VTableKeyValueRow v-for="(range, classification) in probabilities" :key="range" :row-key="range">
          <FieldRisk :dimmed="classification !== risk.classification" :risk="classification" rounded />
        </VTableKeyValueRow>
      </VTableKeyValue>
    </VRow>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    risk: {
      type: Object,
      required: true,
    },
  },

  computed: {
    probabilities() {
      return {
        'very high': `${this.$t('risk-chart-score')} > 0.24`,
        high: `0.12 < ${this.$t('risk-chart-score')} <= 0.24`,
        moderate: `0.06 < ${this.$t('risk-chart-score')} <= 0.12`,
        low: `0.03 < ${this.$t('risk-chart-score')} <= 0.06`,
        'very low': `${this.$t('risk-chart-score')} <= 0.03`,
      }
    },
  },

  methods: {
    riskColor(riskScore, impact, probability) {
      const style = { color: 'white' }
      if (riskScore > 0.24) {
        style.backgroundColor = '#B11B1B'
      } else if (riskScore > 0.12) {
        style.backgroundColor = '#D62121'
      } else if (riskScore > 0.06) {
        style.backgroundColor = '#FA6636'
      } else if (riskScore > 0.03) {
        style.backgroundColor = '#3B923D'
      } else if (riskScore <= 0.03) {
        style.backgroundColor = '#4DB04F'
      }

      if (impact.toLowerCase() === this.risk.impact && probability.toLowerCase() === this.risk.probability) {
        style.borderStyle = 'solid'
        style.borderWidth = '3px'
        style.borderColor = this.adjust(style.backgroundColor, -25)
        style.lineHeight = '24px'
      }
      if (impact.toLowerCase() !== this.risk.impact || this.probabilityOrder.indexOf(this.risk.minSetProbability) < this.probabilityOrder.indexOf(probability.toLowerCase())) {
        style.opacity = 0.3
      }

      return style
    },

    adjust(hexInput, percent) {
      let hex = hexInput

      // strip the leading # if it's there
      hex = hex.replace(/^\s*#|\s*$/g, '')

      // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
      if (hex.length === 3) {
        hex = hex.replace(/(.)/g, '$1$1')
      }

      let r = parseInt(hex.substr(0, 2), 16)
      let g = parseInt(hex.substr(2, 2), 16)
      let b = parseInt(hex.substr(4, 2), 16)

      const calculatedPercent = (100 + percent) / 100

      r = Math.round(Math.min(255, Math.max(0, r * calculatedPercent)))
      g = Math.round(Math.min(255, Math.max(0, g * calculatedPercent)))
      b = Math.round(Math.min(255, Math.max(0, b * calculatedPercent)))

      return `#${r.toString(16).toUpperCase()}${g.toString(16).toUpperCase()}${b.toString(16).toUpperCase()}`
    },
  },
})
</script>

<style lang="scss" scoped>
.colored-cell {
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.matrix-title {
  text-align: center;
  margin-top: 20px;
}

.left-header-cell {
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 30px;
  color: #585858;
}
:deep() td {
  padding: 10px;
}
</style>
