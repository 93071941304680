<template>
  <div>
    <VTable :data="reports" max-width="250" row-key="id" show-overflow-tooltip>
      <VTableColumn prop="name" />

      <VTableColumn v-slot="{ row }: { row: Report }" width="200">
        {{ types[row.type] }}
      </VTableColumn>

      <VTableColumn v-slot="{ row }" prop="created_at" width="120">
        <FieldDate past relative :value="row.created_at" />
      </VTableColumn>

      <VTableColumn v-slot="{ row }" width="120">
        {{ $dayjs(row.created_at).add(90, 'days').fromNow() }}
      </VTableColumn>

      <VTableColumn v-slot="{ row }: { row: Report }" cell-class="actions-cell" width="120">
        <VButton class="delete-icon-button" icon="Delete" :icon-size="16" size="small" type="icon" @click="removeReport(row)" />
        <VButton icon="Download" :icon-size="16" size="small" type="icon" @click="$emit('download', row)" />
        <VButton icon="Mail" :icon-size="16" size="small" type="icon" @click="$emit('mail', row)" />
      </VTableColumn>
    </VTable>

    <VPagination
      v-model="meta.current_page"
      :page-count="meta.last_page"
      :page-size="10"
      style="margin-bottom: 20px; margin-top: 0px; padding-top: 10px; border-top: var(--border-light)"
      @update:model-value="fetch"
    />
  </div>
</template>

<script setup lang="ts">
import { type Report, type SearchResponse } from '~/types.ts'
import { reportTypes } from './ViewReports.vue'

interface Props {
  batchId: string
  remove: (reports: Report[]) => Promise<void>
}

const props = defineProps<Props>()

const reports = ref<Report[]>([])
const meta = reactive(createMeta())
const types = reportTypes()

defineEmits<{
  download: [report: Report]
  mail: [report: Report]
}>()

async function fetch() {
  const { data } = await api.post<SearchResponse<Report>>('reports/searches', {
    batch_id: [props.batchId],
    page: meta.current_page,
  })
  reports.value = data.data
  Object.assign(meta, data.meta)
}
fetch()

async function removeReport(report: Report) {
  await props.remove([report])
  fetch()
}
</script>

<style scoped lang="scss">
:deep() .v-table__inner {
  padding: 0 0 0 20px;
  margin: 0px;
}

:deep() tr:last-child td {
  border-bottom: 0px;
}
</style>
