<template>
  <span v-if="country" class="country">
    <CountryFlagIcon :value="country['alpha-2']" />
    {{ country.name }}
  </span>
  <slot v-else name="empty">
    <FieldEmpty />
  </slot>
</template>

<script setup lang="ts">
import CountryFlagIcon from './CountryFlagIcon.vue'
import FieldEmpty from './fields/FieldEmpty.vue'

interface Props {
  value?: null | string
}

const props = withDefaults(defineProps<Props>(), {
  value: '',
})

const country = computed(() => {
  if (!props.value) {
    return null
  }

  if (props.value in stores.countries.countriesByAlpha3Code) {
    return stores.countries.countriesByAlpha3Code[props.value.toLowerCase()]
  }

  return stores.countries.country(props.value)
})
</script>

<style lang="scss" scoped>
.country:not(:last-child) {
  padding-right: 5px;
  &:after {
    content: ', ';
  }
}
</style>
