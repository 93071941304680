import { acceptHMRUpdate, defineStore } from 'pinia'

import type { PaymentMethod, PaymentMethodCreditcard, PaymentMethodDirectdebit, PaymentMethodName } from '~/types.ts'

export interface StorePaymentMethodRequest {
  method: PaymentMethodName
  consumerName?: string
  consumerAccount?: string
  organization_name?: string
}

export const defaultPaymentMethods = () => ({
  directdebit: {
    status: '',
    method: 'directdebit',
    created_at: '',
    payment: {},
    details: {},
  } as PaymentMethodDirectdebit,
  creditcard: {
    status: '',
    method: 'creditcard',
    created_at: '',
    payment: {},
    details: {},
  } as PaymentMethodCreditcard,
})

export const usePaymentMethods = defineStore('paymentMethods', {
  state: () => ({
    paymentMethods: defaultPaymentMethods(),
    organizationId: 0,
  }),

  getters: {
    hasValidPaymentMethod: (state) => Object.values(state.paymentMethods).some((method) => method.status === 'valid'),
  },

  actions: {
    async fetch(organizationId: number) {
      this.organizationId = organizationId
      this.paymentMethods = defaultPaymentMethods()

      const { data } = await api.get(`payment-methods/${organizationId}`, {
        headers: {
          OrganizationId: organizationId,
        },
      })
      Object.assign(this.paymentMethods, data)
    },

    async updateMandate(form: StorePaymentMethodRequest) {
      const { data } = await api.post<PaymentMethod>(`payment-methods/${this.organizationId}`, form, {
        headers: {
          OrganizationId: this.organizationId,
        },
      })
      if (data.status === 'valid') {
        stores.organization.invalid_payment_method_at = null
      }
      // @ts-expect-error - method should correspond to the data
      this.paymentMethods[form.method] = data
    },

    async removeMandate(method: PaymentMethodName) {
      await api.delete(`payment-methods/${this.organizationId}/${method}`, {
        headers: {
          OrganizationId: this.organizationId,
        },
      })

      // @ts-expect-error - method should correspond to the data
      this.paymentMethods[method] = defaultPaymentMethods()[method]
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePaymentMethods, import.meta.hot))
}
