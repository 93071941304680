import { acceptHMRUpdate, defineStore } from 'pinia'

import { type Role } from '~/types.ts'

export const APP_ROLES = new Set(['Owner', 'Admin', 'Billing manager', 'Team manager'])

export const useRoles = defineStore('roles', {
  state: () => ({
    roles: {} as Record<number, Role>,
    APP_ROLES,
  }),

  getters: {
    appRoles: (state) => Object.values(state.roles).filter((role) => APP_ROLES.has(role.name)),
    administrationRoles: (state) => Object.values(state.roles).filter((role) => !APP_ROLES.has(role.name)),
  },

  init: async (store) => {
    const { data } = await api.get('roles')
    store.roles = keyedBy('id', data)
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRoles, import.meta.hot))
}
