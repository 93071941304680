<template>
  <MatchTag v-if="hasMatched" class="match-tag" :value="value" />
  <template v-if="type === 'location'">
    <CountryLink v-if="$stores.countries.countryName(value)" :highlight-target="highlightTarget" :text="value" />
    <VTextHighlight v-else :queries="highlightTarget" :text="value" />
  </template>
  <SearchLink v-else :form="form" :highlight-target="highlightTarget" :searched-name="searchedName" :text="value" :type="type" />
</template>

<script setup lang="ts">
import { type CaseForm, type CaseType, type HighlightTarget } from '~/types.ts'

interface Props {
  value: string
  type?: CaseType | 'location' | ''
  highlightTarget?: HighlightTarget
  form?: Partial<CaseForm>
  searchedName?: string
  elasticHighlights?: Record<string, any>
}

const props = withDefaults(defineProps<Props>(), {
  type: undefined,
  highlightTarget: '',
  form: undefined,
  searchedName: '',
  elasticHighlights: () => ({}),
})

const hasMatched = computed(() => 'name' in props.elasticHighlights)
</script>
