<template>
  <VTag class="match-tag" @contextmenu.prevent="openTermMenu">
    {{ $t('match') }}
    <ContextMenu v-if="renderTermMenu && false" ref="termMenu">
      <ul>
        <li @click="addToExcludedTerms()">{{ $t('add-to-excluded-terms') }}</li>
      </ul>
    </ContextMenu>
  </VTag>
</template>

<script lang="ts">
import { $caseStoreKey } from '~/injections.ts'

export default defineComponent({
  props: {
    value: {
      required: true,
      type: String,
    },
  },

  setup() {
    const { caseStore, sourceStores } = injectRequired($caseStoreKey)

    return {
      caseStore,
      sourceStores,
    }
  },

  data: () => ({
    renderTermMenu: false,
  }),

  computed: {
    isAlreadyExcludedTerm() {
      return this.caseStore.case?.excluded_terms.includes(this.value.toLowerCase())
    },

    isInputTerm() {
      const inputNames = [this.caseStore.case?.name ?? '', ...(this.caseStore.case?.aliases ?? [])].map((value) => value.toLowerCase())

      return inputNames.includes(this.value.toLowerCase())
    },
  },

  methods: {
    async openTermMenu(event: MouseEvent) {
      if (this.isAlreadyExcludedTerm || this.isInputTerm) {
        return
      }
      this.renderTermMenu = true
      await this.$nextTick()
      this.$refs.termMenu.open(event, event)
    },

    addToExcludedTerms() {
      stores.cases.update({ id: this.caseStore.case?.id, excluded_terms: [...(this.caseStore.case?.excluded_terms ?? []), this.value.toLowerCase()] })
    },
  },
})
</script>

<style lang="scss" scoped>
.match-tag {
  margin-right: 8px;
  margin-bottom: 3px;
  color: var(--color-text-primary);
  border-color: var(--color-text-primary);
}
</style>
