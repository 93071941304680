<template>
  <VRow v-if="isActivated" :gutter="10" style="margin-bottom: 10px">
    <VCol :md="8">
      <div style="height: 30px">
        <div class="title-20" style="margin-left: 10px">{{ $t('industries') }}</div>
        <div style="display: flex; justify-content: center; margin-top: -5px">
          <VButton
            v-if="sourceFilters?.news.industries"
            icon="Close"
            :label="$tk(sourceFilters?.news.industries)"
            plain
            style="position: absolute; z-index: 2"
            type="primary"
            @click="$emit('update:sourceFilters', { news: { industries: null } })"
          />
        </div>
      </div>
      <div v-if="industryChartData?.series?.[0].data.length === 0" class="title-20" style="position: absolute; padding-left: 10px; color: var(--color-grey-40)">
        {{ $t('not-available') }}
      </div>
      <ChartDonut
        v-if="industryChartData?.series"
        :chart-data="industryChartData"
        :other-color="industryChartData.nonIdentified ? '#d0dfee' : ''"
        style="height: 280px"
        @data-click="filterFromChartData('industries', $event)"
      />
    </VCol>
    <VCol :md="8" style="border-left: 1px solid #e0e0e0; border-right: 1px solid #e0e0e0">
      <div style="height: 30px">
        <div class="title-20" style="margin-left: 10px">{{ $t('adverse-events') }}</div>
        <div style="display: flex; justify-content: center; margin-top: -5px">
          <VButton
            v-if="sourceFilters?.news.adverse"
            icon="Close"
            :label="$t($stores.initialState.adverse.labels[sourceFilters?.news.adverse] ?? kebab(sourceFilters?.news.adverse))"
            plain
            style="position: absolute; z-index: 2"
            type="primary"
            @click="$emit('update:sourceFilters', { news: { adverse: null } })"
          />
        </div>
      </div>
      <div v-if="adverseChartData?.series?.[0].data.length === 0" class="title-20" style="position: absolute; padding-left: 10px; color: var(--color-grey-40)">
        {{ $t('not-available') }}
      </div>
      <ChartDonut
        v-if="adverseChartData?.series"
        :chart-data="adverseChartData"
        color="#B11B1B"
        :other-color="adverseChartData.nonIdentified ? '#d0dfee' : ''"
        style="height: 280px"
        @data-click="filterFromChartData('adverse', $event)"
      />
    </VCol>
    <VCol :md="8">
      <div style="height: 30px">
        <div class="title-20" style="margin-left: 10px">{{ $t('year-of-publication') }}</div>
        <div style="display: flex; justify-content: center; margin-top: -5px">
          <VButton
            v-if="sourceFilters?.news.year"
            icon="Close"
            :label="sourceFilters?.news.year"
            plain
            style="position: absolute; z-index: 2"
            type="primary"
            @click="$emit('update:sourceFilters', { news: { year: null } })"
          />
        </div>
      </div>
      <div v-if="yearChartData?.series?.[0].data.length === 0" class="title-20" style="position: absolute; padding-left: 10px; color: var(--color-grey-40)">
        {{ $t('not-available') }}
      </div>
      <ChartBar
        v-if="yearChartData?.series"
        :chart-data="yearChartData"
        :chart-options="{ xAxis: { minInterval: 1 } }"
        color="#10069F"
        style="height: 280px"
        @data-click="filterFromChartData('year', $event)"
      />
    </VCol>
  </VRow>
</template>

<script setup lang="ts">
import type { SourceFilters } from '~/composables/source.ts'
import { kebab } from '~/helpers/textFormatting.ts'
import { $caseStoreKey } from '~/injections.ts'
import { type CaseHit, type HitUpdate } from '~/types.ts'

interface Props {
  sourceFilters: SourceFilters | undefined // Not supposed to be able to be undefined.
  hits: CaseHit[]
  filteredNames: string[]
  filteredHits: HitUpdate[]
  filteredTableData: HitUpdate[]
}

const props = defineProps<Props>()
const s = inject($caseStoreKey)

if (!s) {
  throw Error('Missing case store injection')
}

const caseStore = s.caseStore
const { isActivated } = useIsActivated()

const emit = defineEmits<{
  'update:sourceFilters': [value: object]
}>()

const deduplicatedTableData = computed(() => {
  return props.filteredTableData.filter(({ id }) => !caseStore.news.similarHitIds.has(id))
})

const industryChartData = computed(() => {
  const items: Record<string, { name: string; value: number }> = {}
  let nonIdentified = 0
  for (const hit of deduplicatedTableData.value) {
    if (hit.industries) {
      for (const key of hit.industries) {
        if (key !== 'non_identified') {
          items[key] ??= { name: $t(kebab(key)), value: 0 }
          items[key].value++
        } else {
          nonIdentified++
        }
      }
    }
  }
  let dataPoints = []

  for (const [id, { name, value }] of Object.entries(items)) {
    dataPoints.push({ id, name, value })
  }
  dataPoints.sort((first, second) => second.value - first.value)

  if (nonIdentified) {
    dataPoints.push({ id: 'non_identified', name: $t('none-identified'), value: nonIdentified })
  }

  const sourceFilters = props.sourceFilters
  if (sourceFilters && sourceFilters.news.industries) {
    dataPoints = dataPoints.filter((dataPoint) => dataPoint.id === sourceFilters.news.industries)
  }

  return {
    series: [{ data: dataPoints, type: 'pie', label: { show: false }, radius: ['50%', '70%'] }],
    categories: dataPoints.map((dataPoint) => dataPoint.name),
    nonIdentified,
  }
})

const adverseChartData = computed(() => {
  const items: Record<string, { name: string; value: number }> = {}
  let nonIdentified = 0
  const minimumThreshold = stores.initialState.adverse.minimum_threshold
  const labels = stores.initialState.adverse.labels

  for (const hit of deduplicatedTableData.value) {
    if (!hit.adverse) {
      continue
    }

    if ('nonadverse' in hit.adverse) {
      nonIdentified++
      continue
    }

    for (const key in hit.adverse) {
      if (key !== 'narcotics' && hit.adverse[key] >= minimumThreshold) {
        items[key] ??= {
          name: $t(labels[key] ?? kebab(key)),
          value: 0,
        }
        items[key].value++
      }
    }
  }
  const dataPoints = []

  for (const [id, { name, value }] of Object.entries(items)) {
    dataPoints.push({ id, name, value })
  }
  dataPoints.sort((first, second) => second.value - first.value)
  if (nonIdentified) {
    dataPoints.push({ id: 'nonadverse', name: $t('none-identified'), value: nonIdentified })
  }

  return {
    series: [{ data: dataPoints, type: 'pie', label: { show: false }, radius: ['50%', '70%'] }],
    categories: dataPoints.map((dataPoint) => dataPoint.name),
    nonIdentified,
  }
})

const yearChartData = computed(() => {
  const items: Record<string, number> = {}
  for (const hit of deduplicatedTableData.value) {
    if (hit.publish_date) {
      const year = hit.publish_date.substring(0, 4)
      items[year] ??= 0
      items[year]++
    } else {
      items.Missing = items.Missing ? items.Missing + 1 : 1
    }
  }
  const dataPoints = []
  for (const [name, value] of Object.entries(items)) {
    dataPoints.push({ id: name, name, value })
  }
  dataPoints.sort((first, second) => first.name.localeCompare(second.name))

  return {
    series: [{ data: dataPoints, type: 'bar', label: { show: false } }],
    categories: dataPoints.map((dataPoint) => dataPoint.name),
  }
})

function filterFromChartData(chartName: 'adverse' | 'industries' | 'year', event: { data: { id: string } }) {
  if (props.sourceFilters?.news[chartName] === event.data.id) {
    emit('update:sourceFilters', { news: { [chartName]: null } })

    return
  }
  emit('update:sourceFilters', { news: { [chartName]: event.data.id } })
  stores.apmEvents.track({ event: 'filter-news-from-chart', category: 'resolving' })
}
</script>

<style scoped>
.filter-card {
  margin-bottom: 15px;
}
.filter-card :deep() .v-card__body {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
