<template>
  <ActionBar classes="action-bar--resolve" :visible="visible" @update:visible="$emit('update:selection', [])">
    <HitResolve extended :rows="selection" />
    <ButtonResolve v-if="hasUpdated" type="update" @click="$update({ entries: 'selected' })" />
  </ActionBar>
</template>

<script setup lang="ts">
import { type CaseHitTransformed } from '~/types.ts'

const $update = inject('$update') as (args: any) => Promise<void>

interface Props {
  selection: CaseHitTransformed['data'][]
}

const props = defineProps<Props>()

defineEmits<{
  'update:selection': [value: CaseHitTransformed['data'][]]
}>()

const { isActivated } = useIsActivated()

const visible = computed(() => {
  return isActivated.value && props.selection.length > 0
})

const hasUpdated = computed(() => {
  return props.selection.filter((item) => item.case_hit?.update).length > 0
})
</script>

<style lang="scss">
.action-bar--resolve {
  display: flex;
  align-items: center;

  .hit-resolve {
    margin: 0;

    flex-grow: 1;

    + .v-btn {
      margin-left: 15px;
    }
  }
}
</style>
