<template>
  <VDialog v-model:visible="visible" hide-close-icon hide-wrapper placement="bottom">
    <slot />

    <div v-if="$slots.right" style="float: right">
      <slot name="right" />
    </div>
  </VDialog>
</template>

<script setup lang="ts">
const visible = defineModel<boolean>('visible', { default: true })

watch(
  visible,
  (value) => {
    if (value) {
      document.body.classList.add('has-action-bar')
    } else {
      document.body.classList.remove('has-action-bar')
    }
  },
  { immediate: true },
)
</script>

<style lang="scss" scoped>
.v-dialog-wrapper :deep() {
  .v-dialog {
    padding: 30px 90px;
    border: var(--border);
    box-shadow: var(--box-shadow-light);
    text-align: center;
  }

  .v-dialog__body {
    padding: 0;
  }
}
</style>
