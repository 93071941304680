import { type LngLatLike, type Map } from 'mapbox-gl'

interface Params {
  id?: string
  size?: number
  duration?: number
  map: Map
  coordinates: LngLatLike
  featureProperties?: object
}

const createPulsingDot = ({ id = 'pulsing-dot', size = 100, duration = 2000, map, coordinates, featureProperties = {} }: Params) => {
  const layer = map.getLayer(id)

  if (!layer) {
    const data = createLayer({ id, size, duration, map, coordinates, featureProperties })

    return data.source.data
  }

  return addFeature({ id, map, coordinates, properties: featureProperties })
}

const createLayer = ({ id, size, duration, map, coordinates, featureProperties }: Required<Params>) => {
  const dot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),
    context: null as null | CanvasRenderingContext2D,

    // get rendering context for the map canvas when layer is added to the map
    onAdd() {
      const canvas = document.createElement('canvas')
      canvas.width = this.width
      canvas.height = this.height
      this.context = canvas.getContext('2d')
    },

    // called once before every frame where the icon will be used
    render() {
      const t = (performance.now() % duration) / duration

      const radius = (size / 2) * 0.3
      const outerRadius = (size / 2) * 0.7 * t + radius
      const context = this.context

      if (!context) {
        return
      }

      // draw outer circle
      context.clearRect(0, 0, this.width, this.height)
      context.beginPath()
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2)
      context.fillStyle = `rgba(16, 7, 159, ${1 - t})`
      context.fill()

      // draw inner circle
      context.beginPath()
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2)
      context.fillStyle = '#0017a8'
      context.strokeStyle = 'white'
      context.lineWidth = 1 + 4 * (1 - t)
      context.fill()
      context.stroke()

      // update this image's data with data from the canvas
      this.data = context.getImageData(0, 0, this.width, this.height).data

      // continuously repaint the map, resulting in the smooth animation of the dot
      map.triggerRepaint()

      // return `true` to let the map know that the image was updated
      return true
    },
  }

  map.addImage(id, dot, { pixelRatio: 2 })

  const layer = {
    id,
    type: 'symbol',
    layout: { 'icon-image': id },
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [{ type: 'Feature', geometry: { type: 'Point', coordinates }, properties: featureProperties }],
      },
    },
  }

  map.addLayer(layer)

  return layer
}

const addFeature = ({ id, map, coordinates, properties }) => {
  const source = map.getSource(id)

  source._data.features.push({ type: 'Feature', geometry: { type: 'Point', coordinates }, properties })
  source.setData(source._data)

  return source._data
}

export { createPulsingDot }
