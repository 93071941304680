<template>
  <VTimeline :data="visiblePositions">
    <template #default="{ item }">
      <div class="date" style="padding-bottom: 5px">
        <template v-if="item.listed_at || item.listed_until">{{ formatDate(item.listed_at, $t('unknown')) }} - {{ formatDate(item.listed_until, $t('unknown')) }}</template>
        <template v-else>{{ $t('unknown-date') }}</template>
      </div>

      <VCard>
        <VTag v-if="item.inactive" class="tag-inactive">{{ $t('inactive') }}</VTag>
        <div>{{ $t('position') }}: <FieldPosition :position="item.position" /></div>
        <div v-if="item.status">{{ $t('status') }}: {{ item.status }}</div>
        <FieldURL v-if="item.url" :value="item.url" />
      </VCard>
    </template>
  </VTimeline>

  <VButton v-if="sortedPositions.length > 3" :label="showMore ? $t('show-less') : $t('show-more')" type="link" @click="showMore = !showMore" />
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type HitDataPosition } from '~/types.ts'

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<HitDataPosition[]>,
      required: true,
    },
  },

  data: () => ({
    showMore: false,
  }),

  computed: {
    sortedPositions() {
      return sortArrayByObjectKey({ array: this.value, key: 'listed_at', order: 'desc', transform: (value) => (value === null ? '9999-01-01' : value) })
    },

    visiblePositions() {
      return this.showMore ? this.sortedPositions : this.sortedPositions.slice(0, 3)
    },
  },

  methods: { capitalize, formatDate },
})
</script>

<style lang="scss" scoped>
.v-card :deep() {
  margin-bottom: 0;
  margin-right: 5px;

  .v-card__body {
    padding: 20px;
  }
}
.date {
  margin-top: 10px;
  font-size: 14px;
  color: var(--color-text-secondary);
}

.tag-inactive {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
