<template>
  <div class="field-max-height" style="overflow-x: hidden; margin: -11px 0">
    <div v-for="(document, key) in documents" :key="key" class="hit-field">
      <span v-if="'type' in document">{{ document.type }}: </span>
      <a v-if="'url' in document && 'title' in document" :href="document.url" target="_blank">{{ document.title }}</a>
      <a v-else-if="'url' in document" :href="document.url" target="_blank">{{ document.url }}</a>
      <template v-else-if="'type' in document">{{ formatKeyValue(document.type, document.title) }}</template>
      <template v-else>{{ document.title }}</template>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    documents: {
      required: true,
      type: Array,
    },
  },

  methods: {
    formatKeyValue,
  },
})
</script>
