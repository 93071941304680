<template>
  <div>
    <VTable class="explanation-table" :data="tableData" style="border-bottom: 2px">
      <VTableColumn :label="$t('field')" prop="field" />
      <VTableColumn :label="$t('description')" prop="description" wrap-text />
      <VTableColumn :label="$t('risk-score')" prop="score" width="180px" />
    </VTable>
    <VRow :gutter="2">
      <VCol :md="8" style="padding-left: 13px; line-height: 26px">
        {{ $t('overall-risk') }}
        <VPopover style="display: inline" tag="span" trigger="hover">
          {{ $t('the-overall-risk-is-calculated-based-on-the-category-risk-and-the-variables-that-are-associated-to-the-source') }} <br />
          {{ $t('the-scores-of-the-risk-are-defined-through-the-impact-and-probability-assigned-to-the-allocated-source-a-higher-score-results-in-a-higher-risk') }} <br />
          {{ $t('the-individual-risk-score-of-a-field-is-equal-to-the-magnitude-of-the-field-against-the-score-whereas-a-higher-score-means-that-the-field-has-more-impact') }}

          <template #reference>
            <VIcon icon="QuestionCircle" :size="11" />
          </template>
        </VPopover>
      </VCol>
      <div class="risk-row">
        <PopoverRiskMatrix :risk="risk" simplified style="width: 180px">
          <FieldRisk auto-width :risk="risk.classification" rounded :score="risk.score" />
        </PopoverRiskMatrix>
      </div>
    </VRow>
    <VRow v-if="userRiskScore" :gutter="2">
      <div class="risk-row">
        <VRow style="line-height: 26px">{{ $t('overruled-risk') }}</VRow>
        <VRow>
          <div style="width: 180px">
            <FieldRisk :risk="getHighestRisk(userRiskScore)" rounded />
          </div>
        </VRow>
      </div>
    </VRow>
  </div>
</template>

<script lang="ts">
import { getHighestRisk } from '~/helpers/risk.ts'

export default defineComponent({
  props: {
    risk: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === undefined,
    },

    userRiskScore: {
      required: true,
      type: Number,
    },

    popup: {
      default: false,
      validator: (prop) => typeof prop === 'boolean' || prop === undefined,
    },

    source: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    typeMap: {
      'very high': 'red',
      high: 'red',
      moderate: 'orange',
      low: 'green',
      'very low': 'green',
      disabled: 'info',
    },
    probabilities: {
      'very low': 0.1,
      low: 0.3,
      moderate: 0.5,
      high: 0.7,
      'very high': 0.9,
    },
    impacts: {
      'very low': 0.05,
      low: 0.1,
      moderate: 0.2,
      high: 0.4,
      'very high': 0.8,
    },
  }),

  computed: {
    tableData() {
      const data = []
      const item = {
        field: this.$t('category-risk'),
        description: this.$t(this.source),
        score: Number((this.probabilities[this.risk.minSetProbability] * this.impacts[this.risk.impact]).toFixed(3)),
      }
      data.push(item)
      const part = (1 - this.probabilities[this.risk.minSetProbability]) / Object.values(this.risk.components).filter((component) => component.classification !== 'disabled').length
      for (const field in this.risk.components) {
        const originalDescription = this.risk.components[field].description
        let description = ''
        if (originalDescription.startsWith('country: ') && originalDescription.endsWith(' not recognized')) {
          const lenCountry = originalDescription.length - 'country: '.length - ' not recognized'.length
          const country = originalDescription.substr('country: '.length, lenCountry)
          description = this.$t('country-country-not-recognized', { country })
        } else if (originalDescription.startsWith('jurisdiction: ') && originalDescription.endsWith(' not recognized')) {
          const lenJurisdiction = originalDescription.length - 'jurisdiction: '.length - ' not recognized'.length
          const jurisdiction = originalDescription.substr('jurisdiction: '.length, lenJurisdiction)
          description = this.$t('jurisdiction-jurisdiction-not-recognized', { jurisdiction })
        } else if (originalDescription.includes(' is recognized as ') && originalDescription.endsWith(' risk')) {
          const lenCountry = originalDescription.indexOf(' is recognized as ')
          const country = originalDescription.substr(0, lenCountry)
          const lenRisk = originalDescription.length - lenCountry - ' is recognized as '.length - ' risk'.length
          const riskStr = originalDescription.substr(lenCountry + ' is recognized as '.length, lenRisk)
          const risk = this.$t(kebab(riskStr)).toLowerCase()
          description = this.$t('country-is-recognized-as-risk-risk', { country, risk })
        } else if (originalDescription.endsWith(' has an unknown risk')) {
          const lenCountry = originalDescription.length - ' has an unknown risk'.length
          const country = originalDescription.substr(0, lenCountry)
          description = this.$t('country-has-an-unknown-risk', { country })
        } else {
          description = this.$t(kebab(originalDescription))
        }
        const item = {
          field: this.$t(kebab(field)),
          description,
          score: this.risk.components[field].score ? Number((part * this.risk.components[field].score * this.impacts[this.risk.impact]).toFixed(3)) : 0,
        }

        data.push(item)
      }

      return data
    },
  },

  methods: {
    getHighestRisk,
  },
})
</script>

<style lang="scss" scoped>
:deep() .explanation-table td + td + td span {
  padding-left: 5px;
}

.risk-row {
  margin-top: 5px;
  margin-right: 10px;
  float: right;
}
</style>
