import { Cache } from '~/services/cache/Cache.ts'

const cache = Cache.tags('validation')

interface ValidateEmailOptions {
  unique?: boolean
}

export async function validateEmail(emails: string | string[], options: ValidateEmailOptions = {}) {
  if (!emails) {
    return false
  }

  if (!Array.isArray(emails)) {
    emails = [emails]
  }

  try {
    await api.post('validate-emails', { emails, ...options })

    return true
  } catch (error) {
    return false
  }
}

export async function validateTaxId(value: string) {
  if (!value) {
    return true
  }

  return await cache.remember(`tax-id:${value}`, dayjs().add(1, 'month'), async () => {
    try {
      await api.post('validate-tax-id', { tax_id: value })

      return true
    } catch {
      return false
    }
  })
}

export async function validateIBAN(value: string) {
  if (!value) {
    return false
  }

  return await cache.remember(`iban:${value}`, dayjs().add(1, 'month'), async () => {
    try {
      await api.post('validate-iban', { iban: value })

      return true
    } catch {
      return false
    }
  })
}
