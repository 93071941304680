<template>
  <VCard class="filter-card">
    <slot name="custom-filter" />

    <VRow :gutter="5">
      <VCol :md="8">
        <div style="font-weight: 500">{{ $t('matched-names') }}</div>
        <FormItem>
          <VButton
            v-for="name in showAllMatchedNames ? matchedNames : matchedNames.slice(0, 5)"
            :key="name"
            :label="name"
            plain
            size="small"
            style="margin-top: 5px"
            :type="filteredNames.includes(name.toLowerCase()) ? 'primary' : 'info'"
            @click="toggleFilteredName(name)"
          />

          <VButton
            v-if="matchedNames.length > 5"
            :icon="showAllMatchedNames ? 'Minus' : 'Plus'"
            :label="showAllMatchedNames ? 'Show less' : 'Show more'"
            plain
            size="small"
            style="margin-top: 5px"
            type="info"
            @click="showAllMatchedNames = !showAllMatchedNames"
          />
        </FormItem>
      </VCol>
      <VCol :md="8">
        <div style="font-weight: 500">{{ $t('found-at') }}</div>
        <FormItem>
          <VDatePicker
            clearable
            is-range
            :model-value="caseStore.filterCreatedAt"
            :start-placeholder="$t('date-of-creation')"
            @update:model-value="updatefilterCreatedAt($event)"
          />
        </FormItem>
      </VCol>
      <VCol :md="8">
        <div v-if="caseStore.activeResolution !== 'unresolved'" style="font-weight: 500; margin-top: 3px; margin-right: 5px">{{ $t('updates') }}</div>
        <VSwitch v-if="caseStore.activeResolution !== 'unresolved'" v-model="caseStore.showOnlyUpdatedHits" style="white-space: nowrap; margin-right: 15px">
          {{ $t('only-show-updated') }}
        </VSwitch>
        <div style="margin-top: 2px; font-weight: 500">{{ $t('search') }}</div>
        <VInput :model-value="caseStore.filterQuery" :placeholder="$t('filter')" style="margin-right: 15px" @update:model-value="updateFilterQuery" />
      </VCol>
    </VRow>
  </VCard>
</template>

<script setup lang="ts">
import { injectRequired } from '~/helpers/injectRequired.ts'
import { $caseStoreKey } from '~/injections.ts'
import { type CaseHit, type CaseSourceName, type HitUpdate } from '~/types.ts'

interface Props {
  matchedNames?: string[] | undefined
  hits: CaseHit[]
  filteredNames: string[]
  filteredHits: HitUpdate[]
  source: CaseSourceName
}

const props = defineProps<Props>()

const emit = defineEmits<{
  'update:filteredNames': [value: string[]]
}>()
const { caseStore } = injectRequired($caseStoreKey)

const showAllMatchedNames = ref(false)

const matchedNames = computed(() => {
  if (props.matchedNames) return props.matchedNames
  const names = props.filteredNames.reduce<Record<string, { name: string; occurrence: number }>>((names, filteredName) => {
    names[filteredName.toLocaleLowerCase()] = { name: filteredName, occurrence: 0 }

    return names
  }, {})

  for (const hit of props.hits.map((hit) => hit.data)) {
    if (hit.highlight) {
      for (const field of Object.values(hit.highlight ?? {})) {
        for (let matches of Object.values(field)) {
          if (typeof matches === 'string') {
            matches = [matches]
          }
          delete matches['addresses.text']
          delete matches.jurisdiction_code
          delete matches['identifiers.value']
          for (const match of Object.values(matches)) {
            const name = match.replaceAll('<em>', '').replaceAll('</em>', '')
            names[name.toLowerCase()] ??= { name, occurrence: 0 }
            names[name.toLowerCase()].occurrence++
          }
        }
      }
    }
  }

  return Object.values(names)
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => a.name.length - b.name.length)
    .sort((a, b) => b.occurrence - a.occurrence)
    .map((name) => name.name)
})

function updateFilterQuery(value: string) {
  caseStore.filterQuery = value
  stores.apmEvents.track({ event: 'filter-search', category: 'resolving' })
}

function updatefilterCreatedAt(value: string[]) {
  caseStore.filterCreatedAt = value
  stores.apmEvents.track({ event: 'filter-search', category: 'resolving' })
}

function toggleFilteredName(name: string) {
  name = name.toLowerCase()
  arrayToggle(props.filteredNames, name)

  emit('update:filteredNames', props.filteredNames)
  stores.apmEvents.track({ event: 'filter-matched-names', category: 'resolving' })
}
</script>

<style scoped>
.filter-card {
  margin-bottom: 15px;
}
.filter-card :deep() .v-card__body {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
