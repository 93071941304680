import { acceptHMRUpdate, defineStore } from 'pinia'

import { type GeoIP, type Meta, type Session } from '~/types.ts'

type Sessions = Record<number, Session>

interface Response {
  data: Sessions
  meta: Meta
}

type Locations = Record<string, GeoIP | null>

export const useSessions = defineStore('sessions', {
  state: () => ({
    sessions: {} as Sessions,
    meta: {} as Meta,
    locations: {} as Locations,
  }),

  actions: {
    async paginate({ page = 1, pageSize = 50 } = {}) {
      try {
        await stores.loading.update({ sessions: true })
        const { data } = await api.post<Response>('sessions', { page, per_page: pageSize })

        this.sessions = data.data
        this.meta = data.meta
      } catch (error) {
        $message.error($t('api-error'))
      } finally {
        await stores.loading.update({ sessions: false })
      }
    },

    async getLocations(sessions: Session[]) {
      const locations: Locations = {}

      for (const session of sessions) {
        if (!(session.ip_address in this.locations) && !(session.ip_address in locations)) {
          try {
            const { data }: { data: GeoIP } = await api.get(`geoip/${session.ip_address}`)
            locations[session.ip_address] = data
          } catch (error) {
            locations[session.ip_address] = null
          }
        }
      }

      if (Object.keys(locations).length > 0) {
        this.locations = { ...this.locations, ...locations }
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSessions, import.meta.hot))
}
