<template>
  <a @click.stop="onClick">
    <VTextHighlight :queries="highlightTarget" :text="text" />
  </a>
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { $caseStoreKey } from '~/injections.ts'
import { type HighlightTarget } from '~/types.ts'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },

    searchValue: {
      type: String as PropType<undefined | string>,
      default: undefined,
    },

    highlightTarget: {
      type: [Array, String] as PropType<HighlightTarget>,
      default: '',
    },
  },

  setup() {
    const injection = inject($caseStoreKey, undefined)

    return {
      caseStore: injection?.caseStore,
    }
  },

  methods: {
    onClick() {
      $bus.emit('dialog.show', 'countryInformation')

      const caseModel = this.caseStore?.case
      const country = stores.countries.countryName(this.searchValue?.trim() ?? this.text.trim())

      if (country) {
        $bus.emit('country.show', { country, case_id: caseModel?.id })
      }
    },
  },
})
</script>
