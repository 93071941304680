import download from 'downloadjs'

import { type Report } from '~/types.ts'

export async function downloadAsPDF(report: Report | string | number) {
  if (typeof report === 'string' || typeof report === 'number') {
    try {
      const { data } = await api.get<Report>(`reports/${report}`)
      report = data
    } catch (error) {
      $message.error($t('api-error'))

      return
    }
  }

  try {
    const { data } = await api({
      url: `/v1/reports/${report.id}/download`,
      method: 'GET',
      responseType: 'blob',
    })

    download(data, `${report.name}.pdf`, 'application/pdf')
    $bus.emit('reportDownloaded', report)
  } catch (error) {
    $message.error($t('api-error'))
  }
}

export function downloadAsFile(filters: Record<string, any>, action: string) {
  const form = document.createElement('form')
  form.action = action
  form.method = 'POST'

  const csrf = document.createElement('input')
  csrf.name = '_token'
  csrf.type = 'hidden'
  csrf.value = (document.getElementsByName('csrf-token')[0] as HTMLMetaElement).content
  form.appendChild(csrf)

  const organizationId = document.createElement('input')
  organizationId.name = '_organizationId'
  organizationId.type = 'hidden'
  organizationId.value = String(stores.organization.id)
  form.appendChild(organizationId)

  for (const key in filters) {
    const value = unref(filters[key])
    if (Array.isArray(value)) {
      for (const item of value) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = `${key}[]`
        if (typeof item === 'boolean') {
          input.value = item ? '1' : '0'
        } else if (!item) {
          input.value = ''
        } else {
          input.value = String(item)
        }
        form.appendChild(input)
      }
    } else {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      if (typeof value === 'boolean') {
        input.value = value ? '1' : '0'
      } else if (!value) {
        input.value = ''
      } else {
        input.value = String(value)
      }
      form.appendChild(input)
    }
  }

  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}
