<template>
  <div :class="{ 'form-item': true, 'form-item--horizontal': horizontal, error: errors.length > 0, 'has-label-width': labelWidth !== null }">
    <div v-if="label !== ''" :class="{ 'form-label': true, required: required, 'form-label--black': black }" :style="{ width: px(labelWidth) }">
      <label :for="labelFor" v-text="label" />
      <IconHelp v-if="help.length > 0" style="vertical-align: middle; margin-left: 3px" :text="help" />
    </div>

    <slot />
    <div v-for="message in errors" :key="message" class="form-error" v-text="message" />
  </div>
</template>

<script lang="ts">
import { type PropType } from 'vue'

export default defineComponent({
  props: {
    required: Boolean,
    horizontal: Boolean,
    black: Boolean,

    label: {
      type: String,
      default: '',
    },

    labelWidth: {
      type: [Number, String],
      default: null,
    },

    error: {
      type: [String, Array],
      default: '',
    },

    help: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  data: () => ({
    labelFor: '',
  }),

  computed: {
    errors() {
      if (Array.isArray(this.error)) {
        return this.error
      }

      if (this.error && this.error.length > 0) {
        return [this.error]
      }

      return []
    },
  },

  mounted() {
    this.labelFor = this.$slots.default ? this.$slots.default()[0]?.elm?.getElementsByTagName('input')[0]?.id : null
  },

  methods: {
    px,
  },
})
</script>

<style lang="scss">
.form-item {
  &.error input {
    border-color: var(--color-red);
  }

  &.success input {
    border-color: var(--color-green);
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.form-item--horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(.has-label-width) {
    width: 100%;
  }

  .form-label {
    padding-bottom: 0;
  }
}

.form-label {
  line-height: 14px;
  padding-bottom: 4px;
  margin-top: 20px;
  font-size: 1em;

  &--black {
    color: #000;
    font-weight: 500;
  }

  &.required:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }

  &:first-child {
    margin-top: 0px;
  }
}

.form-error {
  color: #f56c6c;
  font-size: 0.85em;
  line-height: 1;
  padding-top: 4px;
  position: relative;
}
</style>
