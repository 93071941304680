<template>
  <VCard class="table-card" :title="$t('reports')">
    <template #header-right>
      <VButton size="small" type="link" @click="editing = !editing">{{ $t('edit') }}</VButton>
      <FilterButton v-model="filtersVisible" />
    </template>

    <VTransitionCollapse :visible="filtersVisible">
      <ReportsFilters :filters="filters" :types="types" @change="updateFilter" @set="setFilters" />
    </VTransitionCollapse>

    <VTable
      ref="table"
      v-model:checked-rows="selection"
      :check-on-dragover="editing"
      :check-on-row-click="editing"
      :checkable="editing"
      :data="reports"
      :default-sort="{ prop: 'created_at', order: 'desc' }"
      :expand-colspan-before="1"
      expandable
      :loading="loading"
      :row-is-expandable="(row) => row.count > 1"
      row-key="key"
      style="min-height: 500px"
      @check="allSelected = false"
      @sort="sortTable"
    >
      <template #empty>
        <FieldEmpty />
      </template>

      <VTableColumn v-slot="{ row }: { row: ReportAggregate }" :label="$t('name')" max-width="250" prop="group_name" show-overflow-tooltip sortable>
        {{ row.group_name }}
      </VTableColumn>

      <VTableColumn v-slot="{ row }: { row: ReportAggregate }" :label="$t('type')" prop="type" sortable width="200">
        <template v-if="row.count === 1">{{ types[row.type] }}</template>
      </VTableColumn>

      <VTableColumn v-slot="{ row }" :label="$t('requested-at')" prop="created_at" sortable width="120">
        <FieldDate past relative :value="row.created_at" />
      </VTableColumn>

      <VTableColumn v-slot="{ row }" :label="$t('expires-in')" width="120">
        {{ $dayjs(row.created_at).add(90, 'days').fromNow() }}
      </VTableColumn>

      <VTableColumn v-slot="{ row }: { row: ReportAggregate }" cell-class="actions-cell" :label="$t('actions')" width="120">
        <VButton class="delete-icon-button" icon="Delete" :icon-size="16" size="small" type="icon" @click.stop="remove([row])" />
        <VButton icon="Download" :icon-size="16" size="small" type="icon" @click.stop="downloadReport(row)" />
        <VButton v-if="row.count === 1" icon="Mail" :icon-size="16" size="small" type="icon" @click.stop="mail(row)" />
      </VTableColumn>

      <template #expand="{ row }">
        <ReportsExpandedRow :batch-id="row.key" :remove="remove" @download="downloadReport" @mail="mail" />
      </template>
    </VTable>

    <VPagination
      v-model="page"
      v-model:page-size="pageSize"
      :page-count="meta.last_page"
      @update:model-value="updateFilter({ key: 'page', value: $event })"
      @update:page-size="updateFilter({ key: 'per_page', value: $event })"
    />

    <ActionBar v-model:visible="editing">
      <i18n-t v-if="!allSelected && meta.total > selection.length" keypath="reports-selected-count-or-select-all" style="margin-right: 7px" tag="span">
        <template #count>{{ selection.length }}</template>
        <template #selectall>
          <VButton :label="$t('select-all')" type="link" @click="selectAll()" />
        </template>
      </i18n-t>
      <span v-else style="margin-right: 7px">{{ $t('all-count-rows-selected', meta.total) }}</span>

      <VButton :disabled="noneSelectedDisabled.disabled" :help="noneSelectedDisabled.help" :label="$t('delete')" type="red" @click="remove(allSelected ? [] : selection)" />
      <VButton :disabled="noneSelectedDisabled.disabled" :help="noneSelectedDisabled.help" :label="$t('download')" @click="downloadBatch" />
      <VButton :label="$t('cancel')" type="secondary" @click="editing = false" />
    </ActionBar>
  </VCard>
</template>

<script setup lang="ts">
import debounce from 'lodash-es/debounce.js'

import router, { route } from '~/router.ts'
import { type Report, type ReportType, type SortEvent } from '~/types.ts'

interface ReportAggregate {
  key: string
  group_name: string
  name: string
  type: ReportType
  created_at: string
  count: number
}

const selection = ref<ReportAggregate[]>([])
const filtersVisible = ref(false)
const editing = ref(false)
const loading = ref(false)
const filters = reactive<Record<string, any>>({})
const reports = ref<ReportAggregate[]>([])
const meta = ref(createMeta())
const page = ref(1)
const pageSize = ref(10)
const types = ref(reportTypes())
const csrf = ref('')
const allSelected = ref(false)
const noneSelectedDisabled = computed(() => new DisabledHelp($t('you-have-not-selected-any-cases'), !allSelected.value && selection.value.length === 0))

onMounted(() => {
  checkQueryParams()
  csrf.value = (document.getElementsByName('csrf-token')[0] as HTMLMetaElement).content
})

onActivated(() => {
  if (Object.keys(filters).length > 0) {
    fetch()
  }
})

onDeactivated(() => (editing.value = false))

function selectAll() {
  selection.value = []
  allSelected.value = true
}

function checkQueryParams() {
  const queryParamIds: string[] = []

  for (const [key, value] of Object.entries(route.value.query)) {
    if (key.startsWith('download_batch_id')) {
      queryParamIds.push(String(value))
    }
  }

  if (queryParamIds.length > 0) {
    downloadZip({ batch_id: queryParamIds })
    router.replace('/statistics/reports')
  }
}

function setFilters(newFilters: Record<string, any>) {
  Object.assign(filters, newFilters)
  fetch()
}

async function updateFilter({ key, value, shouldFetch = true }: { key: string; value: any; shouldFetch?: boolean }) {
  filters[key] = value

  if (shouldFetch) {
    await fetch()
  }
}

const fetch = debounce(
  async function () {
    loading.value = true

    try {
      const { data } = await api.post('reports/aggregate', {
        ...filters,
        user_id: [stores.user.user.id],
        organization_id: [stores.organization.id],
      })

      reports.value = data.data
      meta.value = data.meta
    } catch (error) {
      $message.error($t('api-error'))
    }

    loading.value = false
  },
  300,
  { leading: true, trailing: true },
)

async function sortTable({ prop, order }: SortEvent) {
  await updateFilter({ key: 'sort_by', value: prop, shouldFetch: false })
  await updateFilter({ key: 'sort_order', value: order, shouldFetch: true })
}

async function remove(reports: ReportAggregate[] | Report[]) {
  if (loading.value) return

  await $confirm({
    title: $t('delete-report-or-delete-reports', reports.length),
    message: $t(
      'if-you-proceed-with-this-action-the-selected-reports-report-will-be-permanently-deleted-or-if-you-proceed-with-this-action-the-selected-reports-reports-will-be-permanently-deleted',
      reports.length,
    ),
  })

  loading.value = true

  let ids: (string | number)[] = []
  let batchIds: string[] = []

  if ('key' in reports[0]) {
    const result = getIdsAndBatchIds(reports as ReportAggregate[])
    ids = result.ids
    batchIds = result.batchIds
  } else {
    ids = reports.map((report) => (report as Report).id)
  }

  try {
    await api.delete('reports', {
      data: {
        ...filters,
        ids,
        batch_id: batchIds,
      },
    })
    await fetch()

    const filteredSelection: ReportAggregate[] = []
    for (const row of selection.value) {
      if (!ids.includes(row.key) && !batchIds.includes(row.key)) {
        filteredSelection.push(row)
      }
    }
    selection.value = filteredSelection
  } catch {
    $message.error($t('api-error'))
  } finally {
    loading.value = false
  }
}

async function downloadReport(report: ReportAggregate | Report) {
  if (loading.value) return
  loading.value = true

  if ('id' in report) {
    await downloadAsPDF(report)
  } else if (report.count > 1) {
    await downloadZip({ batch_id: [report.key] })
  } else {
    await downloadAsPDF(report.key)
  }

  loading.value = false
}

async function mail(report: ReportAggregate | Report) {
  if (loading.value) return
  loading.value = true

  let id = ''
  if ('key' in report) {
    id = report.key
  } else {
    id = String(report.id)
  }

  try {
    await api.get(`reports/${id}/email`)
    $message.success($t('report-has-been-successfully-mailed'))
  } catch (error) {
    $message.error($t('api-error'))
  }
  loading.value = false
}

function getIdsAndBatchIds(reports: ReportAggregate[]) {
  const ids = []
  const batchIds = []

  for (const item of reports) {
    if (isNaN(Number(item.key))) {
      batchIds.push(item.key)
    } else {
      ids.push(item.key)
    }
  }

  return { ids, batchIds }
}

async function downloadBatch() {
  await downloadZip(getIdsAndBatchIds(selection.value))
}

async function downloadZip(extraFilters: Record<string, any>) {
  loading.value = true
  await nextTick()
  downloadAsFile(
    {
      ...filters,
      ...extraFilters,
      user_id: [stores.user.user.id],
      organization_id: [], // Email downloads can be from multiple organizations
    },
    '/api/reports/zip',
  )

  loading.value = false
}
</script>

<script lang="ts">
export const reportTypes = (): Record<ReportType, string> => ({
  case: $t('case-report'),
  client: $t('client-report'),
  'organization-audit': $t('organization-audit-report'),
  'user-sessions': $t('user-sessions'),
})
</script>

<style lang="scss" scoped>
.v-table :deep() .actions-cell {
  display: flex;
  align-items: center;
}
</style>
