<template>
  <VTable :data="roles" :default-sort="{ prop: 'pep_role_term_end_date', order: 'desc' }" :pagination="{}" style="margin-bottom: -16px">
    <VTableColumn :label="$t('position')" prop="pep_position" />
    <VTableColumn :label="$t('role')" min-width="200" prop="pep_role_bio" wrap-text />

    <VTableColumn v-slot="{ row }" :label="$t('term')" prop="pep_role_term_end_date" width="300">
      <template v-if="row.pep_role_term_start_date || row.pep_role_term_end_date">
        {{ formatDate(row.pep_role_term_start_date, $t('unknown')) }} - {{ formatDate(row.pep_role_term_end_date, $t('unknown')) }}
      </template>
    </VTableColumn>

    <VTableColumn :label="$t('status')" prop="pep_role_status" width="90" />
    <VTableColumn :label="$t('level')" prop="pep_role_level" width="70" />
  </VTable>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    roles: {
      required: true,
      type: Array,
    },
  },

  methods: {
    formatDate,
  },
})
</script>
