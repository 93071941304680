<template>
  <VCollapse style="margin: -11px 0">
    <VCollapseItem v-for="item in items" :key="item.type" :title="item.type">
      <VTextHighlight :queries="highlightTarget" :text="item.value" />
    </VCollapseItem>
  </VCollapse>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    items: {
      required: true,
      type: Array,
    },

    highlightTarget: {
      type: [Array, String],
      default: '',
    },
  },
})
</script>
