<template>
  <img v-if="customFlag" class="custom-flag" :src="customFlag" />
  <span v-else-if="flag" :class="['flag-icon', `flag-icon-${flag}`]" />
</template>

<script setup lang="ts">
interface Props {
  source: string
}
const props = defineProps<Props>()

stores.flags.fetch()

const customFlags: Record<string, string> = {
  OpenCorporates: `${$env.cdn_url}/images/oc.png`,
}

const customFlag = computed((): string | undefined => {
  return customFlags[props.source]
})

const flag = computed((): string | undefined => {
  return stores.flags.perSource[props.source]
})
</script>

<style lang="scss">
.flag-icon {
  margin-right: 5px;
}

.custom-flag {
  margin-right: 5px;
  max-height: 16px;
  display: inline-block;
  vertical-align: middle;
}
</style>
