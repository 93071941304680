<template>
  <div v-if="showRow || $slots.empty" class="hit-field" :class="{ 'is-vertical': vertical }">
    <VRow v-for="(value, type) in values" :key="type" :class="`row-${type}`" :gutter="5">
      <VCol :md="vertical ? 24 : fieldSize">
        <div class="hit-field-title" v-text="formattedField" />
      </VCol>
      <VCol :md="vertical ? 24 : valueSize">
        <slot v-if="empty(value)" name="empty">
          <FieldEmpty />
        </slot>

        <template v-else-if="field === 'birth_dates'">
          <div v-for="(item, index) in value" :key="index">
            <FieldDate
              :age="Array.isArray(row.death_dates) && row.death_dates.length > 0 ? false : true"
              :highlight-target="highlightTarget"
              :value="isCustomSource ? `${item.date}Z` : item.date"
            />
          </div>
        </template>

        <template v-else-if="field === 'death_dates'">
          <div v-for="(item, index) in value" :key="index">
            <FieldDate :value="isCustomSource ? `${item.date}Z` : item.date" />
          </div>
        </template>

        <template v-else-if="field === 'worldcheck_categories'">
          <span v-for="(item, key) in value" :key="key" style="padding-right: 1em">{{ formatKey(key) }}: <VTextHighlight :queries="highlightTarget" :text="value[key]" /></span>
        </template>

        <template v-else-if="field === 'languages'">
          <div v-for="({ language }, index) in value" :key="index" v-text="language" />
        </template>

        <template v-else-if="field === 'identifiers'">
          <FieldIdentifier v-for="(identifier, index) in value" :key="index" :value="identifier" />
        </template>

        <template v-else-if="field === 'listed_until'">
          <FieldDate :value="value" />
          <VTag v-if="$dayjs(value).isBefore($dayjs())" size="mini" style="margin-left: 5px" type="orange">{{ $t('expired') }}</VTag>
        </template>

        <template v-else-if="field === 'nationalities'">
          <FieldNationality v-for="{ country_code, country } in value" :key="country_code || country" :highlight-target="highlightTarget" :value="country_code || country" />
        </template>

        <template v-else-if="['industry_type', 'industry_types'].includes(field)">
          <FieldIndustryCode v-for="(item, index) in value" :key="index" :value="item" />
        </template>

        <template v-else-if="field === 'associated_companies'">
          <FieldCompanyName v-for="(item, index) in value" :key="index" :highlight-target="highlightTarget" :jurisdiction="item.jurisdiction_code" :name="item.name" />
        </template>

        <VTextHighlight v-else-if="field === 'title'" :queries="highlightTarget" :text="value" />
        <FieldAddresses v-else-if="field === 'addresses' || field === 'birth_places'" :highlight-target="highlightTarget" :value="value" />
        <FieldCharacteristics v-else-if="field === 'characteristics'" :value="value" />
        <FieldContactDetails v-else-if="field === 'contact_details'" :value="value" />
        <FieldRiskExplanation v-else-if="field === 'risk_explanation'" :risk="value" :source="row.case_hit.source" :user-risk-score="row.case_hit.user_risk_score" />
        <FieldRisk v-else-if="field === 'risk'" :risk="getHighestRisk(row.case_hit.user_risk_score) ?? value.classification" rounded />
        <FieldConfidence v-else-if="field === 'score'" :implicit-feedback="row.case_hit.implicit_feedback" :value="value" />
        <FieldAliases v-else-if="field === 'aliases'" :elastic-highlights="row?.highlight ?? {}" :highlight-target="highlightTarget" :value="value" />
        <FieldAliases v-else-if="field === 'associated_entities'" :highlight-target="highlightTarget" :value="value" />
        <FieldUltimateBeneficialOwners v-else-if="field === 'ultimate_beneficial_owners'" :highlight-target="highlightTarget" :value="value" />
        <FieldAdverseEvents v-else-if="field === 'adverse'" :value="value" />
        <FieldExplanation v-else-if="field === 'explanation'" :explanation="row.explanation" />
        <FieldNationality v-else-if="field === 'company.jurisdiction_code'" field="jurisdiction_code" :value="row.company.jurisdiction_code" />
        <FieldAddresses v-else-if="field === 'residence'" :highlight-target="highlightTarget" :value="[{ text: value }]" />
        <FieldConnections v-else-if="field === 'connections'" :connections="value" :elastic-highlights="row?.highlight ?? {}" :form="form" />
        <FieldFurtherInformation v-else-if="field === 'further_information'" :highlight-target="highlightTarget" :items="value" />
        <FieldHomeCompany v-else-if="field === 'home_company'" :value="value" />
        <FieldPepRoles v-else-if="field === 'pep_roles'" :roles="value" />
        <FieldOfficers v-else-if="field === 'officers'" :officers="value" />
        <FieldGender v-else-if="field === 'gender'" :highlight-target="highlightTarget" :value="value" />
        <FieldDocuments v-else-if="field === 'documents'" :documents="value" />
        <FieldKeywords v-else-if="field === 'keywords'" :keywords="value" />
        <FieldIndustries v-else-if="field === 'industries'" :industries="value" />
        <FieldLanguage v-else-if="field === 'language'" :value="value" />
        <FieldName
          v-else-if="field === 'name'"
          :elastic-highlights="row?.highlight ?? {}"
          :form="form"
          :type="row.type ? (row.type?.value ? row.type.value : row.type) : ''"
          :value="value"
        />
        <FieldImages v-else-if="field === 'images'" :value="value" />
        <FieldDuplicates v-else-if="field === 'duplicates'" :value="value" />
        <FieldSentiment v-else-if="field === 'sentiment'" :value="value" />
        <template v-else-if="field === 'company.name'">
          <FieldCompanyName
            :highlight-target="highlightTarget"
            :jurisdiction="type !== 'new' ? row.company.jurisdiction_code : row.case_hit.update.company.jurisdiction_code"
            :name="value"
            :searched-name="searchedName"
            style="display: inline; margin-right: 5px"
          />
          <FieldCheckedName type="Business" :value="value" />
        </template>
        <FieldPosition v-else-if="field === 'position'" :position="value" />
        <FieldPositions v-else-if="field === 'positions'" :value="value" />
        <FieldSource v-else-if="field === 'source'" :url="type !== 'new' ? row.url : row.case_hit.update.url" :value="value" />
        <FieldNationality v-else-if="['jurisdiction_code', 'nationality'].includes(field)" :field="field" :highlight-target="highlightTarget" :value="value" />
        <FieldNER
          v-else-if="['organizations', 'persons', 'locations'].includes(field)"
          :elastic-highlights="row?.highlight ?? {}"
          :field="field"
          :highlight-target="highlightTarget"
          :items="value"
          :searched-name="searchedName"
        />
        <FieldDate v-else-if="field === 'listed_at'" :value="isCustomSource ? `${value}Z` : value" />
        <FieldDate v-else-if="['retrieved_at', 'updated_at', 'incorporation_date', 'publish_date'].includes(field)" :value="value" />
        <FieldSimilar v-else-if="field === 'similar'" :form="form" :similar-ids="value" />

        <template v-else-if="field === 'case_hit.created_at'">
          <FieldDate style="margin-right: 10px" :value="value" />
          <RemovedTag v-if="row.case_hit?.removed_from_source" />
        </template>

        <Translation v-else-if="['reason', 'summary', 'measures'].includes(field)" v-slot="{ translation }" :text="value">
          <FieldSummary v-if="field === 'summary'" :highlight-target="highlightTarget" :searched-name="searchedName" :value="translation" />
          <VTextHighlight v-else :queries="highlightTarget" :text="translation" />
        </Translation>
        <VTextHighlight v-else-if="highlightTarget" :queries="highlightTarget" :text="String(value)" />
        <template v-else>{{ value }}</template>
      </VCol>
    </VRow>
  </div>
</template>

<script lang="ts">
import get from 'lodash-es/get.js'
import { type PropType } from 'vue'

import { getHighestRisk } from '~/helpers/risk.ts'
import { type CaseForm, type CaseHitTransformed, type SearchHitTransformed } from '~/types.ts'

const fieldToKey: Record<string, string | undefined> = {
  risk_explanation: 'risk',
}

const arrayDiffFields = {
  organizations: 'value',
  persons: 'value',
  locations: 'value',
  connections: 'name',
}

function updatedChanges(updates, field: string) {
  if (field in arrayDiffFields) {
    const itemField = arrayDiffFields[field]
    // Remove all items from both arrays that are in both arrays.
    const added = updates.new.filter((newItem) => !updates.old.some((oldItem) => oldItem[itemField] === newItem[itemField]))
    const old = updates.old.filter((oldItem) => !updates.new.some((newItem) => oldItem[itemField] === newItem[itemField]))

    return { old, new: added }
  }

  return { old: updates.old, new: updates.new }
}

export default defineComponent({
  props: {
    vertical: Boolean,

    row: {
      required: true,
      type: Object as PropType<CaseHitTransformed['data'] | SearchHitTransformed['data']>,
    },

    field: {
      type: String,
      required: true,
    },

    form: {
      type: Object as PropType<CaseForm>,
      default: () => ({}),
    },

    fieldSize: {
      type: Number,
      default: 6,
    },

    valueSize: {
      type: Number,
      default: 18,
    },

    showChanges: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    searchedName() {
      return this.form.name
    },

    formattedField() {
      return mapField(this.field)
    },

    isCustomSource() {
      return stores.dataSources.dataSources.some((dataSource) => dataSource.id === this.row.case_hit?.data_source_id && dataSource.vendor === 'custom')
    },

    highlightTarget() {
      return createHighlightQueries(this.form, this.field)
    },

    changes() {
      const vm = this
      const changes = vm.row.case_hit?.update_changes ?? null
      if (!changes) {
        return null
      }

      const field = fieldToKey[vm.field] ?? vm.field

      if (changes.added && field in changes.added) {
        return { old: null, new: changes.added[field].new }
      }

      if (changes.removed && field in changes.removed) {
        return { old: changes.removed[field].old, new: null }
      }

      if (changes.updated && field in changes.updated) {
        return updatedChanges(changes.updated[field], field)
      }

      return null
    },

    values() {
      const vm = this
      const fieldKey = fieldToKey[vm.field] ?? vm.field

      if (vm.showChanges && vm.changes) {
        return { ...vm.changes }
      }

      return { unchanged: get(vm.row, fieldKey, null) as any }
    },

    showRow() {
      if (!this.empty(this.values.unchanged) || !this.empty(this.values.new) || !this.empty(this.values.old)) {
        return true
      }

      return false
    },
  },

  methods: {
    formatKeyValue,
    formatKey,
    getHighestRisk,

    empty(value: undefined | string | unknown[] | object) {
      if (value === undefined || value === null) {
        return true
      }

      if (typeof value === 'string') {
        return value === ''
      }

      if (Array.isArray(value)) {
        return value.length === 0
      }

      if (typeof value === 'object') {
        return Object.keys(value).length === 0
      }

      return false
    },
  },
})
</script>

<style lang="scss" scoped>
.row-old {
  padding-bottom: 7px;
  margin-bottom: 7px;
  border-bottom: 1px dashed var(--border-color);

  * {
    filter: grayscale(1);
    color: var(--color-grey-40);

    .risk-bar {
      color: var(--color-grey-8);
    }
    :not(.hit-field-title) {
      text-decoration: line-through;
    }
  }

  :deep() .v-popper {
    display: none;
  }
}
</style>
