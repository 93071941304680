import { createPinia } from 'pinia'
import { type App } from 'vue'

import { useApmEvents } from './apmEvents.ts'
import { useArchivedSources } from './archivedSources.ts'
import { useAvatars } from './avatars.ts'
import { useBillingSettings } from './billingSettings.ts'
import { useCase } from './case.ts'
import { useCases } from './cases.ts'
import { useCountries } from './countries.ts'
import { useDataSources } from './dataSources.ts'
import { useDiscounts } from './discounts.ts'
import { useFlags } from './flags.ts'
import { useGroups } from './groups.ts'
import { useInitialState } from './initialState.ts'
import { useLoading } from './loading.ts'
import { useLocations } from './locations.ts'
import { useMarketplacePacks } from './marketplacePacks.ts'
import { useNER } from './ner.ts'
import { useOrganization } from './organization.ts'
import { useOrganizationFunctions } from './organizationFunctions.ts'
import { useOrganizationIntegration } from './organizationIntegration.ts'
import { usePaymentMethods } from './paymentMethods.ts'
import { usePolicies } from './policies.ts'
import { usePrices } from './prices.ts'
import { useReportSettings } from './reportSettings.ts'
import { useRiskConfig } from './riskConfig.ts'
import { useRiskSourceConfigs } from './riskSourceConfigs.ts'
import { useServices } from './services.ts'
import { useSessions } from './sessions.ts'
import { useSettings } from './settings.ts'
import { useSource } from './source.ts'
import { useTranslation } from './translation.ts'
import { useUser } from './user.ts'
import { useUsers } from './users.ts'

export interface Stores {
  apmEvents: ReturnType<typeof useApmEvents>
  archivedSources: ReturnType<typeof useArchivedSources>
  avatars: ReturnType<typeof useAvatars>
  billingSettings: ReturnType<typeof useBillingSettings>
  case: ReturnType<typeof useCase>
  cases: ReturnType<typeof useCases>
  countries: ReturnType<typeof useCountries>
  dataSources: ReturnType<typeof useDataSources>
  flags: ReturnType<typeof useFlags>
  groups: ReturnType<typeof useGroups>
  initialState: ReturnType<typeof useInitialState>
  loading: ReturnType<typeof useLoading>
  locations: ReturnType<typeof useLocations>
  marketplacePacks: ReturnType<typeof useMarketplacePacks>
  ner: ReturnType<typeof useNER>
  organization: ReturnType<typeof useOrganization>
  organizationFunctions: ReturnType<typeof useOrganizationFunctions>
  organizationIntegration: ReturnType<typeof useOrganizationIntegration>
  paymentMethods: ReturnType<typeof usePaymentMethods>
  policies: ReturnType<typeof usePolicies>
  prices: ReturnType<typeof usePrices>
  discounts: ReturnType<typeof useDiscounts>
  reportSettings: ReturnType<typeof useReportSettings>
  riskConfig: ReturnType<typeof useRiskConfig>
  riskSourceConfigs: ReturnType<typeof useRiskSourceConfigs>
  services: ReturnType<typeof useServices>
  sessions: ReturnType<typeof useSessions>
  settings: ReturnType<typeof useSettings>
  source: ReturnType<typeof useSource>
  translation: ReturnType<typeof useTranslation>
  user: ReturnType<typeof useUser>
  users: ReturnType<typeof useUsers>
}

export const stores = {
  install(app: App) {
    const stores: Stores = {
      apmEvents: useApmEvents(),
      archivedSources: useArchivedSources(),
      avatars: useAvatars(),
      billingSettings: useBillingSettings(),
      case: useCase(),
      cases: useCases(),
      countries: useCountries(),
      dataSources: useDataSources(),
      flags: useFlags(),
      groups: useGroups(),
      initialState: useInitialState(),
      loading: useLoading(),
      locations: useLocations(),
      marketplacePacks: useMarketplacePacks(),
      ner: useNER(),
      organization: useOrganization(),
      organizationFunctions: useOrganizationFunctions(),
      organizationIntegration: useOrganizationIntegration(),
      paymentMethods: usePaymentMethods(),
      policies: usePolicies(),
      prices: usePrices(),
      discounts: useDiscounts(),
      reportSettings: useReportSettings(),
      riskConfig: useRiskConfig(),
      riskSourceConfigs: useRiskSourceConfigs(),
      services: useServices(),
      sessions: useSessions(),
      settings: useSettings(),
      source: useSource(),
      translation: useTranslation(),
      user: useUser(),
      users: useUsers(),
    }

    window.stores = stores
    app.config.globalProperties.$stores = stores
  },
}

export const pinia = createPinia()

pinia.use(({ options, store }) => {
  if (!options.init) {
    return
  }

  store.$initialized = new Promise((resolve, reject) => {
    if (!options.init) {
      resolve(store.$state)

      return
    }

    const result = options.init(store)

    if (result instanceof Promise) {
      result.then(() => resolve(store.$state)).catch((error) => reject(`Store init failed: ${error}`))
    } else {
      resolve(store.$state)
    }
  })
})

/**
 * Resets the state of a composition store.
 *
 * @param state Object containing all the values that should be reset.
 */
export function withReset(state: Record<string, unknown>): () => void {
  const initialState: Record<string, unknown> = {}

  for (const key in state) {
    const value = state[key]
    if (isRef(value)) {
      initialState[key] = value.value
    } else {
      initialState[key] = JSON.parse(JSON.stringify(value))
    }
  }

  return () => {
    for (const key in state) {
      const value = state[key]
      if (isRef(value)) {
        value.value = initialState[key]
      } else {
        //@ts-expect-error - keys are the same
        Object.assign(value, initialState[key])
      }
    }
  }
}
