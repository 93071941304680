<template>
  <CountryLink v-if="nerType === 'location' && link" :highlight-target="highlightTarget" :text="text" />
  <SearchLink v-else-if="nerType && link" :highlight-target="highlightTarget" :searched-name="searchedName" :text="text.trim()" :type="intelligentCheckType" />
  <span v-else-if="nerType === 'location'" v-text="text" />
  <VTextHighlight v-else :queries="highlightTarget" :text="text" />
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type HighlightTarget } from '~/types.ts'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    highlightTarget: {
      type: [Array, String] as PropType<HighlightTarget>,
      default: '',
    },

    searchedName: {
      type: String,
      default: '',
    },
  },

  computed: {
    lowercased() {
      return this.text.toLowerCase().trim()
    },

    link() {
      if (isOneTimeCase()) {
        return false
      }

      if (this.nerType === 'location') {
        return this.isCountry(this.lowercased)
      }

      return true
    },

    nerType() {
      if (!(this.lowercased in stores.ner.results)) {
        return ''
      }

      return stores.ner.results[this.lowercased]
    },

    intelligentCheckType() {
      if (this.nerType === 'organization') return 'Business'
      if (this.nerType === 'person') return 'Person'

      return ''
    },
  },

  methods: {
    isCountry(value: string) {
      return stores.countries.countryName(value) !== null
    },
  },
})
</script>
