import { acceptHMRUpdate, defineStore } from 'pinia'

interface Location {
  address: string
  formatted: string
  geo_country_code: string
  geometry: {
    lat: number
    lon: number
  }
  type: string
  updated_at: string
}

export const useLocations = defineStore('locations', {
  state: () => ({
    locations: {} as Record<string, Location>,
  }),

  actions: {
    async search(query: string) {
      if (!query) {
        return {}
      }

      if (query in this.locations) {
        return this.locations[query]
      }

      if (query.endsWith(' Z/N')) {
        query = query.substring(0, query.length - 4)
      }

      const { data } = await api.post<Location>('geocode', { query })
      this.locations[query] = data

      return data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLocations, import.meta.hot))
}
