<template>
  <VMentionableOverlay v-if="readonly" :options="options" :value="value">
    <template #mention="{ mention }">
      <!-- Remove whitespace before and after mention label -->
      <VTooltip style="pointer-events: all; cursor: help" tag="span" wrapper-tag="span"
        >@{{ mention.label
        }}<template #content>
          <div class="user-info">
            <UserAvatar :size="40" style="font-size: 20px; font-weight: 500" :user-id="mention.key" />
            <div style="margin-left: 10px">
              <div class="bold" v-text="mention.label" />
              <div class="color-grey-40" v-text="$stores.users.users[mention.key].email" />
            </div>
          </div>
        </template>
      </VTooltip>
    </template>
  </VMentionableOverlay>
  <VMentionable
    v-else
    ref="vmentionable"
    :disabled="disabled"
    :model-value="value"
    :options="options"
    :placeholder="placeholder"
    style="width: 100%"
    :suggestions="suggestions"
    @update:model-value="$emit('update:modelValue', JSON.stringify($event))"
  >
    <template #mention="{ mention }">
      <!-- Remove whitespace before and after mention label -->
      <VTooltip style="pointer-events: all; cursor: help" tag="span" wrapper-tag="span"
        >@{{ mention.label
        }}<template #content>
          <div class="user-info">
            <UserAvatar :size="40" style="font-size: 20px; font-weight: 500" :user-id="mention.key" />
            <div style="margin-left: 10px">
              <div class="bold" v-text="mention.label" />
              <div class="color-grey-40" v-text="$stores.users.users[mention.key].email" />
            </div>
          </div>
        </template>
      </VTooltip>
    </template>

    <template #option="{ option }">
      <div class="v-select-option__left">
        <FieldUser :user="option" />
      </div>
      <div v-if="option.labelRight" class="v-select-option__right">
        {{ option.labelRight }}
      </div>
    </template>
  </VMentionable>
</template>

<script setup lang="ts">
import { VMentionable } from '@vartion/ui'
import { type Mention } from '@vartion/ui/src/components/VMentionable.vue'

import { type MentionableComment, type User } from '~/types.ts'

interface Props {
  modelValue: string
  disabled?: boolean
  readonly?: boolean
  suggestions?: string[]
  placeholder?: string
}

const props = defineProps<Props>()

defineEmits<{
  'update:modelValue': [string]
}>()

const vmentionable = ref<InstanceType<typeof VMentionable>>()

const value = computed((): MentionableComment => {
  return JSON.parse(props.modelValue) ?? []
})

const options = computed((): (User & Mention)[] => {
  return Object.values(stores.users.users).map((user) => ({
    ...user,
    key: user.id,
    label: user.name,
    labelRight: user.email,
  }))
})

defineExpose({
  vmentionable,
})
</script>

<style lang="scss" scoped>
.readonly :deep() {
  .v-input__inner {
    border: none;
    pointer-events: none;
  }
}

.user-info {
  display: flex;
  align-items: center;
  min-width: 250px;
}
</style>
