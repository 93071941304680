import { type InjectionKey } from 'vue'

export function injectRequired<T>(key: string | InjectionKey<T>): NonNullable<T> {
  const injection = inject(key)

  if (!injection) {
    throw Error(`Injection ${String(key)} is required`)
  }

  return injection
}
