<template>
  <VTooltip placement="top" tag="span" wrapper-tag="span">
    <template #content>
      <div v-for="(line, index) in details.split('\n')" :key="index" :style="index === 0 ? 'font-weight:500' : ''">
        {{ line }}
      </div>
    </template>

    <slot />
  </VTooltip>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    details: {
      type: String,
      required: true,
    },
  },
})
</script>
