<template>
  <VCard v-if="empty" :title="title">
    <SourceEmpty />
  </VCard>

  <div v-if="!empty">
    <div style="text-align: right">
      <FilterButton v-model="caseStore.showFilters" :active="nFiltersActive > 0" />
    </div>

    <SourceFilters
      v-if="!empty"
      v-show="caseStore.showFilters"
      :filtered-hits="filteredHits"
      :filtered-names="filteredNames"
      :hits="hits"
      source="businessRegisters"
      @update:filtered-names="filteredNames = $event"
    />
  </div>

  <VCard v-if="!empty" :title="title">
    <template #header-left>
      <div v-if="nFiltersActive" style="margin-top: 4px">
        {{ nFiltersActive }} {{ $t('filters-active', nFiltersActive) }}
        <VButton icon="Close" plain size="small" style="margin-left: 5px" type="primary" @click="clearAllFilters">{{ $t('clear-all') }}</VButton>
      </div>
    </template>

    <SourceTable source="businessRegisters" v-bind="sourceTableProps" @expand-open="expand" />

    <ContextMenu ref="contextMenu" v-slot="{ data }">
      <ul>
        <li v-for="item in contextMenuItems" :key="item" @click="contextMenuClick(item, data)">{{ item }}</li>
      </ul>
    </ContextMenu>

    <ActionBarResolve v-model:selection="selection" />
  </VCard>
</template>

<script setup lang="ts">
import ContextMenu from '~/components/ContextMenu.vue'
import SourceTable from '~/components/SourceTable.vue'
import { type SourceProps, useSource } from '~/composables/source.ts'
import { type CaseHitTransformed } from '~/types.ts'

const props = withDefaults(defineProps<SourceProps>(), {
  form: () => ({}),
  tableSize: 20,
})

const contextMenu = ref<InstanceType<typeof ContextMenu>>()
const sourceTable = ref<InstanceType<typeof SourceTable>>()

const {
  activeResolution,
  caseStore,
  clearAllFilters,
  contextMenuClick,
  contextMenuItems,
  empty,
  filteredHits,
  filteredNames,
  hits,
  nFiltersActive,
  selection,
  sourceStores,
  sourceTableProps,
  title,
} = useSource(props, contextMenu, sourceTable, 'businessRegisters', [
  'selection',
  'expand',
  'entry_count',
  'name',
  'status',
  'jurisdiction_code',
  'source',
  'risk',
  'confidence',
  'resolve',
  'comments',
])

const rowsLoading = ref<number[]>([])

async function expand(row: CaseHitTransformed['data']) {
  if (!('data' in row) && row.source === 'OpenCorporates') {
    const hit = sourceStores.businessRegisters[activeResolution.value].find((hit) => hit.id === row.case_hit.id)
    if (hit) {
      await loadDetailedInformation(hit)
    }
  }
}

async function loadDetailedInformation(hit: CaseHitTransformed) {
  if (rowsLoading.value.includes(hit.id)) {
    return
  }

  rowsLoading.value.push(hit.id)

  try {
    const { data } = await api.get<{ hits: Record<string, any>[] }>(`business-registers/companies/${hit.data.jurisdiction_code}/${hit.data.company_number}`)

    if (Object.keys(data.hits).length > 0) {
      const updatedHitData = { ...Object.values(data.hits)[0], score: hit.data.score }
      sourceStores.businessRegisters.updateHitData({ id: hit.id, data: updatedHitData })
    }
  } catch (error) {
    console.error(error)
  } finally {
    const index = rowsLoading.value.indexOf(hit.id)
    rowsLoading.value.splice(index, 1)
  }
}
</script>
