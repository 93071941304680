<template>
  <VIcon :icon="icon" :background-color="color" />
</template>

<script lang="ts">
import { type PropType } from "vue"


export const icons = {
  Person: 'User',
  Business: 'Buildings',
  Country: 'Globe',
  individual: 'User',
  entity: 'Buildings',
}

export const iconColors = {
  Person: '#e2baff',
  individual: '#e2baff',
  Business: '#00fdff',
  entity: '#00fdff',
  Country: '#ffed6e',
}

export type CaseIconType = keyof typeof icons

export default defineComponent({
  props: {
    type: {
      type: String as PropType<keyof typeof icons>,
      required: true,
    },
  },

  computed: {
    icon() {
      return icons[this.type]
    },

    color() {
      return iconColors[this.type]
    }
  },
})
</script>
