<template>
  <Comments :comments="hit.case_hit.comments" :editable="(comment) => $user.id === comment.user_id && !$stores.policies.policies.require_hit_comments" @remove="remove" />
</template>

<script setup lang="ts">
import { type CaseHitTransformed } from '~/types.ts'

interface Props {
  hit: CaseHitTransformed['data']
}

defineProps<Props>()

async function remove({ id }: { id: number }) {
  await $confirm({ title: $t('delete-comment'), message: $t('if-you-proceed-with-this-action-the-comment-will-be-deleted-permanently') })
  try {
    await api.delete(`/hit-comments/${id}`)
  } catch (error) {
    $message.error($t('api-error'))
  }
}
</script>
