<template>
  <DialogForm
    :form="form"
    :loading="loading"
    placement="left"
    :rules="rules"
    :submit-text="$t('submit')"
    :title="$t('data')"
    :visible="visible"
    @submit="submit"
    @update:visible="hide"
  >
    <p style="margin-bottom: 15px">{{ $t('use-this-form-when-you-notice-missing-outdated-or-incorrect-data') }}</p>

    <VSelect v-model="form.data_bug" :label="$t('category')" prop="data_bug">
      <VOption :label="$t('general')" value="general" />
      <VOption :label="$t('bug')" value="bug" />
      <VOption :label="$t('request')" value="request" />
    </VSelect>

    <VTextarea v-if="form.data_bug !== null" v-model="form.description" :label="$t('describe-the-issue')" :maxlength="2000" prop="description" :rows="4" />

    <template v-if="form.data_bug && $env.app_env !== 'production'">
      <p style="margin-bottom: 10px">{{ $t('please-give-a-hit-id-regarding-your-bug') }}</p>
      <VInput v-model="form.hit_id" label="Hit id" />
    </template>

    <p style="margin-bottom: 10px">{{ $t('please-provide-the-related-source-if-relevant') }}</p>
    <VInput v-model="form.source" :label="$t('source')" />

    <VSelect v-model="form.follow_up_email" :label="$t('may-we-follow-up-on-the-given-feedback-via-email')">
      <VOption v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </VSelect>

    <VUpload accept=".jpg,.png,.mp4,.webm,.pdf" drag :files="form.attachment" :max="5" multiple :pastable="visible" style="display: inline" @update:files="updateAttachment" />
  </DialogForm>
</template>

<script lang="ts">
const defaultForm = () => ({
  description: '',
  hit_id: '',
  source: '',
  data_bug: 'general',
  follow_up_email: true,
  attachment: [],
})

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:visible'],

  data: (vm) => ({
    loading: false,
    form: defaultForm(),
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
    rules: {
      description: { required: true, max: 2000, message: vm.$t('please-describe-the-issue') },
    },
    files: [],
  }),

  methods: {
    hide() {
      this.$emit('update:visible', false)
    },

    updateAttachment(files) {
      if (files.some((file) => file.size > 2000000)) {
        this.$message.error('File is too large. The maximum size is 2MB')

        return
      }

      this.form.attachment = files
    },

    async submit() {
      const formData = new FormData()

      for (const [key, value] of Object.entries({ ...this.form, data_bug: this.form.data_bug === 'bug' })) {
        formData.append(key, value)
      }

      formData.delete('attachment')

      this.form.attachment.forEach((file, index) => formData.append(`files[${index}]`, file))
      formData.append('title', this.form.description.split('\n')[0].split('. ')[0].slice(0, 180))
      formData.append('url', document.location.href)

      this.loading = true

      try {
        this.form = defaultForm()
        await api.post('data-request', formData)
        this.$message.success(this.$t('your-report-was-successfully-submitted'))
      } catch {
        this.$message.error(this.$t('api-error'))
      }
      this.loading = false
    },
  },
})
</script>
