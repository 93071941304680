<template>
  <div>
    <div style="display: flex; align-items: center">
      <VRadioGroup v-model="currentTab" style="margin-bottom: 30px" type="tabs">
        <VRadio v-for="tab in tabs" :key="tab.source" :disabled="tab.total === 0" :label="tab.source">
          <VProgress :footer="tab.progressLabel" :height="8" :label="tab.sourceLabel" :loading="tab.loading" :type="tab.status" :value="tab.progress" width="80px" />
          <VPopover :offset="30" placement="bottom" trigger="click" @click.stop.prevent>
            <template #reference>
              <VIcon icon="InfoCircle" :size="10" style="position: absolute; right: -5px; bottom: -20px" type="placeholder" />
            </template>
            <div class="title-20">{{ $t('data-sources') }}</div>
            {{
              $t('used-count-data-sources-used-failed-count-sources-failed-to-load', {
                usedCount: tabData[tab.source].length,
                failedCount: tabData[tab.source].filter((source) => source.status === 'error').length,
              })
            }}

            <VTable v-if="tabData[tab.source].some((source) => source.active)" class="sources-table" :data="tabData[tab.source]" hide-header>
              <VTableColumn v-slot="{ row: source }: { row: Source }" cell-class="source-column flex-center">
                <div class="flex-center" style="min-height: 15px; min-width: 35px">
                  <div v-if="!source.active" class="--color-text-secondary">{{ $t('inactive') }}</div>
                  <VLoadingDots v-else-if="source.status === 'loading'" />
                  <VIcon v-else-if="source.status === 'loaded'" icon="Check" type="green" />
                  <VIcon v-else icon="Close" type="red" />
                </div>

                <template v-if="source.data_source.vendor === 'custom'">{{ $stores.initialState.sourceLabels[source.data_source.source] }}</template>
                <template v-else>{{ $stores.dataSources.dataSources.find((dataSource) => dataSource.id === source.data_source_id)?.marketplace_pack?.name }}</template>
              </VTableColumn>
              <VTableColumn v-slot="{ row: source }: { row: Source }" cell-class="vendor-column">
                {{ dataSourceVendor(source.data_source_id) }}
              </VTableColumn>
              <VTableColumn v-slot="{ row: source }: { row: Source }">
                <VButton
                  icon="Refresh"
                  size="small"
                  :style="{ margin: '-5px 0', visibility: source.status === 'error' ? 'visible' : 'hidden' }"
                  type="icon"
                  @click="updateSource(source)"
                />
              </VTableColumn>
            </VTable>
            <div v-else>{{ tab.sourceLabel }} {{ $t('is-not-activated-in-this-case') }}</div>
          </VPopover>
        </VRadio>
      </VRadioGroup>

      <div v-if="resolutionNavigation" style="display: flex; margin-left: auto; margin-right: 0; margin-top: 0; margin-bottom: auto">
        <VNavbarItem
          v-for="(label, resolution) in { unresolved: $t('unresolved'), positive: $t('included'), negative: $t('excluded') }"
          :key="resolution"
          :active="caseStore.activeResolution === resolution"
          @click="updateResolution(resolution)"
        >
          {{ label }}
        </VNavbarItem>
      </div>
    </div>
    <KeepAlive>
      <Component :is="component" :key="`${currentTab}.${caseStore.activeResolution}`" :form="highlightForm" :table-size="tableSize" />
    </KeepAlive>
    <VCard v-if="currentTab === ''">
      <SourceLoading v-if="searching" />

      <div v-else class="empty-message">
        <div v-if="caseStore.activeResolution === 'unresolved'">
          <div v-if="!hasResults" class="title-20">{{ $t('no-matches-have-been-found') }}</div>
          <div v-if="!hasResults && !isOneTimeCase()" style="margin-bottom: 20px">
            {{ $t('please-check-if-your-input-is-correct-if-you-expected-results-and-did-not-find-them-you-can-also') }}
            <VButton :label="$t('make-a-data-request')" type="link" @click="onMakeRequest" />
          </div>
          <div v-else-if="hasResults" class="title-20">{{ $t('all-items-have-been-resolved') }}</div>
          <template v-if="isOneTimeCase()">
            <VButton :label="$t('go-back')" size="small" style="margin-top: 20px" @click="$emit('back')" />
          </template>
          <template v-else-if="!canEditCaseStatus(caseModel)" />

          <div v-else-if="isPreviewDialog">
            <div style="padding-bottom: 10px">
              {{ $t('open-case-to-manage-this-case') }}
            </div>
            <VButton plain size="small" @click.stop="$router.push(`/cases/${caseStore.case?.uuid}`)">{{ $t('open-case') }}</VButton>
          </div>

          <template v-else-if="caseModel.status === 'In review'">
            <div v-if="hasResults && $organization.type === 'free'" style="margin-bottom: 20px">{{ $t('you-can-archive-this-case') }}</div>
            <div v-else-if="hasResults" style="margin-bottom: 20px">{{ $t('you-can-start-monitoring-this-case-or-archive-it') }}</div>
            <VButton v-if="$organization.type !== 'free' && $stores.policies.billedMonitoringFrequency !== 0" size="small" @click="updateStatus('Monitored')">
              {{ $t('start-monitoring') }}
            </VButton>
            <VButton size="small" type="link" @click="updateStatus('Archived')">{{ $t('archive-case') }}</VButton>
          </template>
          <template v-else>
            <div v-if="caseModel.status === 'Monitored'" style="margin-bottom: 20px">
              {{ $t('your-case-is-being-monitored-and-you-will-be-notified-when-new-items-are-found') }}
            </div>
            <div v-else-if="caseModel.status === 'Archived'" style="margin-bottom: 20px">{{ $t('your-case-is-currently-archived') }}</div>
            <ReportButton
              :case-model="caseModel"
              :default-filename="caseStore.searchedName"
              :type="caseModel.status === 'Preview' ? 'preview' : 'case'"
              @report-generated="reportBatchId = $event"
            >
              <span v-text="$t('download-case-report')" />
            </ReportButton>
            <NextCaseButton :case-model="caseModel" />
          </template>
        </div>

        <div v-else-if="isPreviewDialog">
          <div class="title-20" style="padding-bottom: 10px">
            {{ $t('open-case-to-manage-this-case') }}
          </div>
          <VButton plain size="small" @click.stop="$router.push(`/cases/${caseStore.case?.uuid}`)">{{ $t('open-case') }}</VButton>
        </div>

        <div v-else>
          <div v-if="hasUnresolved" lass="title-20">{{ $t('start-resolving-some-items') }}</div>
          <div v-else class="title-20">{{ $t('all-items-have-been-resolved') }}</div>
          <div style="margin-bottom: 20px">
            <template v-if="caseStore.activeResolution === 'positive'">{{ $t('your-included-items-will-show-up-here') }}</template>
            <template v-else>{{ $t('your-excluded-items-will-show-up-here') }}</template>
          </div>
          <VButton v-if="hasUnresolved" size="small" @click="updateResolution('unresolved')">{{ $t('start-resolving') }}</VButton>
        </div>
      </div>
    </VCard>

    <DialogDataRequest v-model:visible="dialogDataRequestVisible" />
  </div>
</template>

<script setup lang="ts">
import { dataSourceVendor, isOneTimeCase } from '~/helpers/index.ts'
import { $caseStoreKey } from '~/injections.ts'
import { FORM_KEYS } from '~/stores/case.ts'
import type { Source } from '~/stores/hits'
import { canEditCaseStatus } from '~/stores/user.ts'
import { type CaseForm, type CaseSource, type CaseSourceName, type CaseStatus, type HitResolution } from '~/types.ts'
import BusinessRegisters from './Sources/BusinessRegisters.vue'
import CompanyOfficers from './Sources/CompanyOfficers.vue'
import Enforcements from './Sources/Enforcements.vue'
import News from './Sources/News.vue'
import Other from './Sources/Other.vue'
import Peps from './Sources/Peps.vue'
import Sanctions from './Sources/Sanctions.vue'

const sourceComponents = {
  businessRegisters: BusinessRegisters,
  companyOfficers: CompanyOfficers,
  other: Other,
  news: News,
  peps: Peps,
  sanctions: Sanctions,
  enforcements: Enforcements,
}

interface Props {
  visible?: boolean
  resolutionNavigation?: boolean
  tableSize?: number
}

const props = withDefaults(defineProps<Props>(), {
  tableSize: 20,
})

const emit = defineEmits<{
  'update:visible': [value: boolean]
  back: []
}>()

const { caseStore, sourceStores, isPreviewDialog } = injectRequired($caseStoreKey)

const tabs = useResolveProgress(caseStore)

const currentTab = ref<CaseSourceName | ''>('')
const dialogDataRequestVisible = ref(false)

const caseModel = computed(() => stores.cases.opened[caseStore.caseId])
const searching = computed(() => caseStore.isSearching)
const hasUnresolved = computed(() => caseStore.sources.some((source) => sourceStores[source].unresolved.length > 0))
const activeResolution = computed(() => caseStore.activeResolution)
const component = computed(() => (currentTab.value ? sourceComponents[currentTab.value] : undefined))
const hasResults = computed(() => tabs.value.some((tab) => tab.total > 0))
/** To keep track of the report being generated for this case and instance, for determining whether to close this dialog. */
const reportBatchId = ref<string>()

const tabData = computed(() => {
  const data: Partial<Record<CaseSourceName, Source[]>> = {}
  for (const tab of tabs.value) {
    data[tab.source] = Object.values(sourceStores[tab.source].sources)
  }

  return data
})

const highlightForm = computed(() => {
  const form: Partial<CaseForm> = {}

  for (const key of FORM_KEYS) {
    // @ts-expect-error - keys are the same
    form[key] = caseModel.value[key]
  }

  return form
})

watch(
  () => props.visible,
  () => {
    if (!currentTab.value) {
      checkIfTabIsEmpty()
    }
  },
  { immediate: true },
)

watch(searching, (value) => {
  if (!value) {
    checkIfTabIsEmpty()
  }
})

watch(hasResults, (value) => {
  if (value) {
    checkIfTabIsEmpty()
  }
})

watch(activeResolution, () => {
  checkIfTabIsEmpty()
})

watch(reportBatchId, (newValue) => {
  if (newValue) emit('update:visible', false)
})

onBeforeMount(() => {
  $bus.on('hits.resolved', checkIfTabIsEmpty)
  $bus.on('reportDownloaded', (report) => {
    if (report.batch_id === reportBatchId.value) {
      emit('update:visible', false)
    }
  })
})

onBeforeUnmount(() => {
  $bus.off('hits.resolved', checkIfTabIsEmpty)
})

/**
 * When the current tab does not contain any hits,
 * Check if the other tabs have hits and switch tab when they do.
 */
function checkIfTabIsEmpty() {
  const source = currentTab.value ? currentTab.value : caseStore.sources[0]
  const hits = getHits(source)

  if (hits.length > 0) {
    currentTab.value = source

    return
  }

  for (const source of caseStore.sources) {
    const hits = getHits(source)

    if (hits.length > 0) {
      currentTab.value = source
      break
    }
  }

  if (currentTab.value && getHits(currentTab.value).length === 0) {
    currentTab.value = ''
  }
}

function getHits(source: CaseSourceName) {
  const sourceStore = sourceStores[source]

  return sourceStore ? sourceStore[caseStore.activeResolution] : []
}

function updateResolution(resolution: HitResolution) {
  caseStore.activeResolution = resolution
}

async function updateStatus(status: CaseStatus) {
  await stores.cases.update({ id: caseStore.caseId, status })
}

function onMakeRequest() {
  dialogDataRequestVisible.value = true
  stores.apmEvents.track({ event: 'open-data', category: 'support' })
}

async function updateSource(source: CaseSource) {
  await api.post('case-sources/search', { ids: [source.id], case_id: caseModel.value.id })
  stores.case[source.data_source.source].searching(source)
}

defineExpose({
  currentTab,
})
</script>

<style lang="scss" scoped>
.v-radio--disabled :deep() {
  cursor: default;

  .v-popper-reference {
    opacity: 0.5;
  }
}

.v-progress :deep() .v-progress__label {
  font-weight: 600;
}

.empty-message {
  display: flex;
  justify-content: center;
}

.sources-table {
  margin-top: 15px;
  :deep() {
    .source-column {
      font-weight: 500;
    }
    .vendor-column {
      color: var(--color-text-secondary);
    }
  }
}
</style>
