<template>
  <a :href="value" target="_blank" @click.stop="">
    <slot>{{ domain }}</slot>
  </a>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    value: {
      required: true,
      type: String,
    },
  },

  computed: {
    domain: (vm) => getDomain(vm.value),
  },
})
</script>
