<template>
  <VButton
    :disabled="disabled"
    :icon="icon"
    :loading="downloading"
    :loading-label="downloading ? $t('generating') : undefined"
    :size="size"
    :type="buttonType"
    @click.stop="dialogVisible = true"
  >
    <slot />

    <DialogForm v-model:visible="dialogVisible" :loading="downloading" :title="$t('download-report', count)">
      <p style="margin-bottom: 20px">
        {{
          $t(
            'when-the-report-has-been-generated-it-will-be-available-for-download-90-days-from-the-document-creation-in-the-report-manager-after-these-90-days-it-will-be-deleted',
            count,
          )
        }}
      </p>

      <VSelect v-if="type === 'organization-audit'" v-model="selectedPeriod" filterable :label="$t('choose-period')" @update:model-value="addPeriodToFilename">
        <VOptionGroup v-for="(periods, year) in periodsPerYear" :key="year" :title="year">
          <VOption v-for="period in periods" :key="period.label" :label="period.label" :value="period.value" />
        </VOptionGroup>
      </VSelect>

      <VSelect v-model="settings.pages" :label="$t('choose-content-to-include-in-the-report', count)" multiple>
        <VOption v-for="(label, value) in availablePages" :key="value" :label="label" :value="value" />
      </VSelect>

      <VSelect
        v-if="type === 'case' || type === 'preview'"
        v-model="settings.detailed"
        :label="type === 'case' ? $t('include-detailed-information-for-resolved-hits') : $t('include-detailed-information-for-found-hits')"
      >
        <!-- eslint-disable-next-line vue/prefer-true-attribute-shorthand -->
        <VOption :label="$t('yes')" :value="true" />
        <VOption :label="$t('no')" :value="false" />
      </VSelect>

      <VTextarea v-model="comment" :label="$t('include-a-comment-to-this-report', count)" :rows="5" />
      <VInput v-if="type !== 'cases'" v-model="filename" :label="$t('filename')" :maxlength="190" required />

      <template v-if="type === 'client' && selectedClient?.cases_count && settings.include_case_reports !== null">
        <VSwitch v-model="settings.include_case_reports" :label="$t('include-all-individual-case-reports')" style="margin-bottom: 20px" />

        <VTransitionCollapse lazy :visible="settings.include_case_reports">
          <VForm>
            <VSelect v-model="settings.case_report_pages" :label="$t('choose-content-to-include-in-the-report', count)" multiple>
              <VOption v-for="(label, value) in caseReportPages" :key="value" :label="label" :value="value" />
            </VSelect>

            <VSelect v-model="settings.detailed" :label="$t('include-detailed-information-for-resolved-hits')">
              <!-- eslint-disable-next-line vue/prefer-true-attribute-shorthand -->
              <VOption :label="$t('yes')" :value="true" />
              <VOption :label="$t('no')" :value="false" />
            </VSelect>
          </VForm>
        </VTransitionCollapse>
      </template>

      <VSwitch v-if="type === 'case'" :label="$t('automatically-generate-monthly-reports')" :model-value="!!caseModel.report_schedule" @update:model-value="toggleScheduling" />

      <template #footer>
        <VButton type="secondary" @click="dialogVisible = false">{{ $t('cancel') }}</VButton>
        <VButton :disabled="disabledHelp.disabled" :help="disabledHelp.help" @click="download">{{ $t('download') }}</VButton>
        <VButton :disabled="disabledHelp.disabled" :help="disabledHelp.help" @click="mail">{{ $t('email') }}</VButton>
      </template>
    </DialogForm>
  </VButton>
</template>

<script setup lang="ts">
import { type VButtonSize, type VButtonType, type VIconIcon } from '@vartion/ui'
import { type Dayjs } from 'dayjs'

import { useReportSettings } from '~/stores/reportSettings.ts'
import { type CaseModelDetailed, type ClientDetailed, type ReportType } from '~/types.ts'
import { reportTypes } from './ViewReports.vue'

const reportSettings = useReportSettings()

interface DownloadRequest {
  pages: string[]
  comment: string
  filename?: string
  detailed?: boolean
  client_id?: number
  include_case_reports?: boolean
  case_report_pages?: string[]
  case_report_detailed?: boolean
  period?: [Dayjs, Dayjs]
  organization_id?: number
  filters?: Record<string, any>
}

interface Props {
  type: ReportType | 'cases' | 'preview'
  caseModel?: CaseModelDetailed | Record<string, never>
  defaultFilename?: string
  size?: VButtonSize
  buttonType?: VButtonType
  filters?: Record<string, any>
  disabled?: boolean
  icon?: VIconIcon
}

const props = withDefaults(defineProps<Props>(), {
  caseModel: () => ({}),
  defaultFilename: '',
  size: 'small',
  buttonType: 'primary',
  filters: undefined,
  disabled: false,
  icon: undefined,
})

const emit = defineEmits<{
  reportGenerated: [value?: string]
}>()

const batchId = ref('')
const dialogVisible = ref(false)
const downloading = ref(false)
const filename = ref('')
const comment = ref('')
const selectedClient = ref<ClientDetailed | null>(null)
const selectedPeriod = ref<[Dayjs, Dayjs] | null>(null)

const settings = computed(() => {
  return reportSettings.settings[props.type]
})

const count = computed(() => (props.type === 'cases' ? 2 : 1))

const disabledHelp = computed(() => {
  const help = new DisabledHelp()

  help.add($t('a-download-is-in-progress'), downloading.value)
  help.add($t('you-need-to-select-at-least-one-page'), settings.value.pages.length === 0)
  help.add($t('a-filename-is-required'), filename.value.trim().length === 0)

  if (props.type === 'client') {
    help.add($t('you-need-to-select-at-least-one-page'), !!(settings.value.include_case_reports && settings.value.case_report_pages?.length === 0))
    help.add($t('you-need-to-select-at-least-one-client'), selectedClient.value === null)
  }

  help.add($t('you-need-to-select-a-period'), props.type === 'organization-audit' && selectedPeriod.value === null)

  return help
})

const caseReportPages = computed(() => {
  const pages: Record<string, string> = {
    overview: $t('overview'),
    input: $t('input'),
    policies: $t('policies'),
    key_findings: $t('key-findings'),
    audit: $t('audit'),
    comments: $t('audit-comments'),
    positive: $t('include'),
    negative: $t('exclude'),
    unresolved: $t('unresolved'),
  }
  const hasPassportVerification = Object.keys(stores.services.$state).includes('passportVerification')
  if (
    (props.type === 'client' && hasPassportVerification) ||
    (props.type === 'cases' && hasPassportVerification) ||
    (props.caseModel.type === 'Person' && props.caseModel.passport_verification)
  ) {
    pages.passport_verification = $t('passport-verification')
  }

  return pages
})

const availablePages = computed(() => {
  if (props.type === 'case' || props.type === 'preview' || props.type === 'cases') {
    const pages = { ...caseReportPages.value }

    // Generating bulk case reports should allow setting all pages.
    if (props.type === 'cases') {
      return pages
    }

    // Preview doesn't contain positive/negative.
    if (props.type === 'preview') {
      delete pages.positive
      delete pages.negative
    } else {
      // Single case report doesn't contain unresolved.
      delete pages.unresolved
    }

    return pages
  }

  if (props.type === 'client') {
    return { overview: $t('client-overview'), case_overview: $t('case-overview'), cases: $t('cases') }
  }

  if (props.type === 'organization-audit') {
    return { overview: $t('overview'), audit: $t('audit') }
  }

  if (props.type === 'user-sessions') {
    return { overview: $t('overview'), sessions: $t('sessions') }
  }

  return { overview: $t('overview') }
})

const url = computed(() => {
  if (props.type === 'preview' || props.type === 'cases' || props.type === 'case') {
    return 'v1/pdf/case'
  }

  return `pdf/${props.type}`
})

const payload = computed(() => {
  const payload: DownloadRequest = {
    pages: settings.value.pages,
    comment: comment.value,
  }
  if (props.type !== 'cases') {
    payload.filename = filename.value
  }

  if (props.filters) {
    payload.filters = props.filters
  }

  if (props.type === 'case' || props.type === 'preview') {
    payload.detailed = !!settings.value.detailed
    payload.filters = { uuid: [props.caseModel.uuid] }
  } else if (props.type === 'client' && selectedClient.value) {
    payload.client_id = selectedClient.value.id
    payload.include_case_reports = selectedClient.value.cases_count > 0 ? !!settings.value.include_case_reports : false
    payload.case_report_pages = settings.value.case_report_pages ?? undefined
    payload.case_report_detailed = !!settings.value.detailed
  } else if (props.type === 'organization-audit' && selectedPeriod.value) {
    payload.period = selectedPeriod.value
    payload.organization_id = stores.organization.id
  }

  return payload
})

const periodsPerYear = computed(() => {
  let periods: { label: string; value: [Dayjs, Dayjs] }[] = []
  const years: Record<string, typeof periods> = {}
  const createdAt = dayjs(stores.organization.created_at).startOf('day')
  let to = dayjs().endOf('quarter')
  let from = dayjs().startOf('quarter')
  let isFirst = true
  while (createdAt.isBefore(to)) {
    const value: [Dayjs, Dayjs] = [from.clone(), to.clone()]

    periods.push({ label: `${value[0].format('MMMM YYYY')} - ${value[1].subtract(isFirst ? 0 : 1, 'month').format('MMMM YYYY')}`, value })
    isFirst = false

    to = from.clone()
    from = from.subtract(1, 'quarter')

    if (from.year() < to.year()) {
      years[`${to.year()} `] = periods
      periods = []
    }
  }

  if (!(`${to.year()} ` in years)) {
    years[`${to.year()} `] = periods
  }

  return years
})

watch(
  () => dialogVisible.value,
  (value) => {
    if (value) {
      setDefaults()
    }
  },
)

createFilename(props.defaultFilename)

async function setDefaults() {
  createFilename(props.defaultFilename)
  await reportSettings.get()
  comment.value = ''
  selectedPeriod.value = null
}

async function download() {
  downloading.value = true
  reportSettings.update(settings.value)

  try {
    const { data } = await api.post(url.value, payload.value)

    $bus.emit('batch.created', data)
    batchId.value = data.id
    emit('reportGenerated', batchId.value)
  } catch (error) {
    downloading.value = false
    $message.error($t('api-error'))
  }

  dialogVisible.value = false
}

function onBatchFinished(finishedBatchId: string) {
  if (finishedBatchId === batchId.value) {
    downloading.value = false
    batchId.value = ''
  }
}
$bus.on('batch.finished', onBatchFinished)
onUnmounted(() => $bus.off('batch.finished', onBatchFinished))

async function mail() {
  reportSettings.update(settings.value)

  try {
    await api.post(url.value, {
      ...payload.value,
      to_email: true,
    })

    $message.success($t('your-report-is-being-generated-and-you-will-receive-an-email-when-it-is-ready'))
    emit('reportGenerated')
  } catch (error) {
    $message.error($t('api-error'))
  }

  dialogVisible.value = false
}

function createFilename(text: string) {
  text = text.slice(0, 150)
  const types = reportTypes()
  filename.value = `${dayjs().format('YYMMDD')}${text ? `_${text}_` : '_'}Pascal ${props.type in types ? types[props.type as ReportType] : types.case}`
}

function addPeriodToFilename() {
  if (selectedPeriod.value) {
    createFilename(`Q${selectedPeriod.value[0].format('Q YYYY')}`)
  }
}

async function toggleScheduling(scheduled: boolean) {
  try {
    if (scheduled) {
      const { data } = await api.post('report-schedules', { reportable_id: props.caseModel.id })
      stores.cases.updateOpened({ id: props.caseModel.id, report_schedule: data })
    } else {
      await api.delete(`report-schedules/case/${props.caseModel.id}`)
      stores.cases.updateOpened({ id: props.caseModel.id, report_schedule: null })
    }
  } catch (error) {
    $message.error($t('api-error'))
  }
}

defineExpose({
  selectedClient,
})
</script>
