<template>
  <VPopover ref="popover" :placement="placement" trigger="hover">
    <div v-if="type === 'string'" style="margin-left: -20px">
      <ul>
        <li v-for="(item, index) in formattedItems" :key="index" v-text="item" />
      </ul>
    </div>

    <template #reference>
      <span ref="reference">
        <slot />
      </span>
    </template>
  </VPopover>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      default: 'string',
    },

    placement: {
      type: String,
      default: 'top',
    },
  },

  data: () => ({
    visible: false,
  }),

  computed: {
    formattedItems() {
      const formattedItems = []

      for (let item in this.items) {
        item = this.items[item]
        if (typeof item === 'object') {
          formattedItems.push(capitalize(item.name))
        } else {
          formattedItems.push(item)
        }
      }

      return formattedItems
    },
  },
})
</script>
