<template>
  <div class="view">
    <template v-if="oneTimeCaseLink">
      <div v-if="oneTimeCaseLink.app_branding_visible" class="header">
        <img :alt="$t('logo')" class="logo" src="/images/logo.svg" width="200" />
      </div>

      <VLoadingDots v-if="$stores.loading.case || $stores.loading.case" mask />
      <VSection v-if="store.case" show-back @back="back">
        <template #title>
          <CaseIcon style="margin-right: 10px" :type="store.case.type" />
          <h1>{{ store.case.name }}</h1>
        </template>

        <template #header-right>
          <VNavbarItem
            v-for="(label, resolution) in { unresolved: $t('unresolved'), positive: $t('included'), negative: $t('excluded') }"
            :key="resolution"
            :active="store.activeResolution === resolution"
            @click="store.activeResolution = resolution"
          >
            {{ label }}
          </VNavbarItem>
        </template>

        <CaseResolve @back="back" />
      </VSection>
    </template>
  </div>
</template>

<script setup lang="ts">
import { $caseStoreKey } from '~/injections.ts'
import { createCaseStore } from '~/stores/case.ts'

interface OneTimeCaseLink {
  id: number
  created_by_id: number
  user_id: number
  case_uuid: string
  return_url: string
  expires_at: string
  created_at: string
  updated_at: string
  require_hit_comments: boolean
  app_branding_visible: boolean
}

const store = createCaseStore('oneTimeCase')()

provide($caseStoreKey, {
  caseStore: store,
  sourceStores: {
    businessRegisters: store.businessRegisters,
    companyOfficers: store.companyOfficers,
    enforcements: store.enforcements,
    news: store.news,
    other: store.other,
    peps: store.peps,
    sanctions: store.sanctions,
  },
})

const oneTimeCaseLink = ref<OneTimeCaseLink>()
api.get<OneTimeCaseLink>('current-one-time-case-link').then(async ({ data }) => {
  oneTimeCaseLink.value = data
  stores.policies.policies.require_hit_comments = data.require_hit_comments
  const caseModel = await stores.cases.loadCaseFromUUID(data.case_uuid)
  if (caseModel) {
    store.loadCase(caseModel)
  }
})

function back() {
  if (!oneTimeCaseLink.value?.return_url) {
    window.close()
  }
  window.location.replace(oneTimeCaseLink.value?.return_url ?? '')
}
</script>

<style lang="scss" scoped>
.view {
  padding: 35px 7% 10px;
  background-color: #f5f6f7;
  height: 100vh;
}

.header {
  margin: 0% 0 65px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
