<template>
  <VButton :disabled="disabled" :help="help" style="flex-shrink: 0" type="secondary" @click="$emit('click', $event)">
    <VIcon background-type="white" :icon="icon" :size="14" style="margin: 0 7px 0 -7px" :type="disabled ? 'info' : 'black'" />
    {{ label }}
  </VButton>
</template>

<script lang="ts">
import { type PropType } from 'vue'

export const types = ['include', 'exclude', 'comment', 'update'] as const

export const icons: Record<string, string> = {
  include: 'Check',
  exclude: 'Close',
  comment: 'CommentEmpty',
  update: 'Check',
}

export default defineComponent({
  props: {
    type: {
      type: String as PropType<(typeof types)[number]>,
      required: true,
    },

    disabled: Boolean,

    help: {
      type: [String, Array] as PropType<string | string[]>,
      default: '',
    },
  },

  emits: ['click'],

  computed: {
    label() {
      return this.type === 'comment' ? this.$t('add-comment') : this.type === 'update' ? this.$t('accept-changes') : this.$t(this.type)
    },

    icon() {
      return icons[this.type]
    },
  },
})
</script>
