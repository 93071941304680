<template>
  <VLoadingDots v-if="!check" />
  <VTag v-else-if="matchedSources.length === 1" style="width: 70px; margin-left: 5px" type="red">
    {{ shortNames[matchedSources[0]] }}
  </VTag>
  <VTooltip v-else-if="matchedSources.length > 0" placement="top" tag="span" trigger="hover" type="light">
    <VTag style="width: 66px" type="red"> {{ $t('SEP') }} </VTag>
    <template #content>
      <span v-for="(formattedName, key) in formattedNames" :key="key" style="padding: 2px">
        <VTag v-if="matchedSources.includes(key)" type="red">{{ formattedName }}</VTag>
      </span>
    </template>
  </VTooltip>
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type Check, nameChecker } from '~/services/nameChecker.ts'
import { type CaseType } from '~/types.ts'

export default defineComponent({
  props: {
    value: {
      required: true,
      type: String,
    },

    type: {
      type: String as PropType<CaseType>,
      default: '',
    },
  },

  data: () => ({
    check: null as null | Check,
    shortNames: {
      sanctions: 'Sanction',
      enforcements: 'Enforce.',
      peps: 'PEP',
    },
    formattedNames: {
      sanctions: 'Sanction',
      enforcements: 'Enforcement',
      peps: 'PEP',
    },
  }),

  computed: {
    matchedSources(): (keyof Check)[] {
      if (!this.check) {
        return []
      }

      const filtered: Record<string, number> = {}

      let key: keyof Check
      for (key in this.check) {
        if (this.check[key] > 0) {
          filtered[key] = this.check[key]
        }
      }

      return Object.keys(filtered) as unknown as (keyof Check)[]
    },
  },

  async created() {
    this.check = await nameChecker.check(this.value, this.type)
  },
})
</script>
