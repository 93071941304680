import { AxiosError } from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'

import type { BillingSettings } from '~/types.ts'

export const useBillingSettings = defineStore('billingSettings', {
  state: () => ({
    validTaxId: false,
    countryTaxes: {} as Record<string, string>,
    billingSettings: {
      id: 0,
      organization_id: 0,
      address: '',
      email_recipient_roles: ['Owner', 'Billing manager'],
      email_recipient_addresses: [],
      city: '',
      country: '',
      created_at: '',
      credits_notification: false,
      order_day_of_month: 1,
      postal_code: '',
      tax_id: '',
      threshold: 0,
      threshold_notification: false,
      updated_at: '',
    } as BillingSettings,
  }),

  getters: {
    hasSettings: (state) => state.billingSettings.id > 0,
  },

  actions: {
    async fetch(organizationId: number) {
      try {
        const { data } = await api.post(
          'billing-settings/searches',
          { organization_id: [organizationId] },
          {
            headers: {
              OrganizationId: organizationId,
            },
          },
        )

        this.billingSettings = data

        if (data.tax_id) {
          this.validTaxId = true
        }
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 404) {
          // Organization has no billing settings.
        } else {
          throw error
        }
      }

      if (Object.keys(this.countryTaxes).length === 0) {
        this.fetchCountryTaxes()
      }
    },

    async save() {
      const { data } = await api.post<BillingSettings>('billing-settings', this.billingSettings)
      Object.assign(this.billingSettings, data)
    },

    async patch(payload?: Partial<BillingSettings>) {
      const { data } = await api.patch<BillingSettings>(`billing-settings/${this.billingSettings.id}`, payload ?? this.billingSettings)
      this.billingSettings = data
      this.validTaxId = !!this.billingSettings.tax_id
    },

    async fetchCountryTaxes() {
      const { data } = await api.get('country-taxes')
      this.countryTaxes = data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBillingSettings, import.meta.hot))
}
