<template>
  <div>
    {{ address }}
    <div v-if="Object.keys(location).length > 0" :style="{ height }">
      <Mapbox :options="{ center, zoom }" @load="onMapLoad" />
    </div>
    <div class="map-address">
      <span v-if="text" style="display: inline-block; padding-right: 10px" :text="text" />
      <FieldNationality v-if="country" :highlight-target="highlightTarget" style="display: inline-block; padding-right: 10px" :value="country['alpha-2']" />
    </div>
  </div>
</template>

<script lang="ts">
import { bbox } from '@turf/bbox'
import { circle } from '@turf/circle'

import { createPulsingDot } from '~/helpers/mapbox.ts'

const confidenceRadius = {
  10: 0.25,
  9: 0.5,
  8: 1,
  7: 5,
  6: 7.5,
  5: 10,
  4: 15,
  3: 20,
  2: 25,
  1: 50,
}

export default defineComponent({
  props: {
    address: {
      required: true,
      type: Object,
    },

    height: {
      type: String,
      default: '400px',
    },

    highlightTarget: {
      type: [Array, String],
      default: '',
    },
  },

  emits: { missingGeocode: null },

  data: () => ({
    map: {},
    location: {},
    zoom: 15,
  }),

  computed: {
    center: (vm) => [
      vm.location.geometry ? vm.location.geometry?.lng ?? vm.location.geometry?.lon ?? vm.location.geometry[0] : 0,
      vm.location.geometry ? vm.location.geometry?.lat ?? vm.location.geometry[1] : 0,
    ],

    country: (vm) => stores.countries.country(vm.address.country_code ?? vm.address.country ?? vm.address.formatted),
    text: (vm) => vm.address.text ?? vm.address.place,
  },

  async mounted() {
    if (this.address.geometry) {
      const location = { geometry: this.address.geometry, type: this.address.geo_type, country: this.country, confidence: this.address.confidence }

      this.location = location

      return
    }

    if (this.text) {
      try {
        this.location = await stores.locations.search(this.text)

        return
      } catch (error) {
        console.error(error)
      }
    }

    if (this.country && Object.keys(this.location).length === 0) {
      this.location = { country: this.country }
    }

    this.$emit('missingGeocode')
  },

  methods: {
    formatKeyValue,

    onMapLoad(map) {
      this.map = map

      if (Object.keys(this.location).length === 0) {
        return
      }

      if (this.location.confidence) {
        if (this.location.confidence === 10) {
          const coordinates = createPulsingDot({ map, coordinates: this.center })
          this.map.fitBounds(bbox(coordinates), { padding: 1000 })

          return
        }

        this.addLayer(circle(this.center, confidenceRadius[this.location.confidence]))

        return
      }

      if (this.location.type === 'Point Address') {
        const coordinates = createPulsingDot({ map, coordinates: this.center })
        this.map.fitBounds(bbox(coordinates), { padding: 1000 })

        return
      }

      if (['Address Range', 'Cross Street'].includes(this.location.type)) {
        this.addLayer(circle(this.center, 0.25))

        return
      }

      if (['Street', 'POI'].includes(this.location.type)) {
        this.addLayer(circle(this.center, 0.5))

        return
      }

      if (this.location.type === 'Geography') {
        if (this.country) {
          this.addCountryLayer(this.country)
        } else {
          this.addLayer(circle(this.center, 100))
        }

        return
      }

      if (this.country) {
        this.addCountryLayer(this.country)

        return
      }

      this.addLayer(circle(this.center, 500))
    },

    async addCountryLayer(country) {
      await stores.countries.loadGeoJSON()
      this.map.setZoom(0)
      const features = stores.countries.searchGeoJSON(country.name)

      if (features.length > 0) {
        this.addLayer({ type: 'FeatureCollection', features })
      }
    },

    addLayer(data) {
      this.map.addLayer({
        id: 'circle',
        type: 'fill',
        source: { type: 'geojson', data },
        layout: {},
        paint: { 'fill-color': '#0017a8', 'fill-opacity': 0.5 },
      })

      this.map.fitBounds(bbox(data), { padding: 100 })
    },
  },
})
</script>

<style lang="scss" scoped>
.map-address {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  top: 0;
  border-bottom-right-radius: 5px;
}
</style>
