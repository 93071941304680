<template>
  <Teleport v-if="visible" to="body">
    <div class="context-menu-backdrop" @contextmenu="close">
      <div ref="popper" class="context-menu" :style="{ left, top }" tabindex="-1" @click="close" @contextmenu.capture.prevent>
        <slot :data="contextData" />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
const popper = ref<InstanceType<typeof HTMLDivElement>>()
const visible = ref(false)
const contextData = ref<unknown>(null)
const left = ref('0px')
const top = ref('0px')

onBeforeUnmount(() => {
  document.removeEventListener('mousedown', onMousedown)
})

function open(event: MouseEvent, newContextData: unknown) {
  document.addEventListener('mousedown', onMousedown)
  visible.value = true
  contextData.value = newContextData
  left.value = `${event.clientX}px`
  top.value = `${event.clientY}px`
}

function close() {
  document.removeEventListener('mousedown', onMousedown)
  if (!visible.value) return
  visible.value = false
  contextData.value = null
}

function onMousedown(event: MouseEvent) {
  if (popper.value && !popper.value.contains(event.target as HTMLElement)) {
    close()
  }
}

defineExpose({ open })
</script>
