<template>
  <VDialog ref="dialog" :placement="placement" :title="title" :visible="visible" :width="width" @update:visible="$emit('update:visible', false)">
    <VForm ref="vform" :errors="localErrors" :form="form" :rules="rules" @submit="$emit('submit')" @update:errors="updateErrors">
      <VLoadingDots v-if="loading" mask />
      <slot />
    </VForm>
    <template v-if="!hideFooter" #footer>
      <slot name="footer">
        <VButton type="secondary" @click="$emit('update:visible', false)">{{ cancelText ? cancelText : $t('cancel') }}</VButton>
        <VButton :loading="loading" @click="submit">{{ submitText ? submitText : $t('apply') }}</VButton>
      </slot>
    </template>
  </VDialog>
</template>

<script setup lang="ts">
import type { VDialogPlacement, VFormRule } from '@vartion/ui'
import { VForm } from '@vartion/ui'

interface Props {
  visible?: boolean
  loading?: boolean
  hideFooter?: boolean
  title?: string
  width?: string
  placement?: VDialogPlacement
  form?: Record<string, unknown>
  rules?: Record<string, VFormRule>
  errors?: Record<string, string[]>
  submitText?: string
  cancelText?: string
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  hideFooter: false,
  title: '',
  width: '40%',
  placement: 'right',
  form: () => ({}),
  rules: () => ({}),
  errors: undefined,
  submitText: '',
  cancelText: '',
})

const emit = defineEmits<{
  submit: []
  'update:visible': [boolean]
  'update:errors': [Record<string, string[]>]
}>()

const vform = ref<InstanceType<typeof VForm>>()
const localErrors = ref<Record<string, string[]>>({})

function updateErrors(errors: Record<string, string[]>) {
  localErrors.value = errors
  emit('update:errors', errors)
}

watchEffect(() => {
  if (!props.visible) {
    updateErrors({})
  } else if (props.errors) {
    localErrors.value = props.errors
  }
})

function validate() {
  vform.value?.validate()
}

function submit() {
  vform.value?.submit()
}

defineExpose({
  form: vform,
  validate,
  submit,
})
</script>
