<template>
  <div v-if="Object.keys(filters).length > 0" class="filters">
    <VCard>
      <VRow :gutter="10">
        <VCol :md="8">
          <VInput clearable :model-value="filters.name" :placeholder="$t('name')" @update:model-value="updateFilter({ key: 'name', value: $event })" />
        </VCol>

        <VCol :md="8">
          <VDatePicker
            clearable
            is-range
            :model-value="filters.created_at"
            :quick-options="quickOptions"
            :start-placeholder="$t('requested-at')"
            @update:model-value="updateFilter({ key: 'created_at', value: $event })"
          />
        </VCol>

        <VCol :md="8">
          <VSelect clearable :model-value="filters.type" multiple :placeholder="$t('type')" @update:model-value="updateFilter({ key: 'type', value: $event })">
            <VOption v-for="(label, type) in types" :key="type" :label="label" :value="type" />
          </VSelect>
        </VCol>
      </VRow>
    </VCard>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    filters: {
      type: Object,
      required: true,
    },

    types: {
      type: Object,
      required: true,
    },
  },

  emits: ['set', 'change'],

  data: () => ({
    names: [],
    quickOptions: useDateOptions(),
  }),

  created() {
    this.setFilters()
  },

  methods: {
    setFilters() {
      this.$emit('set', {
        name: '',
        created_at: [],
        type: [],
        organization_id: [stores.organization.id],
      })
    },

    updateFilter({ key, value, fetch = true }: { key: string; value: any; fetch?: boolean }) {
      this.$emit('change', { key, value, shouldFetch: fetch })
    },
  },
})
</script>
