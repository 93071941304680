<template>
  <div style="width: 550px; height: 280px; overflow: hidden">
    <h1 class="matrix-title">{{ $t('risk-classification-matrix') }}</h1>

    <VRow :gutter="10" style="height: 200px; margin-top: 60px">
      <VCol class="probability-label" :span="2">{{ $t('probability') }}</VCol>

      <VCol :span="22">
        <VTable cell-padding="0" :data="tableData" layout="fixed">
          <VTableColumn v-slot="{ row }" label="" prop="probability">
            <div class="left-header-cell" v-text="row.probability" />
          </VTableColumn>

          <VTableColumn v-for="impactField in impact" :key="impactField" v-slot="{ row }" :label="impactField">
            <div
              class="colored-cell"
              :style="riskColor(row.threats[impact.indexOf(impactField)], impactField, row.probability)"
              v-text="row.threats[impact.indexOf(impactField)]"
            />
          </VTableColumn>
        </VTable>
      </VCol>
    </VRow>
    <div class="impact-label">{{ $t('impact') }}</div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    risk: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    probabilities: ['Very high', 'High', 'Moderate', 'Low', 'Very low'],
    impact: ['Very low', 'Low', 'Moderate', 'High', 'Very High'],
    probabilityOrder: ['very high', 'high', 'moderate', 'low', 'very low'],
    threats: [
      [0.05, 0.09, 0.18, 0.36, 0.72],
      [0.04, 0.07, 0.14, 0.28, 0.56],
      [0.03, 0.05, 0.1, 0.2, 0.4],
      [0.02, 0.03, 0.06, 0.12, 0.24],
      [0.01, 0.01, 0.02, 0.04, 0.08],
    ],
  }),

  computed: {
    tableData() {
      const data = []
      for (const probabilityField in this.probabilities) {
        const item = {
          probability: this.probabilities[probabilityField],
        }
        item.threats = this.threats[probabilityField]

        data.push(item)
      }

      return data
    },
  },

  methods: {
    riskColor(riskScore, impact, probability) {
      const style = { color: 'white' }
      if (riskScore > 0.24) {
        style.backgroundColor = '#B11B1B'
      } else if (riskScore > 0.12) {
        style.backgroundColor = '#D62121'
      } else if (riskScore > 0.06) {
        style.backgroundColor = '#FA6636'
      } else if (riskScore > 0.03) {
        style.backgroundColor = '#3B923D'
      } else if (riskScore <= 0.03) {
        style.backgroundColor = '#4DB04F'
      }

      if (impact.toLowerCase() === this.risk.impact && probability.toLowerCase() === this.risk.probability) {
        style.borderStyle = 'solid'
        style.borderWidth = '3px'
        style.borderColor = this.adjust(style.backgroundColor, -25)
        style.lineHeight = '24px'
      }
      if (impact.toLowerCase() !== this.risk.impact || this.probabilityOrder.indexOf(this.risk.minSetProbability) < this.probabilityOrder.indexOf(probability.toLowerCase())) {
        style.opacity = 0.3
      }

      return style
    },

    adjust(hexInput, percent) {
      let hex = hexInput

      // strip the leading # if it's there
      hex = hex.replace(/^\s*#|\s*$/g, '')

      // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
      if (hex.length === 3) {
        hex = hex.replace(/(.)/g, '$1$1')
      }

      let r = parseInt(hex.substr(0, 2), 16)
      let g = parseInt(hex.substr(2, 2), 16)
      let b = parseInt(hex.substr(4, 2), 16)

      const calculatedPercent = (100 + percent) / 100

      r = Math.round(Math.min(255, Math.max(0, r * calculatedPercent)))
      g = Math.round(Math.min(255, Math.max(0, g * calculatedPercent)))
      b = Math.round(Math.min(255, Math.max(0, b * calculatedPercent)))

      return `#${r.toString(16).toUpperCase()}${g.toString(16).toUpperCase()}${b.toString(16).toUpperCase()}`
    },
  },
})
</script>

<style lang="scss" scoped>
.probability-label {
  font-weight: 500;
  font-size: 20px;
  color: #999999;
  padding: 120px 0;
  transform: rotate(-90deg);
  white-space: nowrap;
}

.impact-label {
  font-weight: 500;
  font-size: 20px;
  color: #999999;
  text-align: center;
}

.colored-cell {
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.matrix-title {
  position: absolute;
  overflow: hidden;
  margin-top: 20px;
  width: 550px;
  text-align: center;
}

.left-header-cell {
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 30px;
  color: #585858;
}

.v-table :deep() {
  th {
    padding: 0px;
    height: 30px;
    border: 1px solid #eeeeee;
    text-align: center;
    font-size: 16px;
    color: #585858;
  }

  td {
    padding: 0px;
    height: 30px;
    border: 1px solid #eeeeee;
    line-height: 30px;
    text-align: center;
    box-sizing: border-box;
  }
}
</style>
