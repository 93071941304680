import { APP_ROLES } from '~/stores/roles.ts'
import { type Role, type User } from '~/types.ts'

const permissions = computed(() => {
  return getPermissions(stores.user.user.roles)
})

function getPermissions(roles: Role[]) {
  const names = new Set<string>()

  for (const role of roles) {
    for (const permission of role.permissions) {
      names.add(permission.name)
    }
  }

  return names
}

export function can(permissionName: string, user?: User) {
  if (!user) {
    return permissions.value.has(permissionName)
  }

  return getPermissions(user.roles).has(permissionName)
}

export function hasRole(roleName: string) {
  return stores.user.user.roles.some((role) => role.name === roleName)
}
