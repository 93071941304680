<template>
  <div class="field-max-height">
    <div v-for="(ubo, index) in value" :key="index">
      <VTextHighlight v-if="ubo.ultimate_beneficial_owner.name" :queries="highlightTarget" :text="ubo.ultimate_beneficial_owner.name" />
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    value: {
      required: true,
      type: Array,
    },

    highlightTarget: {
      type: [Array, String],
      default: '',
    },
  },
})
</script>

<style lang="scss" scoped>
div > .match-tag {
  margin-right: 8px;
  color: var(--color-text-primary);
  border-color: var(--color-text-primary);
}

div:not(:last-child) > .match-tag {
  margin-bottom: 3px;
}
</style>
