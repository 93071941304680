<template>
  <div v-if="formatted[0].type || formatted[0].name" style="overflow: hidden">
    <CaseIcon v-if="formatted[0].type" style="margin-right: 5px" :type="formatted[0].type" />
    <SearchLink v-if="formatted[0].name" style="overflow: hidden" :text="capitalize(formatted[0].name)" :type="formatted[0].type" />
  </div>
</template>

<script lang="ts">
const typeMap = {
  business: 'Business',
  entity: 'Business',
  individual: 'Person',
  person: 'Person',
}

export default defineComponent({
  props: {
    connections: {
      required: true,
      type: Array,
    },
  },

  computed: {
    formatted: (vm) =>
      vm.connections.map((connection) => ({
        ...connection,
        end_date: formatDate(connection.listed_until, vm.$t('unknown')),
        type: vm.type(connection.type.value),
      })),
  },

  methods: {
    capitalize,

    type(value) {
      if (!value) {
        return ''
      }

      if (typeof value === 'object' && 'value' in (value ?? {})) {
        value = value.value
      }

      value = value.toLowerCase()

      if (value in typeMap) {
        return typeMap[value]
      }

      return ''
    },
  },
})
</script>
