<template>
  <VTable v-if="items.length > 0" :data="tableData" :pagination="{ pageSize: 5 }" row-key="value">
    <VTableColumn v-slot="{ row }" :label="$t('name')" prop="value" show-overflow-tooltip sortable width="300">
      <PopoverTranslation v-if="field === 'persons'" :text="row.value" transliterate>
        <MatchTag v-if="row?.matched" class="match-tag" :value="row.value" />
        <SearchLink
          :form="createForm(row)"
          :highlight-target="highlightTarget"
          :searched-name="searchedName"
          :text="row.value"
          :title="$t('perform-a-person-check')"
          type="Person"
        />
      </PopoverTranslation>

      <PopoverTranslation v-if="field === 'organizations'" :text="row.value" transliterate>
        <MatchTag v-if="row?.matched" class="match-tag" :value="row.value" />
        <SearchLink :highlight-target="highlightTarget" :searched-name="searchedName" :text="row.value" :title="$t('perform-a-business-check')" type="Business" />
      </PopoverTranslation>

      <template v-else-if="field === 'locations'">
        <MatchTag v-if="row?.matched" class="match-tag" :value="row.value" />
        <CountryLink v-if="isCountry(row.value)" :highlight-target="highlightTarget" :text="row.value" />
        <VTextHighlight v-else :queries="highlightTarget" :text="row.value" />
      </template>
    </VTableColumn>

    <VTableColumn v-slot="{ row }" :label="$t('occurrence')" prop="coverage" sortable>
      {{ row.occurrence }}
    </VTableColumn>

    <VTableColumn v-slot="{ row }" max-width="66" prop="value" show-overflow-tooltip>
      <FieldCheckedName v-if="field !== 'locations'" :type="type" :value="row.value" />
    </VTableColumn>
  </VTable>
  <FieldEmpty v-else />
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type NERType } from '~/types.ts'

const fieldToType = {
  organizations: 'Business',
  persons: 'Person',
  locations: 'Location',
} as const

export default defineComponent({
  props: {
    field: {
      type: String as PropType<keyof typeof fieldToType>,
      required: true,
    },

    items: {
      type: Array as PropType<NERType[]>,
      required: true,
    },

    highlightTarget: {
      type: [Array, String],
      default: '',
    },

    searchedName: {
      type: String,
      default: '',
    },

    elasticHighlights: {
      type: Object as PropType<Record<string, string[]>>,
      default: () => ({}),
    },
  },

  data: () => ({
    dialogCountryInformationVisible: false,
  }),

  computed: {
    type() {
      return fieldToType[this.field]
    },

    formattedItems() {
      const lowThreshold = stores.initialState.newsCoverageMultiplier / 3

      const formatted = []

      for (const item of copyObject(this.items)) {
        if (!item.value) continue

        if (item.coverage < lowThreshold) {
          item.occurrence = this.$t('low')
        } else if (item.coverage < lowThreshold * 2) {
          item.occurrence = this.$t('moderate')
        } else {
          item.occurrence = this.$t('high')
        }

        formatted.push(item)
      }

      return formatted
    },

    tableData() {
      let filteredItems = this.formattedItems.slice()
      let matchedItems: NERType[] = []

      if (`${this.field}.value` in this.elasticHighlights) {
        const matchedFields = this.elasticHighlights[`${this.field}.value`].map((highlight) => this.removeTokens(highlight.toLowerCase()))
        matchedItems = filteredItems.filter((item) => matchedFields.includes(this.removeTokens(item.value.toLowerCase()))).map((obj) => ({ ...obj, matched: true }))
        filteredItems = filteredItems.filter((item) => !matchedFields.includes(this.removeTokens(item.value.toLowerCase())))
      }

      return [...sortArrayByObjectKey({ array: matchedItems, key: 'count', order: 'desc' }), ...sortArrayByObjectKey({ array: filteredItems, key: 'count', order: 'desc' })]
    },
  },

  methods: {
    removeTokens(string: string) {
      return string.replaceAll('<em>', '').replaceAll('</em>', '').replaceAll('.', '').replaceAll(',', '').replaceAll('-', '')
    },

    isCountry(value: string) {
      return stores.countries.countryName(value) !== null
    },

    createForm(item: NERType) {
      return {
        name: this.searchedName,
        gender: capitalizeFirst(item.GENDER ?? ''),
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.match-tag {
  margin-right: 8px;
  color: var(--color-text-primary);
  border-color: var(--color-text-primary);
}
</style>
