<template>
  <span :class="relative ? 'help' : null" :title="title">
    <div v-if="label" style="font-weight: 500" v-text="label" />
    <template v-if="date">
      <VTextHighlight v-if="highlightTarget && formatted" :queries="highlightTarget" :text="formatted" />
      <span v-else :title="title">{{ formatted }}</span>
      <span v-if="age"> ({{ $t('age') }}: {{ $dayjs().diff(date, 'years') }})</span>
    </template>
    <FieldEmpty v-else />
  </span>
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type HighlightTarget } from '~/types.ts'

export default defineComponent({
  props: {
    value: {
      type: [String, Number] as PropType<string | number | null>,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },

    age: {
      type: Boolean,
      default: false,
    },

    highlightTarget: {
      type: [String, Array] as PropType<HighlightTarget>,
      default: '',
    },

    relative: {
      type: Boolean,
      default: false,
    },

    past: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    tick: 0,
    interval: undefined as undefined | number,
  }),

  computed: {
    dateFormat() {
      return stores.settings.settings.date_format ?? 'YYYY-MM-DD'
    },

    isYear() {
      return typeof this.value === 'string' && this.value.length === 4
    },

    title() {
      return this.relative ? this.date?.format(`${this.dateFormat} HH:mm:ss`) : undefined
    },

    date() {
      const date = this.createDate()

      if (date === null) return null

      if (this.past) {
        return dayjs.min(date, dayjs())
      }

      return date
    },

    formatted() {
      this.tick
      const date = this.date

      if (date === null) return null

      if (this.relative) {
        return date.fromNow()
      }

      if (this.isYear) {
        return date.format('YYYY')
      }

      return date.format(this.dateFormat)
    },
  },

  mounted() {
    if (this.relative) {
      this.sync()
    }
  },

  beforeUnmount() {
    clearInterval(this.interval)
  },

  methods: {
    createDate() {
      if (!this.value) {
        return null
      }

      if (this.isYear) {
        return dayjs(this.value, 'YYYY')
      } else if (typeof this.value === 'number') {
        return dayjs(this.value * 1000) // seconds to milliseconds
      }

      const date = dayjs(this.value)

      // when the value is a date without timestamp
      if (!this.value.includes('T')) {
        date.utc(true)

        return date
      }

      // when the value doesn't contain timezone information
      if (!this.value.includes('+') && !this.value.includes('Z')) {
        date.utc(true)
      }

      return date
    },

    sync() {
      this.interval = window.setInterval(() => this.tick++, 60000)
    },
  },
})
</script>

<style lang="scss" scoped>
.help {
  cursor: help;
}
</style>
