<template>
  <div>
    <CountryFlagIcon v-if="value.country_code" :value="value.country_code" />
    <template v-if="value.type">{{ value.type }}: </template>
    <template v-if="value.value">{{ value.value }}</template>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    value: {
      required: true,
      type: Object,
    },
  },
})
</script>
