<template>
  <div class="field-max-height">
    <div v-for="(alias, index) in aliases" :key="index">
      <MatchTag v-if="alias?.matched" :value="alias.name" />
      <template v-if="alias.type">{{ alias.type }}: </template>
      <VTextHighlight v-if="alias.name" :queries="highlightTarget" :text="alias.name" />
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: () => [],
    },

    highlightTarget: {
      type: [Array, String],
      default: '',
    },

    elasticHighlights: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    aliases() {
      if ('aliases.name' in this.elasticHighlights) {
        const matchedFields = this.elasticHighlights['aliases.name'].map((highlight) => this.removeTokens(highlight.toLowerCase()))

        return [
          ...this.value.filter((item) => matchedFields.includes(this.removeTokens(item.name.toLowerCase()))).map((obj) => ({ ...obj, matched: true })),
          ...this.value.filter((item) => !matchedFields.includes(this.removeTokens(item.name.toLowerCase()))),
        ]
      }

      return this.value
    },
  },

  methods: {
    removeTokens(items) {
      return items.replaceAll('<em>', '').replaceAll('</em>', '').replaceAll('.', '').replaceAll(',', '').replaceAll('-', '')
    },
  },
})
</script>
