<template>
  <VPopover ref="popover" :placement="placement" trigger="manual" @popper-mouseenter="hovering = true" @popper-mouseleave="hide(200)">
    <ClipboardLink>
      <div v-for="(line, index) in translation.split('\n')" :key="index">{{ line }}</div>
    </ClipboardLink>

    <template #reference>
      <span ref="reference" @mouseleave="hide(200)" @mousemove="mouseMove">
        <slot />
      </span>
    </template>
  </VPopover>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },

    transliterate: {
      type: Boolean,
      default: false,
    },

    translationTrigger: {
      type: String,
      default: 'hover',
    },

    placement: {
      type: String,
      default: 'top',
    },
  },

  data: () => ({
    translation: '',
    hovering: false,
    visible: false,
    mouseMoveTimeout: 0,
  }),

  computed: {
    language() {
      return stores.settings.settings.translation_language
    },

    knownLanguages() {
      return stores.settings.settings.known_languages ?? []
    },

    knownScripts() {
      return this.knownLanguages.map((code) => stores.translation.scripts[code])
    },
  },

  watch: {
    language() {
      this.translation = ''
    },
  },

  created() {
    if (this.translationTrigger === 'created') {
      this.translate()
    }
  },

  beforeUnmount() {
    window.clearTimeout(this.mouseMoveTimeout)
  },

  methods: {
    mouseMove(event: MouseEvent) {
      // Popover should not be triggered when hovering over highlighted text
      window.clearTimeout(this.mouseMoveTimeout)
      this.hovering = true
      this.mouseMoveTimeout = window.setTimeout(() => {
        if (!this.hovering) {
          return
        }

        let element = event.target as HTMLElement | null
        while (element) {
          // Don't check elements that are parents of the reference element
          if (element === this.$refs.reference) {
            break
          }

          if (element.classList && element.classList.contains('text__highlight')) {
            return
          }

          element = element.parentElement
        }

        this.translate()
      }, 200)
    },

    show() {
      if (this.language && this.translation !== this.text && this.hovering) {
        this.$refs.popover?.show()
      }
    },

    async hide(delayDuration: number) {
      this.hovering = false
      await delay(delayDuration)
      if (!this.hovering) {
        this.$refs.popover?.hide()
      }
    },

    async translate() {
      if (this.translation) {
        this.show()

        return
      }

      this.translation = this.$t('detecting-language')
      const shouldTranslatePromise = this.shouldTranslate()
      // User must hover at least 400ms before translation request is send
      await delay(400)

      this.show()

      if (!this.hovering) {
        this.translation = ''
        this.hide(0)

        return
      }

      if (!(await shouldTranslatePromise)) {
        await this.hide(0)
        await delay(200)
        this.translation = this.text

        return
      }

      this.translation = this.$t('translating')
      const { translation } = await stores.translation.translate({ text: this.text })

      if (translation?.translation) {
        this.showTranslation(translation.translation)
      } else {
        this.translation = ''
        this.hide(0)
      }
    },

    showTranslation(translation: string) {
      if (!this.hovering) {
        this.translation = translation

        return
      }

      this.translation = translation
      this.show()
    },

    async shouldTranslate() {
      if (this.transliterate) {
        await stores.translation.loadScripts()
        const detected = await stores.translation.detectScript({ text: this.text })

        return !this.knownScripts.includes(detected)
      }

      const detected = await stores.translation.detectLanguage({ text: this.text })

      return !this.knownLanguages.includes(detected)
    },
  },
})
</script>
