<template>
  <VTextHighlight v-if="highlightTarget && gender" :queries="highlightTarget" :text="gender" />
  <div v-else-if="gender">{{ gender }}</div>
  <FieldEmpty v-else />
</template>

<script setup lang="ts">
import type { HighlightTarget } from '~/types.ts'

interface Props {
  value: string | { value: string }
  highlightTarget?: HighlightTarget
}

const props = defineProps<Props>()

const gender = computed(() => (typeof props.value === 'string' ? props.value : props.value.value))
</script>
