<template>
  <div>
    <div v-for="(item, index) in value" :key="index" class="field-address" :class="{ link: !!$user.id }" @click="selectItem(item)">
      <VTextHighlight
        v-if="item.text || item.place || item.country || item.country_code || item.formatted"
        :queries="highlightTarget"
        style="display: inline-block; margin-right: 10px"
        :text="text(item)"
      />
      <VButton v-if="$user.id" icon="Pin" rounded size="small" type="icon" />
    </div>

    <VDialog v-if="selected" class="map-dialog" placement="fullscreen" :visible="selected !== null" @update:visible="selected = null">
      <FieldAddress :address="selected" height="100vh" :highlight-target="highlightTarget" style="margin: -60px" @missing-geocode="selected = null" />
    </VDialog>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    value: {
      type: Array,
      required: true,
    },

    highlightTarget: {
      type: [Array, String],
      default: '',
    },
  },

  data: () => ({
    selected: null,
  }),

  methods: {
    toCountry(code) {
      return stores.countries.countriesByAlpha2Code[code.toLowerCase()]?.name ?? ''
    },

    text(item) {
      return item.text
        ? item.text
        : item.place
          ? item.place
          : item.country_code
            ? this.toCountry(item.country_code)
            : item.country
              ? item.country
              : item.formatted
                ? item.formatted
                : ''
    },

    selectItem(item) {
      if (!this.$user.id) {
        return
      }

      this.selected = item
    },
  },
})
</script>

<style lang="scss" scoped>
.field-address {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: var(--border-light);
  }
}
</style>
