<template>
  <VCard v-if="empty" :title="title">
    <SourceEmpty />
  </VCard>

  <div v-if="!empty">
    <div style="text-align: right">
      <FilterButton v-model="caseStore.showFilters" :active="nFiltersActive > 0" />
    </div>

    <SourceFilters
      v-if="!empty"
      v-show="caseStore.showFilters"
      :filtered-hits="filteredHits"
      :filtered-names="filteredNames"
      :hits="hits"
      :matched-names="matchedNames"
      source="news"
      @update:filtered-names="filteredNames = $event"
    >
      <template #custom-filter>
        <SourceFiltersNews
          :filtered-hits="filteredHits"
          :filtered-names="filteredNames"
          :filtered-table-data="customFilteredTableData"
          :hits="hits"
          :source-filters="sourceFilters"
          @update:source-filters="updateSourceFilters($event)"
        />
      </template>
    </SourceFilters>
  </div>

  <VCard v-if="!empty" :title="title">
    <template #header-left>
      <div v-if="nFiltersActive" style="margin-top: 4px">
        {{ nFiltersActive }} {{ $t('filters-active', nFiltersActive) }}
        <VButton icon="Close" plain size="small" style="margin-left: 5px" type="primary" @click="clearAllFilters">{{ $t('clear-all') }}</VButton>
      </div>
    </template>

    <SourceTable source="news" v-bind="customSourceTableProps" @expand-open="loadNer">
      <template #expanded-row-start="{ row }">
        <NewsExpand :form="form" :value="row" />
      </template>
    </SourceTable>

    <ContextMenu ref="contextMenu" v-slot="{ data }">
      <ul>
        <li v-for="item in contextMenuItems" :key="item" @click="contextMenuClick(item, data)">{{ item }}</li>
      </ul>
    </ContextMenu>

    <ActionBarResolve v-model:selection="selection" />
  </VCard>
</template>

<script setup lang="ts">
import ContextMenu from '~/components/ContextMenu.vue'
import SourceTable from '~/components/SourceTable.vue'
import { type SourceProps, useSource } from '~/composables/source.ts'
import { type HitData } from '~/types.ts'

const props = withDefaults(defineProps<SourceProps>(), {
  form: () => ({}),
  tableSize: 20,
})

const contextMenu = ref<InstanceType<typeof ContextMenu>>()
const sourceTable = ref<InstanceType<typeof SourceTable>>()

const {
  caseStore,
  clearAllFilters,
  contextMenuClick,
  contextMenuItems,
  empty,
  filteredHits,
  filteredNames,
  hits,
  loadNer,
  nFiltersActive,
  selection,
  sourceFilters,
  sourceTableProps,
  title,
  updateSourceFilters,
} = useSource(props, contextMenu, sourceTable, 'news', ['expand', 'selection', 'similar', 'title', 'publish_date', 'source', 'risk', 'confidence', 'resolve', 'comments'])

const customSourceTableProps = computed(() => {
  return { ...sourceTableProps.value, data: deduplicatedTableData.value }
})

const customFilteredTableData = computed(() => {
  return chartFilterHits(filteredHits.value)
})

const deduplicatedTableData = computed(() => {
  return customFilteredTableData.value.filter(({ id }) => !caseStore.news.similarHitIds.has(id))
})

const matchedNames = computed<string[]>(() => {
  const names: Record<string, { name: string; occurrence: number }> = {}

  const chartFilteredHitIds = chartFilterHits(hits.value.map((hit) => hit.data)).map((hit: HitData) => hit.id)
  for (const hit of hits.value.map((hit) => hit.data)) {
    if (caseStore.news.similarHitIds.has(hit.id)) {
      continue
    }

    for (const matches of Object.values(hit.highlight ?? {})) {
      for (const match of matches) {
        const name = match.replaceAll('<em>', '').replaceAll('</em>', '') as string
        if (chartFilteredHitIds.includes(hit.id) || filteredNames.value.includes(name.toLowerCase())) {
          if (names[name.toLowerCase()]) {
            names[name.toLowerCase()].occurrence++
          } else {
            names[name.toLowerCase()] = { name, occurrence: 1 }
          }
        }
      }
    }
  }

  return Object.values(names)
    .sort((a, b) => a.name.length - b.name.length)
    .sort((a, b) => b.occurrence - a.occurrence)
    .map((name) => name.name)
})

function chartFilterHits(hits: HitData[]) {
  // Filter on industries.
  if (sourceFilters.value.news?.industries) {
    const industriesFilter = sourceFilters.value.news.industries
    hits = hits.filter(({ industries }) => industries && industries.includes(industriesFilter))
  }

  // Filter on adverse.
  const adverseFilter = sourceFilters.value.news?.adverse
  if (adverseFilter) {
    hits = hits.filter((hit) => hit.adverse && adverseFilter in hit.adverse)
  }

  // Filter on year.
  if (sourceFilters.value.news?.year) {
    if (sourceFilters.value.news.year === 'Missing') {
      hits = hits.filter((hit) => !hit.publish_date)
    } else {
      hits = hits.filter((hit) => hit.publish_date && sourceFilters.value.news.year === hit.publish_date.substring(0, 4))
    }
  }

  return hits
}
</script>

<style scoped>
:deep() .form-item .form-label {
  margin-bottom: 5px;
}

.filter-card {
  margin-bottom: 15px;
}

.filter-card :deep() .v-card__body {
  padding-top: 25px;
  padding-bottom: 20px;
}
</style>
