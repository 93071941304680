import type { FeatureCollection } from 'geojson'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { keyedBy } from '~/helpers/keyedBy.ts'
import { axios } from '~/services/axios.ts'
import { type CountryInformation } from '~/types.ts'
import countriesJSON from '../../storage/app/countries.json'

const countries: Record<string, Country> = sortObjectByKeys(countriesJSON)

export interface Jurisdiction {
  code: string
  name: string
  full_name: string
  country: Country
}

export interface Country {
  name: string
  'alpha-2': string
  'alpha-3': string
  aliases: string[]
  jurisdictions: Record<string, Omit<Jurisdiction, 'country'>>
  capital: string
  'passport-code'?: string
}

function createJurisdictions(countries: Record<string, Country>) {
  const jurisdictions: Record<string, Jurisdiction> = {}

  for (const country of Object.values(countries)) {
    if ('jurisdictions' in country) {
      for (const jurisdiction of Object.values(country.jurisdictions)) {
        jurisdictions[jurisdiction.code] = { ...jurisdiction, country }
      }
    }
  }

  return jurisdictions
}

const jurisdictions = createJurisdictions(countries)

function countriesByAlpha2Code(countries: Record<string, Country>) {
  return Object.values(countries).reduce<Record<string, Country>>((acc, country) => {
    acc[country['alpha-2'].toLowerCase()] = country

    return acc
  }, {})
}

function countriesByAlpha3Code(countries: Record<string, Country>) {
  return Object.values(countries).reduce<Record<string, Country>>((acc, country) => {
    acc[country['alpha-3'].toLowerCase()] = country

    return acc
  }, {})
}

function jurisdictionsByCode(jurisdictions: Record<string, Jurisdiction>) {
  return keyedBy('code', Object.values(jurisdictions))
}

export const useCountries = defineStore('countries', {
  state: () => ({
    countriesByAlpha2Code: countriesByAlpha2Code(countries),
    countriesByAlpha3Code: countriesByAlpha3Code(countries),
    countries,
    jurisdictions,
    jurisdictionsByCode: jurisdictionsByCode(jurisdictions),
    information: {} as Record<string, CountryInformation>,
    informationVersion: 0,
    geoJSON: {} as FeatureCollection,
  }),

  getters: {
    country() {
      return (name: string): Country | null => {
        if (!name) {
          return null
        }

        name = name.toLowerCase()
        if (name in countries) {
          return countries[name]
        }

        for (const key in countries) {
          const country = countries[key]
          if (country['alpha-2'].toLowerCase() === name) return country
          if (country['alpha-3'].toLowerCase() === name) return country
          if ((country['passport-code'] ?? '').toLowerCase() === name) return country
          if (country.capital.toLowerCase() === name) return country
          if ('aliases' in country && country.aliases.includes(name)) return country
        }

        return null
      }
    },

    countryName() {
      const vm = this

      return (value: string): string | null => {
        const country = vm.country(value)
        if (country) {
          return country.name
        }

        const jurisdiction = vm.jurisdiction(value)

        return jurisdiction?.country?.name ?? null
      }
    },

    jurisdiction(state) {
      return (query: string): Jurisdiction | undefined => {
        if (!query) {
          return
        }

        query = query.toLowerCase()

        if (query in state.jurisdictions) return state.jurisdictions[query]

        for (const jurisdiction of Object.values(state.jurisdictions)) {
          if (jurisdiction.code === query) return jurisdiction
          if (jurisdiction.name.toLowerCase() === query) return jurisdiction
        }
      }
    },

    queryCountries() {
      return (query: string, cb?: (results: { value: string; code: string }[]) => void) => {
        if (!query) {
          const results: { value: string; code: string }[] = []
          for (const key in countries) {
            const country = countries[key]
            results.push({ value: country.name, code: country['alpha-2'] })
          }
          if (cb) {
            return cb(results)
          }

          return results
        }

        query = query.toLowerCase()

        const results = []
        for (const [key, country] of Object.entries(countries)) {
          const result = { value: country.name, code: country['alpha-2'] }

          if (key.includes(query)) {
            results.push(result)
          } else if (country['alpha-3'].toLowerCase().includes(query)) {
            results.push({ ...result, label: country['alpha-3'] })
          } else if (country.capital.toLowerCase().includes(query)) {
            results.push({ ...result, label: country.capital })
          }
        }

        if (cb) return cb(results)

        return results
      }
    },

    searchJurisdictions(state) {
      return (name: string) => {
        if (!name) return state.jurisdictions

        name = name.toLowerCase()

        const results: Jurisdiction[] = []
        for (const key in state.jurisdictions) {
          if (key.includes(name)) {
            results.push(state.jurisdictions[key])
          }
        }

        return results
      }
    },

    searchGeoJSON(state) {
      return (name: string) => {
        return state.geoJSON.features.filter((feature) => feature.properties?.name === name)
      }
    },
  },

  actions: {
    async loadInformation() {
      if (this.informationVersion) return

      const { data } = await api.get<{ hits: Record<string, CountryInformation> }>('country-information')

      if ('hits' in data) {
        this.information = Object.freeze(data.hits)
        this.informationVersion = Object.values(this.information).find((hit) => hit.cpi)?.cpi.year ?? 0
      }
    },

    async loadGeoJSON() {
      if (Object.keys(this.geoJSON).length > 0) {
        return
      }

      const { data } = await axios.get<FeatureCollection>(`countries.geojson?${$env.version}`)
      this.geoJSON = Object.freeze(data)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCountries, import.meta.hot))
}
