<template>
  <span v-if="jurisdiction">
    <CountryFlagIcon :value="jurisdiction.country['alpha-2']" />
    {{ jurisdiction.name }}
  </span>
  <slot v-else name="empty">
    <FieldEmpty />
  </slot>
</template>

<script setup lang="ts">
import CountryFlagIcon from './CountryFlagIcon.vue'
import FieldEmpty from './fields/FieldEmpty.vue'

const props = defineProps<{
  value: string
}>()

const jurisdiction = computed(() => {
  if (props.value in stores.countries.jurisdictionsByCode) {
    return stores.countries.jurisdictionsByCode[props.value]
  }

  return stores.countries.jurisdiction(props.value)
})
</script>
