<template>
  <div :style="{ maxHeight, overflow: 'auto' }">
    <div v-for="(line, index) in value.split('\n')" :key="index">
      <VTextHighlight :highlight-component="NERHighlight" :highlight-target="highlightTarget" :queries="queries" :searched-name="searchedName" :text="line" />
    </div>
  </div>
</template>

<script lang="ts">
import NERHighlight from '~/components/NERHighlight.vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },

    highlightTarget: {
      type: [String, Array],
      default: '',
    },

    maxHeight: {
      type: String,
      default: '300px',
    },

    searchedName: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    NERHighlight: markRaw(NERHighlight),
    entities: {},
  }),

  computed: {
    queries() {
      const entities = Object.keys(this.entities).map((entity) => new RegExp(`${wordBoundaries.before}${escapeRegExp(entity)}${wordBoundaries.after}`, 'gmi'))

      if (typeof this.highlightTarget === 'string') {
        return [this.highlightTarget, ...entities]
      }

      return [...this.highlightTarget, ...entities]
    },
  },

  async created() {
    // Don't use NER when unauthenticated.
    if (this.$user.id) {
      this.entities = await stores.ner.ner(this.value)
    }
  },
})
</script>
