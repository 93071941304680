/**
 * Composable for all source components
 */

import merge from 'lodash-es/merge.js'

import type ContextMenu from '~/components/ContextMenu.vue'
import type SourceTable from '~/components/SourceTable.vue'
import { injectRequired } from '~/helpers/injectRequired.ts'
import { keyedBy } from '~/helpers/keyedBy.ts'
import { $caseStoreKey } from '~/injections.ts'
import { canEditCase } from '~/stores/user.ts'
import { CASE_SOURCE_NAMES, type CaseForm, type CaseHitTransformed, type CaseSourceName, type HitResolution, type HitUpdate } from '~/types.ts'

export interface SourceProps {
  form: CaseForm
  tableSize: number
}

export interface SourceFilters extends Partial<Record<CaseSourceName, object>> {
  news: {
    adverse: null | string
    industries: null | string
    year: null | string
  }
}

export function useSource(
  props: SourceProps,
  contextMenu: Ref<InstanceType<typeof ContextMenu>>,
  sourceTable: Ref<InstanceType<typeof SourceTable>>,
  caseSourceName: (typeof CASE_SOURCE_NAMES)[number],
  columns: string[],
) {
  provide('$resolve', resolve)
  provide('$update', update)

  const { caseStore, sourceStores, isPreviewDialog } = injectRequired($caseStoreKey)

  const selection = ref<CaseHitTransformed['data'][]>([])
  const filteredNames = ref<string[]>([])
  const showAllMatchedNames = ref(false)

  const defaultSourceFilters = () => ({
    news: {
      industries: null,
      adverse: null,
      year: null,
    },
  })
  const sourceFilters = ref<SourceFilters>(defaultSourceFilters())

  const activeResolution = computed(() => caseStore.activeResolution)
  const caseId = computed(() => caseStore.caseId)
  const caseModel = computed(() => stores.cases.opened[caseId.value])
  const contextMenuItems = computed(() => getContextMenuItems(filteredTableData.value))
  const editable = computed(() => canEditCase(caseModel.value))
  const empty = computed(() => Object.values(hits.value).length === 0)
  const filterCreatedAt = computed(() => caseStore.filterCreatedAt)
  const filteredTableData = computed(() => filteredHits.value)
  const filterQuery = computed(() => caseStore.filterQuery)
  const hits = computed(() => store.value[activeResolution.value])
  const hitsById = computed(() => keyedBy('id', hits.value))
  const shouldShowOnlyUpdatedHits = computed(() => showOnlyUpdatedHits.value && activeResolution.value !== 'unresolved')
  const showOnlyUpdatedHits = computed(() => caseStore.showOnlyUpdatedHits)
  const source = computed(() => store.value.name)
  const store = computed(() => sourceStores[caseSourceName])
  const tableData = computed<HitUpdate[]>(() => hits.value.map((hit) => hit.update ?? hit.data))
  const title = computed(() => $t(source.value))

  const nameType = computed(() => {
    if (source.value === 'peps') {
      return ''
    }

    return caseStore.case?.type ?? ''
  })

  const visibleColumns = computed(() => {
    let visibleColumns = columns.slice()

    if (!editable.value || isPreviewDialog) {
      visibleColumns = columns.filter((column) => column !== 'selection' && column !== 'resolve')
    }

    if (caseModel.value.status === 'Preview' || isPreviewDialog) {
      findAndSpliceArray(visibleColumns, (column) => column === 'comments')
    }

    if (!(caseModel.value.services ?? []).includes('riskClassification')) {
      findAndSpliceArray(visibleColumns, (column) => column === 'risk')
    }

    if (!caseModel.value.services.includes('mediaDeduplication')) {
      findAndSpliceArray(visibleColumns, (column) => column === 'similar')
    }

    return visibleColumns
  })

  const expandColspanBefore = computed(() => {
    let value = 0

    for (const column of ['selection', 'expand', 'entry_count', 'similar', 'type']) {
      if (visibleColumns.value.includes(column)) {
        value++
      }
    }

    return value
  })

  const filteredHits = computed<HitUpdate[]>(() => {
    const query = filterQuery.value
    const createDates = filterCreatedAt.value

    if (!query && !createDates && !shouldShowOnlyUpdatedHits.value && filteredNames.value.length === 0) {
      return tableData.value
    }

    const excludedKeys = ['case_hit']

    return tableData.value.filter((hit) => {
      // Filter out cases without updated hits
      if (shouldShowOnlyUpdatedHits.value && !hit.case_hit.hasChanges) {
        return false
      }

      // Filter by ids
      if (
        filteredNames.value.length > 0 &&
        hit.highlight &&
        !Object.values(hit.highlight).some((highlightTypes) => {
          if (!Array.isArray(highlightTypes)) {
            delete highlightTypes['addresses.text']
            delete highlightTypes.jurisdiction_code
            delete highlightTypes['identifiers.value']
          }

          return Object.values(highlightTypes).some((highlights) =>
            (typeof highlights === 'string' ? [highlights] : highlights).some((highlight) =>
              filteredNames.value.includes(highlight.replaceAll('<em>', '').replaceAll('</em>', '').toLowerCase()),
            ),
          )
        })
      ) {
        return false
      }

      if (createDates[0] && dayjs(hit.case_hit.created_at).isBefore(dayjs(createDates[0]), 'day')) return false
      if (createDates[1] && dayjs(hit.case_hit.created_at).isAfter(dayjs(createDates[1]), 'day')) return false

      // Filter by input query.
      const array: any[] = []
      Object.keys(hit).forEach((key) => {
        if (!excludedKeys.includes(key)) {
          array.push(hit[key])
        } else if (key === 'case_hit') {
          array.push(hit[key].comments)
          for (const updates of Object.values(hit[key].update_changes ?? {})) {
            for (const [field, update] of Object.entries(updates)) {
              if (!excludedKeys.includes(field)) {
                array.push(update.new)
                array.push(update.old)
              }
            }
          }
        }
      })

      return stringInArray({ array, string: query })
    })
  })

  const sourceTableProps = computed(() => {
    return {
      columns: visibleColumns.value,
      data: filteredTableData.value,
      defaultSort: { prop: 'score', order: 'desc' },
      form: props.form,
      nameType: nameType.value,
      pagination: true,
      ref: 'sourceTable',
      rowKey: 'case_hit.id',
      tableEvents: tableEvents.value,
      expandColspanBefore: expandColspanBefore.value,
      checkedRows: selection.value,
      pageSize: props.tableSize,
    }
  })

  const tableEvents = computed(() => {
    return {
      cellContextmenu: openContextMenu,
      check: select,
      'update:checkedRows': (rows: CaseHitTransformed['data'][]) => (selection.value = rows),
    }
  })

  const nFiltersActive = computed(() => {
    let nFilters = 0

    nFilters += sourceFilters.value.news.adverse !== null ? 1 : 0
    nFilters += sourceFilters.value.news.year !== null ? 1 : 0
    nFilters += sourceFilters.value.news.industries !== null ? 1 : 0
    nFilters += Object.values(filteredNames.value).length > 0 ? 1 : 0

    nFilters += filterQuery.value ? 1 : 0
    nFilters += filterCreatedAt.value.length ? 1 : 0
    nFilters += showOnlyUpdatedHits.value ? 1 : 0

    return nFilters
  })

  watch(hitsById, (hits) => {
    selection.value = selection.value.filter((row) => row.case_hit.id in hits)
  })

  watch(filterQuery, () => {
    const table = sourceTable.value?.table
    if (table) {
      table.expandedRows = []
    }
  })

  function select(rows: CaseHitTransformed['data'][]) {
    store.value.selected = rows.map((row) => row.id)
  }

  function getHits(keys: number[]) {
    const foundHits: Record<string, CaseHitTransformed> = {}

    for (const id of keys) {
      if (!(id in hitsById.value)) continue

      foundHits[id] = hitsById.value[id]

      foundHits[id].data.similar?.forEach((id) => {
        const hit = hits.value.find((hit) => hit.data.id === id)
        if (hit) {
          foundHits[hit.id] = hit
        }
      })
    }

    return foundHits
  }

  async function resolve({
    entries,
    ids = [],
    resolution,
    comment = null,
    risk = null,
  }: {
    entries: string
    ids?: number[]
    resolution: string
    comment?: string | null
    risk?: string | null
  }) {
    if (ids.length === 0) {
      let hits

      if (entries === 'selected') {
        hits = getHits(selection.value.map((row) => row.case_hit.id))
      } else {
        hits = hitsById.value
      }

      ids = Object.keys(hits).map((key) => Number(key))
    }

    if (ids.length === 0) {
      $message.warning($t('no-rows-have-been-selected'))

      return
    }

    await store.value.resolve({ ids, resolution, comment, risk })
    selection.value = []
  }

  async function update({ entries }: { entries: string }) {
    let hitsToUpdate

    if (entries === 'selected') {
      hitsToUpdate = getHits(selection.value.map((row) => row.case_hit.id))
    } else {
      hitsToUpdate = hitsById.value
    }

    if (Object.keys(hits).length === 0) {
      $message.warning($t('no-rows-have-been-selected'))

      return
    }

    const { data } = await api.post('case-hits/accept-update', {
      ids: Object.keys(hitsToUpdate).map((key) => Number(key)),
      case_id: caseId.value,
      source: source.value,
    })
    for (const caseSourceName of CASE_SOURCE_NAMES) {
      const sourceHits = data.hits.filter((hit) => hit.source === caseSourceName)
      if (sourceHits) {
        sourceStores[caseSourceName].updateHits(sourceHits)
      }
    }

    selection.value = []
  }

  function loadNer(row: CaseHitTransformed['data']) {
    if (!row.summary || row.summary in stores.ner.queries) {
      return
    }

    // Add the NER fields to the NER store so they don't have to be NER'ed when the summary is shown
    const results: Record<string, string> = {}
    for (const type of ['persons', 'organizations', 'locations'] as const) {
      for (const { value } of row[type] ?? []) {
        if (value) {
          stores.ner.results[value.toLowerCase()] = type.slice(0, -1)
          results[value.toLowerCase()] = type.slice(0, -1)
        }
      }
    }

    stores.ner.queries[row.summary] = results
  }

  function clearAllFilters() {
    caseStore.filterQuery = ''
    filteredNames.value = []
    caseStore.showOnlyUpdatedHits = false
    sourceFilters.value = defaultSourceFilters()
  }

  function updateSourceFilters(updateObject: object) {
    merge(sourceFilters.value, updateObject)
  }

  function getContextMenuItems(hits: CaseHitTransformed['data'][]) {
    let items = ['Include all entries', 'Include selected entries', 'Exclude all entries', 'Exclude selected entries', 'Update all entries', 'Update selected entries']

    if (stores.policies.policies.require_hit_comments) {
      items = items.filter((item) => !item.includes('Include') && !item.includes('Exclude'))
      items.push('Select all entries')
    }

    let hitsWithUpdates = 0
    for (const hit of hits) {
      if (hit.case_hit.update) {
        hitsWithUpdates++
        if (hitsWithUpdates > 1) {
          break
        }
      }
    }
    if (hitsWithUpdates < 2) {
      items = items.filter((item) => !item.includes('Update'))
    }

    if (selection.value.length === 0) {
      items = items.filter((item) => !item.includes('selected'))
    } else {
      items = items.filter((item) => !item.includes('all'))
    }

    if (activeResolution.value === 'positive') {
      items = items.filter((item) => !item.includes('Include'))
    } else if (activeResolution.value === 'negative') {
      items = items.filter((item) => !item.includes('Exclude'))
    } else if (activeResolution.value === 'unresolved') {
      items = items.filter((item) => !item.includes('Update'))
    }

    return items
  }

  function openContextMenu(row: CaseHitTransformed['data'], column: string, event: MouseEvent) {
    event.preventDefault()
    if (editable.value && contextMenuItems.value.length > 0) {
      contextMenu?.value?.open(event, row)
    }
  }

  async function contextMenuClick(label: string, row: CaseHitTransformed['data']) {
    let hits = {}
    let resolution: HitResolution = 'positive'

    if (label.startsWith('Exclude')) {
      resolution = 'negative'
    }

    if (label === 'Select all entries') {
      selection.value = filteredTableData.value.slice()

      return
    } else if (label.endsWith('selected entries')) {
      hits = getHits(selection.value.map((row) => row.case_hit.id))
    } else {
      hits = getHits(filteredTableData.value.map((row) => row.case_hit.id))
    }

    const ids = Object.keys(hits).map((key) => Number(key))

    if (label.startsWith('Update')) {
      const { data } = await api.post('case-hits/accept-update', { case_id: caseId.value, ids, source: source.value })

      for (const source of CASE_SOURCE_NAMES) {
        const sourceHits = data.hits.filter((hit) => hit.source === source)
        if (sourceHits) {
          sourceStores[source].updateHits(sourceHits)
        }
      }
      selection.value = []

      return
    }

    await store.value.resolve({ ids, resolution })
    if (label.endsWith('entry')) {
      findAndSpliceArray(selection.value, (item) => item.case_hit.id === row.case_hit.id)
    } else {
      selection.value = []
    }
  }

  return {
    activeResolution,
    caseModel,
    caseStore,
    clearAllFilters,
    contextMenuClick,
    contextMenuItems,
    loadNer,
    empty,
    filteredHits,
    filteredNames,
    filteredTableData,
    hits,
    nFiltersActive,
    selection,
    showAllMatchedNames,
    sourceFilters,
    sourceStores,
    sourceTableProps,
    title,
    updateSourceFilters,
  }
}
