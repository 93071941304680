<template>
  <div>
    <div v-for="(item, key) in filtered" :key="key" v-text="formatKeyValue(key, item)" />
  </div>
</template>

<script setup lang="ts">
import { formatKeyValue } from '~/helpers/textFormatting.ts'
import { type HitData } from '~/types.ts'

interface Props {
  value: HitData['industry_types'][0]
}

const props = defineProps<Props>()

const filtered = computed(() => {
  const filtered: Record<string, string> = {}

  for (const [key, value] of Object.entries(props.value)) {
    if (key !== 'code_scheme_id') {
      filtered[key] = value
    }
  }

  return filtered
})
</script>
