<template>
  <span v-if="language" v-text="language" />
  <FieldEmpty v-else />
</template>

<script lang="ts">
import ISO6391 from 'iso-639-1'

export default defineComponent({
  props: {
    value: {
      required: true,
      type: String,
    },
  },

  computed: {
    language() {
      return ISO6391.getName(this.value)
    },
  },
})
</script>
