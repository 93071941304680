<template>
  <VTag v-if="sentiment" :type="sentiment.type">{{ sentiment.label }}</VTag>
  <FieldEmpty v-else />
</template>

<script lang="ts">
export default defineComponent({
  props: {
    value: {
      required: true,
      type: String,
    },
  },

  computed: {
    sentiment() {
      if (!this.value) {
        return
      }

      if (this.value === 'positive') {
        return { type: 'green', label: this.$t('positive') }
      } else if (this.value === 'negative') {
        return { type: 'red', label: this.$t('negative') }
      }

      return { type: 'info', label: this.$t('neutral') }
    },
  },
})
</script>
