import { type VProgressType } from '@vartion/ui'

import { type createCaseStore } from '~/stores/case.ts'
import { type CaseHit, type CaseSourceName, type HitResolution } from '~/types.ts'

interface Params {
  hits: CaseHit[]
  resolution: HitResolution
  source: CaseSourceName
  hasErrors: boolean
  hasSearched: boolean
  isActive: boolean
  loading: boolean
  isPreview: boolean
}

interface Progress {
  total: number
  percentage: number
  resolved: number
}

function resolveProgress({ hits, resolution, source, hasSearched, hasErrors, isActive, loading, isPreview }: Params) {
  let progress: Progress = { total: 0, percentage: 0, resolved: 0 }
  let progressLabel = ''

  if (resolution === 'unresolved') {
    progress = unresolvedProgress(hits)
    if (progress.total === 0) {
      progressLabel = $t('no-matches')
    } else if (isPreview) {
      progressLabel = $t('n-hits', progress.total)
    } else if (progress.percentage === 100) {
      progressLabel = $t('done')
    } else {
      progressLabel = $t('resolved-total-resolved', { resolved: progress.resolved, total: progress.total })
    }
  } else {
    progress = updateProgress(hits, resolution)
    const matches = resolution === 'positive' ? $t('no-includes') : $t('no-excludes')
    progressLabel = progress.total === 0 ? matches : $t('resolved-total-up-to-date', { resolved: progress.resolved, total: progress.total })
  }

  if (!hasSearched) {
    progressLabel = $t('not-searched')
  }

  if (progress.total === 0 && loading) {
    progressLabel = '0/0'
  }

  let status: VProgressType = 'blue-5'
  if (hasErrors) {
    status = 'yellow'
  } else if (progress.percentage === 100) {
    status = 'green-light'
  }

  return {
    source,
    progress: progress.percentage,
    total: progress.total,
    sourceLabel: $t(source),
    progressLabel,
    loading,
    hasErrors,
    status,
    isActive,
  }
}

function unresolvedProgress(hits: CaseHit[]): Progress {
  const total = hits.length
  if (total === 0) {
    return {
      total: 0,
      resolved: 0,
      percentage: 0,
    }
  }

  const unresolved = hits.filter((hit) => hit.resolution === 'unresolved').length
  const resolved = total - unresolved

  return {
    resolved,
    total,
    percentage: Math.floor((resolved / total) * 100),
  }
}

function updateProgress(hits: CaseHit[], resolution: HitResolution) {
  hits = hits.filter((hit) => hit.resolution === resolution)

  const total = hits.length

  const resolved = hits.filter((hit) => !hit.update_changes).length

  let percentage = 0

  if (total !== 0) {
    percentage = Math.floor((resolved / total) * 100)
  }

  return {
    total,
    resolved,
    percentage,
  }
}

export function useResolveProgress(caseStore: ReturnType<ReturnType<typeof createCaseStore>>) {
  return computed(() =>
    caseStore.sources.map((source) => {
      return resolveProgress({
        hits: caseStore[source].hits,
        resolution: caseStore.activeResolution,
        source,
        loading: caseStore[source].loading.length > 0,
        hasSearched: Object.values(caseStore[source].sources).some((source) => source.searched_at),
        hasErrors: !Object.values(caseStore[source].sources).every((source) => source.status !== 'error'),
        isActive: Object.values(caseStore[source].sources).some(
          (source) => source.active && (source.data_source.active || dayjs(source.data_source.deactivated_at).isAfter(dayjs().startOf('month'))),
        ),
        isPreview: caseStore.case?.status === 'Preview',
      })
    }),
  )
}
