<template>
  <VTooltip style="max-width: 140px; width: 100%">
    <template #content>{{ confidence }}</template>

    <VProgress :label="''" :marker="marker" style="max-width: 140px" :value="confidence" />
  </VTooltip>
</template>

<script lang="ts">
import { type PropType } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },

    implicitFeedback: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },

  computed: {
    marker() {
      if (!this.implicitFeedback) {
        return undefined
      }

      return this.implicitFeedback > 0 ? Math.min(100, this.value + 20) : Math.max(0, this.value - 20)
    },

    confidence() {
      return Math.round(this.value)
    },
  },
})
</script>

<style lang="scss" scoped>
.v-badge :deep(.v-badge__content) {
  right: 1px;
}
</style>
