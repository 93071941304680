<template>
  <VDialog placement="right" :title="$t('connection-information')" :visible="visible" @update:visible="$emit('update:visible', $event)">
    <MatchTag v-if="value.matched" class="match-tag" :value="value" />
    <HitField v-for="field in fields" :key="field" :field="field" :field-size="fieldSize" :form="form" :row="value" show-changes :value-size="valueSize" />
  </VDialog>
</template>

<script lang="ts">
export default defineComponent({
  name: 'DialogConnectionInformation',

  props: {
    visible: Boolean,

    value: {
      required: true,
      type: Object,
    },

    form: {
      type: Object,
      required: true,
    },

    fieldSize: {
      type: Number,
      default: 6,
    },

    valueSize: {
      type: Number,
      default: 18,
    },
  },

  emits: ['update:visible'],

  data: () => ({
    fields: [
      'name',
      'aliases',
      'legal_form',
      'connection_type',
      'company_number',
      'addresses',
      'jurisdiction_code',
      'nationalities',
      'position',
      'status',
      'source',
      'officers',
      'identifiers',
      'contact_details',
      'documents',
      'organizations',
      'persons',
      'locations',
      'number_of_employees',
      'summary',
      'listed_at',
      'listed_until',
      'register_type',
      'industry_types',
      'updated_at',
      'retrieved_at',
    ],
  }),
})
</script>

<style lang="scss" scoped>
.match-tag {
  margin-bottom: 20px;
  color: var(--color-text-primary);
  border-color: var(--color-text-primary);
}
</style>
