<template>
  <div>
    <div v-for="(characteristic, index) in formatted" :key="index" v-text="characteristic" />
  </div>
</template>

<script lang="ts">
import { type PropType } from 'vue'

import { type Characteristic } from '~/types.ts'

function format({ type, value }: Characteristic) {
  if (type && value) {
    return `${type}: ${value}`
  }

  if (type) {
    return type
  }

  return value
}

export default defineComponent({
  props: {
    value: {
      required: true,
      type: Array as PropType<Characteristic[]>,
    },
  },

  computed: {
    formatted() {
      return this.value.map((characteristic) => format(characteristic)).filter((characteristic) => characteristic)
    },
  },
})
</script>
